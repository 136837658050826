import { css } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";
import Avatar from "@mui/material/Avatar";
import Drawer from "@mui/material/Drawer";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useTheme } from "@mui/styles";
import { ReactComponent as ExitIcon } from "shared/Icons/exit.svg";
import { ReactComponent as FullscreenIcon } from "shared/Icons/maximize.svg";
import { ReactComponent as FullscreenExitIcon } from "shared/Icons/minimize.svg";
import { ReactComponent as MenuIcon } from "shared/Icons/menu.svg";
import { useAccount } from "auth";
import React from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";

import HomeIcon from "@mui/icons-material/Home";
import { ThemeProvider } from "@mui/material/styles";
import { endSession } from "auth";
import { initialBroadcastEditorState } from "./BroadcastStation/BroadcastEditor";

import { useMsal } from "@azure/msal-react";
import { getProfileImg, logoutMSAL } from "auth/msalAuth";
import { useOrgData } from "auth/useOrgData";
import { getTheme } from "getTheme";
import { sessionStorageSync, useSessionStorageSync } from "shared/helpers/sessionStorageSync";
import store from "store";
import { logoutResetStore } from "store/action-creators";
import FeatureTogglingContainer from "./FeatureTogglingContainer";
import OrgEditor from "./OrgEditor";
import ToolEditor from "./ToolEditor";
import { useNavigate } from "react-router-dom";

export default function Admin() {
  const { loggedIn, loggingIn, claims, isDraft, isClaim } = useAccount();
  const [selectedOrganization, setSelectedOrganization] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [fullscreen, setFullscreen] = React.useState(false);
  const [advancedMenuAnchorEl, setAdvancedMenuAnchorEl] = React.useState(null);
  const [changesPending, setChangesPending] = React.useState(false);
  const [tab, setTab] = React.useState("organization"); //before selection org either "organization" or "userManagement"
  const [activeTab, setActiveTab] = React.useState(() =>
    isClaim("OrgConfig.Read") || isClaim("ModelConfig.Read") ? 0 : isClaim("OrgARM.Read") ? 1 : null,
  ); //after selecton org either 0 or 1; 0 => configuration tab, 1 => users tab

  const [broadcastEditorState, setBroadcastEditorState] = React.useState({ ...initialBroadcastEditorState });
  const theme = useTheme();

  const { instance, accounts } = useMsal();
  const email = accounts?.[0]?.username?.toLowerCase();
  const navigate = useNavigate();

  const [profilePic, setProfilePic] = React.useState(null);
  React.useEffect(() => {
    getProfileImg(instance, accounts).then((a) => setProfilePic(a));
  }, [accounts, instance]);
  const [disableScroll, setDisableScroll] = React.useState(false);

  React.useEffect(() => {
    if (loggedIn && selectedOrganization !== claims.organization) setSelectedOrganization(claims.organization);
    if (!loggedIn && !loggingIn && selectedOrganization !== null) setSelectedOrganization(null);
  }, [loggedIn, loggingIn, claims?.organization, selectedOrganization]);

  const [msalToken] = useSessionStorageSync("msalToken");
  const { data: orgs = [], mutate: localMutate } = useOrgData(msalToken || null, accounts, instance);
  const organizationsList = orgs?.accessibleOrgs || [];
  // console.log(organizationsList);
  const isUnauthorized = false;

  // The admin interface does not show for the normal login flow
  // if ((loggedInAsCustomer && !customerClaims.impersonating) || showSelectOrgPage) return null;

  const profileAvatarContainer = document.getElementById("profile-avatar-container");

  const advancedMenu = (
    <Menu
      id="pages-menu"
      anchorEl={advancedMenuAnchorEl}
      keepMounted
      open={Boolean(advancedMenuAnchorEl)}
      onClose={() => setAdvancedMenuAnchorEl(null)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      PaperProps={{ style: { marginTop: 2 } }}
      TransitionComponent={Fade}
    >
      <MenuItem
        key="logout"
        onClick={() => {
          setAdvancedMenuAnchorEl(null);
          logoutMSAL();
        }}
        style={{ fontFamily: "Brown, Helvetica, sans-serif" }}
      >
        Logout {email}
      </MenuItem>
    </Menu>
  );
  return (
    profileAvatarContainer &&
    ReactDOM.createPortal(
      <>
        <div
          style={{
            position: "absolute",
            cursor: "pointer",
            zIndex: 1000,
            height: "24px",
            width: "24px",
            top: "8px",
            right: "8px",
          }}
          onClick={(e) =>
            (loggedIn &&
              (isClaim("OrgConfig.Read") ||
                isClaim("ModelConfig.Read") ||
                isClaim("OrgARM.Read") ||
                isClaim("OrgARM.External.Read"))) ||
            isClaim("GlobalARM.Read")
              ? setOpen(true)
              : setAdvancedMenuAnchorEl(e.currentTarget)
          }
          id="admin-handle"
        >
          <Avatar
            sx={{ width: 24, height: 24, fontSize: 13, fontWeight: 500, border: "1px gray solid" }}
            src={profilePic}
            alt="☻"
          >
            {getInitials(claims.name, email)}
          </Avatar>
        </div>
        {(isClaim("OrgConfig.Read") ||
          isClaim("ModelConfig.Read") ||
          isClaim("OrgARM.Read") ||
          isClaim("GlobalARM.Read") ||
          isClaim("OrgARM.External.Read")) && (
          <Drawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            PaperProps={{
              style: {
                width: fullscreen ? "100%" : 960,
                background:
                  tab === "userManagement" || activeTab === 1 ? theme.palette.whiteish.main : theme.palette.greyish[10],
              },
            }}
            ModalProps={{ keepMounted: true }}
          >
            <ThemeProvider theme={getTheme()}>
              <AdminFrame
                className="customScroll"
                data-test-id="adminFrame"
                style={fullscreen ? { paddingLeft: "15%", paddingRight: "15%" } : undefined}
                disableScroll={disableScroll}
              >
                <ErrorBoundary
                  fallbackRender={({ error, resetErrorBoundary }) => (
                    <div style={{ margin: 10 }}>
                      There was a problem showing the configuration for this advertiser organization. Data may be
                      corrupt.
                      <br />
                      <br />
                      <button onClick={resetErrorBoundary}>Back</button>
                    </div>
                  )}
                  onReset={endSession}
                >
                  {selectedOrganization ? (
                    <>
                      <AdminHeaderFrame data-test-id="adminHeader" id="admin-header" />
                      <OrgEditor
                        organization={selectedOrganization}
                        name={organizationsList.find((d) => d.orgSlug === selectedOrganization)?.orgName}
                        changesPending={changesPending}
                        changesPendingCb={setChangesPending}
                        setDisableScroll={setDisableScroll}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                    </>
                  ) : (
                    <>
                      <AdminHeaderFrame data-test-id="adminHeader" id="admin-header" />
                      {isUnauthorized && (
                        <div style={{ padding: 20 }}>You don't have permissions to use this control</div>
                      )}
                      <ToolEditor
                        organization={selectedOrganization}
                        name={
                          organizationsList.find((d) => d.organization === selectedOrganization)?.orgName ||
                          selectedOrganization
                        }
                        changesPending={changesPending}
                        changesPendingCb={setChangesPending}
                        tab={tab}
                        setTab={setTab}
                        organizationsList={organizationsList}
                        broadcastEditorState={broadcastEditorState}
                        setBroadcastEditorState={setBroadcastEditorState}
                        getOrgs={localMutate}
                        setDisableScroll={setDisableScroll}
                      />
                    </>
                  )}
                </ErrorBoundary>

                <AdminTopBackdrop />

                {isClaim("GlobalARM.Read") && (
                  <div style={{ position: "absolute", left: 15, top: 12, zIndex: 9999 }}>
                    <Tooltip
                      title={changesPending ? "You need to save or discard changes first!" : "List of organizations"}
                      arrow
                    >
                      <span>
                        <IconButton
                          onClick={() => {
                            sessionStorageSync.removeItem("current-token-v2");
                            sessionStorageSync.removeItem("selected-model");
                            sessionStorageSync.removeItem("selectedOrg");
                            store.dispatch(logoutResetStore());
                            setTab("organization");
                            setBroadcastEditorState({ ...initialBroadcastEditorState });
                            navigate("/");
                          }}
                          color="primary"
                          disabled={changesPending || (tab === "organization" && !selectedOrganization)}
                          style={{ padding: 0 }}
                        >
                          <HomeIcon />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                )}

                <AdminControls data-test-id="adminControls">
                  <IconButton
                    onClick={(e) => setAdvancedMenuAnchorEl(advancedMenuAnchorEl === null ? e.currentTarget : null)}
                    style={{ padding: 0 }}
                  >
                    <MenuIcon />
                  </IconButton>
                  <IconButton onClick={() => setFullscreen((s) => !s)} style={{ padding: 0, width: 20, height: 20 }}>
                    {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                  </IconButton>
                  <IconButton onClick={() => setOpen(false)} style={{ padding: 0 }}>
                    <ExitIcon />
                  </IconButton>
                </AdminControls>
              </AdminFrame>
            </ThemeProvider>
          </Drawer>
        )}
        {advancedMenu}
        {isDraft && <DraftRibbon />}
        {isClaim("LittleTools") && <FeatureTogglingContainer />}
      </>,
      profileAvatarContainer,
    )
  );
}

function getInitials(name, email) {
  return (name || email || "")
    .toUpperCase()
    .split(" ")
    .map((d) => d[0])
    .join("");
}

const AdminFrame = styled.div`
  --vertical-scrollbar-margin-top: 48px;
  --vertical-scrollbar-margin-bottom: 115px;
  padding-top: var(--vertical-scrollbar-margin-top);
  --bottom-buffer: 200px;
  padding-bottom: var(--bottom-buffer);
  padding-right: 5px;
  overflow-x: hidden;
  overflow-y: ${({ disableScroll }) => (disableScroll ? "hidden" : "auto")};
  font-family: "Brown", "Helvetica", sans-serif;
  --org-entry-grid-columns: 5% 21% repeat(4, 12%) 18% auto;
  --org-new-entry-grid-columns: 5% calc(25% + 5px) 12% 11.5% 12% 12.5% 22% auto;
  --tool-entry-grid-columns: 30% 12% 12% 18% 19% auto;
  --tool-new-entry-grid-columns: 30% 12.5% 11.5% 18.3% 21% auto;
`;

const AdminHeaderFrame = styled.div`
  position: absolute;
  top: 0px;
  left: 20px;
  right: 20px;
  z-index: 9999;
  font-size: 20px;
  color: #212121;
  padding: 0;
  height: 48px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const AdminControls = styled.div`
  position: absolute;
  top: 12px;
  right: 15px;
  z-index: 9999;
  display: flex;
  gap: 15px;
`;

const AdminTopBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  background-color: #e5f2f1;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  z-index: 9998;
`;

const DraftRibbon = styled.span`
  position: fixed;
  left: 0;
  top: 0;
  width: 140px;
  color: white;
  background: #ffa000;
  text-align: center;
  padding: 2px 0;
  font-weight: 500;
  transform: rotate(-45deg) translate(-40px, 0px);
  z-index: 999;
  &:before {
    content: "DRAFT";
  }
`;

export const Option = styled.div`
  color: #000;
  background: ${({ theme }) => theme.palette.greyish[20]};
  cursor: pointer;
  ${(props) =>
    props.active &&
    css`
      background: black;
      color: white;
      cursor: default;
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
  padding: 10px 16px;
  font-size: 16px;
  text-transform: uppercase;
`;
