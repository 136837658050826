import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import styled from "@emotion/styled/macro";
import _ from "lodash";

import validateRequest from "../helpers/validateRequest";
import getWhatSocketCallsModelslug from "shared/helpers/getWhatSocketCallsModelslug";

import {
  cancelEditor,
  startEditorValidation,
  successEditorValidation,
  errorEditorValidation,
  saveConstraint,
  selectData,
  selectInlineEditor,
} from "store/reducers/activityDialog";

import { useAccount } from "auth";

export default function TableFooter() {
  const dispatch = useDispatch();
  const { selectedModel, selectedModelConf: modelConf, isFeatureAvailable } = useAccount();
  const { socketSlug, objective } = modelConf;

  const activityV2StateData = useSelector(selectData);
  const activityV2StateInlineEditor = useSelector(selectInlineEditor);
  const { index, validating, constraint } = activityV2StateInlineEditor;

  const handleValidateRequest = ({
    socketSlug,
    whatSocketCallsModelslug,
    objective,
    isAdding,
    addedOrEditedConstraint,
    useExperimentalOptimizer,
  }) => {
    dispatch(startEditorValidation());
    validateRequest({
      socketSlug,
      whatSocketCallsModelslug,
      objective,
      isAdding,
      addedOrEditedConstraint,
      useExperimentalOptimizer,
      data: activityV2StateData,
      index: activityV2StateInlineEditor.index,
      isFeatureAvailable,
    })
      .then((res) => {
        console.log("Contraints validated:", res);
        if (res.constraintsValid) {
          dispatch(successEditorValidation());
          dispatch(saveConstraint({ index: activityV2StateInlineEditor.index }));
        } else {
          dispatch(
            errorEditorValidation({
              errorMessage: "Not compatible with previous inputs or available publisher inventory",
            }),
          );
        }
      })
      .catch((err) => {
        console.error("Error while verifying constraints", err);
        // Try to find out what's going on with these errors
        dispatch(errorEditorValidation({ errorMessage: "Internal system error, please try again" }));
      });
  };

  return (
    <EditorFooterList>
      <EditorFooterListItem>
        <Button sx={{ color: "#000", fontSize: "14px" }} onClick={() => dispatch(cancelEditor())}>
          Cancel
        </Button>
        <Button
          sx={{ fontSize: "14px" }}
          variant="contained"
          disabled={
            [constraint.type, constraint.value, constraint.startDate, constraint.endDate].some(_.isNil) ||
            !constraint.dimensions.some((d) => d.selection && !_.isEmpty(d.selection)) ||
            validating
          }
          onClick={() =>
            handleValidateRequest({
              socketSlug,
              whatSocketCallsModelslug: isFeatureAvailable("NPS")
                ? socketSlug
                : getWhatSocketCallsModelslug({
                    whatSocketCallsModelslug: socketSlug,
                    modelSlug: selectedModel,
                    socketSlug,
                  }),
              objective,
              isAdding: index === null,
              addedOrEditedConstraint: constraint,
              useExperimentalOptimizer: isFeatureAvailable("Use experimental optimizer"),
            })
          }
        >
          Add constraint
        </Button>
      </EditorFooterListItem>
    </EditorFooterList>
  );
}

const EditorFooterList = styled.ul`
  list-style-type: none;
  margin-top: 15px;
  padding: 0;
`;

const EditorFooterListItem = styled.li`
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  height: 32px;
`;
