import React from "react";
import PropTypes from "prop-types";
import DimensionFilter from "shared/components/DimensionFilter";
import DeleteIconButton from "shared/components/DeleteIconButton";
import EditorItemForText from "admin/EditorItem/EditorItemForText";
import { produce } from "immer";
import { useTheme } from "@mui/styles";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { getFilteringFunction } from "shared/helpers/filterOutUselessDimensionValues";
import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled/macro";
import _ from "lodash";
import getDynamicColor from "shared/helpers/getDynamicColor";
import getColorPickerOptions from "shared/helpers/getColorPickerOptions";
import ColorPicker from "shared/components/ColorPicker";
import { findDuplicateAttributes } from "admin/helpers";
import formattedLabel from "shared/helpers/formattedLabel";
import sluggify from "shared/helpers/sluggify";

function MediaGroupingsEditor({
  data = [],
  prodData = [], //to see property in conf taken to production
  onChange,
  isDisabled,
  mediaDimensionValues,
  labelDictionary,
}) {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState("rest");
  const validFilters = data.map((d) => d.filter).filter((d) => !_.isEmpty(d));
  const ungrouped = mediaDimensionValues.filter(
    (insertion) => !validFilters.some((filter) => getFilteringFunction(filter)(insertion)),
  );
  const sortedColorPickerOptions = getColorPickerOptions({ opts: { manualSort: true } });
  const prodMediaGroupings = prodData?.["mediaGroupings"] ?? [];
  const duplicateColors = Object.keys(findDuplicateAttributes(data, "color")) || [];
  return (
    <div>
      {mediaDimensionValues.length > 0 && ungrouped.length === 0 && (
        <Message>All insertions are now part of your media groupings.</Message>
      )}
      {mediaDimensionValues.length > 0 && ungrouped.length > 0 && (
        <Message>
          {ungrouped.length} insertions not covered by any of your groupings.{" "}
          <Link
            component="button"
            onClick={() => setExpanded(expanded === "rest" ? null : "rest")}
            style={{ marginLeft: 10 }}
          >
            {expanded === "rest" ? "Hide" : "Expand"}
          </Link>
        </Message>
      )}
      {expanded === "rest" && ungrouped.length > 0 && (
        <DimensionValuesView values={ungrouped} labelDictionary={labelDictionary} isError />
      )}

      {(data || []).map(({ slug, name, filter, color }, i) => {
        const defaultColor = getDynamicColor("grouping", slug);
        const insertionsInThisGroup = _.isEmpty(filter)
          ? []
          : mediaDimensionValues.filter(getFilteringFunction(filter));
        return (
          <React.Fragment key={i}>
            <ItemFrame warning={duplicateColors.includes(color)}>
              <ColorPicker
                slug={slug}
                isDisabled={isDisabled}
                color={color ?? defaultColor}
                sortedColorPickerOptions={sortedColorPickerOptions}
                prodColor={prodMediaGroupings?.find((grouping) => grouping?.slug === slug)?.color ?? defaultColor}
                onChange={
                  isDisabled
                    ? undefined
                    : (newColor) => {
                        onChange(
                          produce((s) => {
                            s[i].color = newColor;
                          }),
                        );
                      }
                }
              />
              <Tooltip
                title={
                  <div style={{ display: "flex", flexDirection: "column", gap: "9px" }}>
                    <span style={{ fontWeight: 400, color: theme.palette.greyish[20] }}>
                      {insertionsInThisGroup.length} insertions in this group.
                    </span>
                    {expanded !== i && <span style={{ fontWeight: 700 }}>Click to expand</span>}
                  </div>
                }
                arrow
              >
                <Count onClick={() => setExpanded(expanded === i ? null : i)} color={color}>
                  {insertionsInThisGroup.length}
                </Count>
              </Tooltip>
              <div>
                <EditorItemForText
                  autoFocus={name === ""}
                  schema={{ title: "Grouping name" }}
                  data={name}
                  isDisabled={isDisabled}
                  extraStyles={{ padding: 0, margin: "0 15px", width: "200px", color: theme.palette.greyish[50] }}
                  onChange={
                    onChange &&
                    ((localOnChange) =>
                      onChange(
                        produce((s) => {
                          s[i].name = localOnChange(s[i].name);
                          // Sluggify
                          s[i].slug = sluggify(s[i].name);
                        }),
                      ))
                  }
                  noborder
                />
              </div>
              <div
                style={{
                  position: "relative",
                  paddingTop: 3,
                  paddingRight: 10,
                  width: "70%",
                  overflowX: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <DimensionFilter
                  color={color ? color : defaultColor}
                  filter={filter}
                  onChange={
                    isDisabled
                      ? undefined
                      : (newFilter) =>
                          onChange(
                            produce((s) => {
                              s[i].filter = newFilter;
                            }, {}),
                          )
                  }
                  dimensionValues={[...insertionsInThisGroup, ...ungrouped]}
                  labelDictionary={labelDictionary}
                  allowAnyValidFilter
                  clearDisabled
                  backDisabled
                  squaredBorder={true}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    width: 20,
                    background: "linear-gradient(to right, transparent, white)",
                  }}
                />
              </div>
              <ButtonsFrame>
                {!isDisabled && <DeleteIconButton onClick={() => onChange((prev) => prev.filter((d, j) => i !== j))} />}
              </ButtonsFrame>
            </ItemFrame>

            {expanded === i && (
              <DimensionValuesView values={insertionsInThisGroup} filter={filter} labelDictionary={labelDictionary} />
            )}
          </React.Fragment>
        );
      })}
      {data.length === 0 && <Message>You have not defined any media groupings yet.</Message>}
      <div>
        {!isDisabled && (
          <div style={{ padding: "9px 15px", borderBottom: `1px solid ${theme.palette.greyish[20]}` }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                onChange((prev) => {
                  var prevGroupings = prev ? [...prev] : [];
                  return [...prevGroupings, { slug: "", name: "", filter: {} }];
                })
              }
              style={{ height: 32 }}
            >
              + Add
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

MediaGroupingsEditor.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ slug: PropTypes.string, name: PropTypes.string, filter: PropTypes.object }),
  ),
  mediaDimensionValues: PropTypes.arrayOf(PropTypes.object),
};

export default MediaGroupingsEditor;

const ButtonsFrame = styled.div`
  transition: opacity 0.5s ease-in-out;
  opacity: 1;
`;
// ref: https://www.styled-components.com/docs/faqs#support-for-nesting
const ItemFrame = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  padding: 8px 15px;
  background-color: ${({ warning }) => (warning ? "#ffecec" : "inherit")};
`;

const Count = styled.div`
  box-sizing: border-box;
  min-width: 24px;
  height: 24px;
  margin: 0 0 0 8px;
  padding: 1px 0px;
  text-align: center;
  color: black;
  border: 1px solid black;
  border-radius: 0px;
  cursor: pointer;
  &:hover {
    color: white;
    background: black;
  }
`;

const Message = styled.div`
  padding: 8px 15px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
  font-size: 14px;
  color: #000;
`;

function DimensionValuesView({ values, filter = {}, labelDictionary = {}, isError = false }) {
  const headers = _.uniq(values.reduce((acc, d) => [...acc, ...Object.keys(d)], []));

  return (
    <div
      style={{
        background: isError ? "hsl(0deg 100% 96%)" : "#f3f3f3",
        borderTop: "1px lightgray solid",
        borderBottom: "1px lightgray solid",
        margin: "5px 0",
        padding: 10,
        display: "grid",
        gridTemplateColumns: `repeat(${headers.length}, auto)`,
        gridGap: 1,
        maxHeight: 110,
        overflowY: "scroll",
      }}
    >
      {headers.map((header) => (
        <Cell key={header} style={{ opacity: 0.5 }}>
          {formattedLabel(labelDictionary, null, header)}
        </Cell>
      ))}
      {values.map((d) =>
        headers.map((header, i) => (
          <Cell
            key={i}
            style={filter[header]?.includes(d[header]) ? { color: "hsl(269deg 98% 52%)", fontWeight: 500 } : {}}
          >
            {formattedLabel(labelDictionary, d[header], header, { returnNull: true })}
          </Cell>
        )),
      )}
    </div>
  );
}

const Cell = styled.div`
  padding: 4px 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
