import React from "react";
import styled from "@emotion/styled/macro";
import { useTheme } from "@mui/styles";

export default function EditorItemForText({
  data,
  schema = {},
  onChange,
  isDisabled,
  autoFocus,
  extraStyles = {},
  placeholderStyle = {},
}) {
  const inputRef = React.useRef();
  const [state, setState] = React.useState(data);
  const theme = useTheme();

  React.useEffect(() => {
    setState(data);
  }, [data]);

  // Only call onChange in a debounced fashion
  React.useEffect(() => {
    const handler = setTimeout(() => {
      if (onChange && data !== state) {
        onChange(() => state);
        inputRef.current.scrollIntoView({
          behavior: "auto",
          block: "center",
        });
      }
    }, 300);
    return () => {
      clearTimeout(handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <TextInput
      theme={theme}
      ref={inputRef}
      data-test-id={`adminInput${schema.title}`}
      autoFocus={autoFocus}
      placeholder={schema.title}
      style={{
        cursor: isDisabled || schema.ui__readOnly ? "not-allowed" : "auto",
        textOverflow: isDisabled && "ellipsis",
        whiteSpace: isDisabled && "nowrap",
        overflow: isDisabled && "hidden",
        ...extraStyles,
      }}
      placeholderStyle={placeholderStyle}
      value={state || ""}
      onChange={(e) => setState(schema.type === "integer" ? +e.target.value : e.target.value)}
      disabled={isDisabled || schema.ui__readOnly}
      spellCheck={false}
      autoComplete="new-password" // The only thing that seems to disable auto-complete https://developer.mozilla.org/en-US/docs/Web/Security/Securing_your_site/Turning_off_form_autocompletion#preventing_autofilling_with_autocompletenew-password
    />
  );
}

const TextInput = styled.input`
  outline-color: ${({ theme }) => theme.palette.primary.main};
  // padding: 9px 0;
  padding-left: 15px;
  display: table-cell;
  width: 100%;
  border: none;
  background: transparent;
  font-size: 14px;
  &:focus {
    outline-style: solid;
    outline-width: 2px;
  }
  &:disabled {
    color: #858574;
  }
  &::placeholder {
    opacity: ${({ placeholderStyle }) => placeholderStyle.opacity ?? "0.5"};
    color: ${({ placeholderStyle }) => placeholderStyle.color ?? undefined};
    text-transform: ${({ placeholderStyle }) => placeholderStyle.textTransform ?? undefined};
  }
`;
