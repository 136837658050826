export function sortAndFilterUserList({ users, tab, searchText, filteredRoles = [], inOrganizationContext = false }) {
  if (users === undefined) return [];
  const usersToShow = [];

  for (const user of users) {
    const isBw7employee = user.email.endsWith("@blackwoodseven.com");
    const isInternal = user.email.endsWith("@kantar.com");
    const matchesTab = (tab === "internal" && isInternal) || (tab === "external" && !isInternal) || tab === "all";

    const matchesSearch =
      user?.firstName?.toLowerCase()?.includes(searchText) ||
      user?.lastName?.toLowerCase()?.includes(searchText) ||
      user?.email?.toLowerCase()?.includes(searchText);

    const matchesRole =
      filteredRoles.length === 0 || filteredRoles.includes(inOrganizationContext ? user.orgRole : user.defaultRole);
    if (matchesTab && matchesSearch && matchesRole) {
      usersToShow.push({ ...user, isBw7employee });
    }
  }

  usersToShow.sort((a, b) => a.email.localeCompare(b.email));

  return usersToShow;
}
