// Some features we like to see them enabled by default when we're developing locally
const TRUE_IN_DEV_MODE = process.env.NODE_ENV === "development" || undefined;

// This object shows available features with their labels and default states.
// DEV -> This feature is currently in development and can be moved to review on dev completion
// REVIEW -> This feature is currently in REVIEW by the product team and can be moved to production only post approval
// PROD -> These features are visible to clients as part of the production release
const FEATURES = {
  // DEV ->
  "Use experimental optimizer": {
    label: "Use experimental optimizer",
    state: false,
    in: "DEV",
  },
  "Optimization Algorithm": {
    label: "Optimization Algorithm",
    state: false,
    in: "DEV",
  },

  //REVIEW ->
  "Cancel optimisation": {
    label: "Cancel running optimisation",
    state: TRUE_IN_DEV_MODE,
    in: "REVIEW",
    ahaId: "UMMO-23",
  },
  NPS: {
    label: "New prediction service",
    state: TRUE_IN_DEV_MODE,
    in: "REVIEW",
  },
  // PROD ->
  "BW7 theme": {
    label: "BW7 look and feel",
    in: "PROD",
  },
  "Media insights": {
    label: "Media insights",
    in: "PROD",
  },
  "Business insights": {
    label: "Business insights",
    in: "PROD",
  },
  Predict: {
    label: "Prediction module (includes Optimisation)",
    in: "PROD",
  },
  Simulator: {
    label: "Simulation (within Prediction module)",
    in: "PROD",
    ahaId: "LROI-817",
  },
  "Activity evaluation": {
    label: "Activity evaluation",
    in: "PROD",
  },
  "PostgreSQL for MI/BI": {
    // To be removed once all data is in PostgreSQL
    label: "PostgreSQL for MI/BI",
    in: "PROD",
    ahaId: "LROI-767",
  },
};

export function getFeatureLabel(feature) {
  return FEATURES[feature]?.label ?? feature;
}

export default FEATURES;
