import backend from "backend";
import buildOptimizationParams from "./buildOptimizationParams";

function validateRequest({
  socketSlug,
  whatSocketCallsModelslug,
  objective,
  isAdding,
  addedOrEditedConstraint,
  useExperimentalOptimizer,
  data,
  index,
  isFeatureAvailable,
}) {
  const predictionURL = isFeatureAvailable("NPS") ? "v4/predictions" : "v3/predictions";
  const payload = buildOptimizationParams({
    objective,
    modelslug: whatSocketCallsModelslug,
    isAdding,
    useExperimentalOptimizer,
    extraConstraint: addedOrEditedConstraint,
    data,
    index,
  });

  return backend
    .post(`${predictionURL}/${socketSlug}/validate`, payload)
    .then((res) => {
      console.log("Contraints validated:", res);
      return res;
    })
    .catch((err) => {
      console.error("Error while verifying constraints", err);
      // Try to find out what's going on with these errors
      // activityV2NotifyValidationError("Internal system error, please try again");
    });
}

export default validateRequest;
