import styled from "@emotion/styled";
import { Button, Checkbox, FormControlLabel, Modal } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

//Icons
import { CheckedIcon, UncheckedIcon } from "shared/Icons/EagerLoadingIcons";

export default function ConfirmationDialog({ open, onClose, onConfirmation, tab, checkAffectUsers }) {
  const theme = useTheme();
  const [confirm, setConfirm] = React.useState(false);
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ModalContent style={{ width: 390 }}>
        <h1>
          Grant access for {checkAffectUsers ? "all" : "selected"} {tab} users?
        </h1>
        <p>
          Access will be given to all selected models and features.
          <br />
          <br />
          If certain users do not require access to specific models or features, those models and features must be
          manually deselected in the users list.
        </p>
        <FormControlLabel
          sx={{
            margin: 0,
            width: "100%",
            ".MuiTypography-root": { fontSize: 14, padding: "8px", lineHeight: 1.2 },
          }}
          control={
            <Checkbox
              checked={confirm}
              onClick={() => setConfirm((pre) => !pre)}
              name="checkedF"
              color="primary"
              icon={<UncheckedIcon style={{ fontSize: "20px" }} />}
              checkedIcon={<CheckedIcon style={{ fontSize: "20px" }} />}
              sx={{ height: "20px", width: "20px", padding: 0 }}
              style={{ textAlign: "right" }}
            />
          }
          label="I understand"
        />
        <ModalActions style={{ height: "auto" }}>
          <Button
            disabled={!confirm}
            style={{
              backgroundColor: confirm ? theme.palette.primary.main : theme.palette.greyish[20],
              color: "white",
            }}
            onClick={() => {
              onConfirmation();
              onClose();
            }}
          >
            GRANT ACCESS
          </Button>
          <Button style={{ backgroundColor: "transparent", color: "black" }} onClick={onClose}>
            NO
          </Button>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
}

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: white;
  width: fit-content;
  color: black;

  h1 {
    font-size: 16px;

    //resets
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  p {
    font-size: 14px;

    //resets
    margin: 0;
    padding: 0;
  }
`;

const ModalActions = styled.div`
  display: flex;
  flex-direction: row-reverse;
  gap: 15px;
  button {
    font-size: 14px;
    padding: 10px;
    height: 32px;
  }
`;
