import styled from "@emotion/styled/macro";
import { Button, FormControl, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React from "react";

const Dropdown = React.forwardRef(
  (
    {
      value, //optional? if not provided the selected item in Menu will be matched according to selected. In some cases the same option can have different label in the Menu and in the button after selection ex: interval selector for timeline chart in MI+.
      selected, // label of selected option.
      options, //[{id: "string", label: "string", disabled: boolean }]
      onChange, // will call with ithe option id
      help = null,
      title, // There will be a title above the dropdown button
      useDynamicWidth = false, // useDynamicWidth: if true then the width of the dropdown selector will be decided on the basis of largest label of the option provided.
      fixedWidth = 180, //will only take effect if useDynamicWidth is false, to take whole parent componetn pass 100%
      clickDisabled = false, // true means menu cannot be opened
      style = { container: {}, button: {}, buttonText: {}, helpText: {}, menuText: {} }, //override default styles, container: containing div, button: MUI button. //USE IN RARE CASES ONLY.
      slotProps = {}, //it helps in positioning tooltip relative to it position https://popper.js.org/docs/v2/modifiers/offset/
      buttonTooltip = "",
      hideArrowOnDisable = false,
      iconSize = "small",
      ...props
    },
    ref,
  ) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const calculatedWidth = useDynamicWidth
      ? options.reduce((longest, obj) => {
          const str = obj.label;
          return str.length > longest.length ? str : longest;
        }, selected ?? "")?.length + "ch" // character count of longest option label
      : fixedWidth;

    const handleClick = (event) => {
      if (!clickDisabled) {
        setAnchorEl(event.currentTarget);
      }
    };

    const itemSelected = (option) => {
      onChange(option);
      setAnchorEl(null);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const [buttonWidth, setButtonWidth] = React.useState(null);
    const buttonRef = React.useRef(null);
    React.useEffect(() => {
      if (buttonRef.current) {
        setButtonWidth(buttonRef.current.offsetWidth);
      }
    }, [buttonRef?.current?.offsetWidth]);

    return (
      <FormControl style={{ width: fixedWidth === "100%" ? "100%" : "initial" }}>
        {title && (
          <MenuTitle ref={ref} variant="subtitle1">
            {title}
          </MenuTitle>
        )}
        {/* calc dynamic logic calculatedWidth + 10px buffer + 24px for arrow down/up icon */}
        <div
          style={{
            width: useDynamicWidth ? `calc(${calculatedWidth} + 12px + 24px + 8px)` : calculatedWidth,
            fontSize: "14px", // this font size must be here to useDynamicWidth based on ch unit.
            ...style.container,
          }}
        >
          <Tooltip title={buttonTooltip} arrow slotProps={{ ...slotProps }}>
            <DropDownButton
              ref={buttonRef}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="outlined"
              disableElevation
              onClick={handleClick}
              endIcon={
                hideArrowOnDisable ? (
                  !clickDisabled ? (
                    iconSize === "small" ? (
                      <DropdownIcon />
                    ) : (
                      <DropdownIconBig />
                    )
                  ) : null
                ) : iconSize === "small" ? (
                  <DropdownIcon />
                ) : (
                  <DropdownIconBig />
                )
              }
              disableFocusRipple={true}
              disableRipple={true}
              disableTouchRipple={true}
              open={open}
              style={{ ...style.button, cursor: clickDisabled && "not-allowed" }}
            >
              <ButtonText style={{ ...style.buttonText }}>{selected}</ButtonText>
            </DropDownButton>
          </Tooltip>
          <Menu
            MenuListProps={{
              disablePadding: true,
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                width: buttonWidth,
                borderRadius: 0,
                boxShadow: "0px 4px 20px 3px rgba(0, 0, 0, 0.10)",
              },
            }}
          >
            {help && (
              <Help disabled style={style?.helpText}>
                {help}
              </Help>
            )}
            {options?.map((option) => (
              <DropdownMenuItem
                key={option.id}
                disabled={option.disabled}
                onClick={() => itemSelected(option.id)}
                selected={value ? value === option.label : selected === option.label}
                style={style.menuText}
              >
                {option.label}
              </DropdownMenuItem>
            ))}
          </Menu>
        </div>
      </FormControl>
    );
  },
);

export default Dropdown;

const DropdownIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4002 11.0001L11.8002 14.6001L8.2002 11.0001"
      stroke="#737373"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DropdownIconBig = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Dropdown Icon Big">
      <path
        id="Vector"
        d="M18 9.5489L12 15.5489L6 9.5489"
        stroke="#858574"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);

export const DropDownButton = styled(Button)`
  && {
    background-color: transparent;
    color: ${({ theme }) => theme.palette.greyish[80]};
    border: 1px solid ${({ theme }) => theme.palette.greyish[20]};
    border-radius: 0;
    padding: 0;
    height: 24px;
    text-transform: capitalize;
    font-size: inherit;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &:hover {
      border: 1px solid ${({ theme }) => theme.palette.greyish[20]};
    }
  }
  .MuiButton-endIcon {
    transform: ${({ open }) => (open ? "rotate(180deg)" : "initial")};
    transition: transform 200ms;
    margin-right: 0px;
  }
`;

export const ButtonText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: var(--default-line-height);
  letter-spacing: var(--default-letter-spacing);
  padding: 0px 4px;
`;

const StyledMenuItemBase = styled(MenuItem)`
  && {
    padding: 8px;
    white-space: normal;
    line-height: var(--default-line-height);
  }
`;
const Help = styled(StyledMenuItemBase)`
  && {
    opacity: 1; //override the mui disabled opacity
    color: ${({ theme }) => theme.palette.greyish[50]};
    font-size: ${({ style }) => style?.fontSize || "12px"};
    border-bottom: 1px solid ${({ theme }) => theme.palette.greyish[20]};
    background-color: ${({ theme }) => theme.palette.whiteish.main};
  }
`;

export const DropdownMenuItem = styled(StyledMenuItemBase)`
  && {
    text-transform: capitalize;
    font-size: ${({ style }) => style?.fontSize || "12px"};
    color: ${({ selected }) => (selected ? "white" : "black")};
  }
`;

export const MenuTitle = styled(Typography)(
  ({ theme }) => `
  && {
      margin-bottom: 2px;
      letter-spacing: 0.5px;
      line-height: var(--default-line-height);
      font-size: 10px;
      color: ${theme.palette.greyish[50]};
      text-transform: capitalize;
  }
`,
);
