import { TextField, InputAdornment } from "@mui/material";
import { useTheme } from "@mui/styles";
import { ReactComponent as SearchIcon } from "shared/Icons/Magnifier-black.svg";
import { ReactComponent as CrossIcon } from "shared/Icons/cross.svg";

//common search bar component with proper icons, to make disabled just pass onChange as null
export default function SearchBar({
  onChange,
  value,
  height = 32,
  iconSize = 20,
  inputStyles = {},
  placeholder = "search",
  ...props
}) {
  const theme = useTheme();
  const disabled = !onChange;
  const defaultInputStyles = {
    padding: "0 8px",
    gap: "8px", //gap between icon and text
    fontSize: "14px",
    borderRadius: 0,
    backgroundColor: theme.palette.greyish[10],
    height: `${height}px`,
    lineHeight: "var(--default-line-height)",
    "&.Mui-focused": {
      backgroundColor: theme.palette.whiteish.main,
    },
    ...inputStyles,

    input: {
      padding: 0, //default html input reset
      lineHeight: "var(--default-line-height)",
      caretColor: theme.palette.greyish[25],
    },
    "input::-webkit-input-placeholder": {
      fontSize: "14px",
      color: theme.palette.greyish[50],
      opacity: 1,
    },
  };
  return (
    <TextField
      sx={{
        width: "100%",
        padding: 0,
        margin: 0,
        fontSize: "14px",
        "& .MuiInputBase-root": { ...(defaultInputStyles ?? {}) },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment
            position="end"
            sx={{
              margin: 0,
              img: {
                height: iconSize,
                width: iconSize,
              },
            }}
          >
            {value === "" ? (
              <SearchIcon height={20} width={20} />
            ) : (
              !disabled && (
                <CrossIcon height={20} width={20} onClick={() => onChange("")} style={{ cursor: "pointer" }} />
              )
            )}
          </InputAdornment>
        ),
        autoComplete: "section-search email",
        /* 
                The section prefix (e.g., section-email, section-address) indicates that 
                the following input fields belong to a specific logical section of the form. 
                This helps browsers provide more relevant autofill suggestions based on 
                previously entered data for that section.
                */
      }}
      hiddenLabel
      variant="filled"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      {...props}
    />
  );
}
