import backend from "backend";
import { useAccount } from "auth";

export default function useLongPollingPrediction(socketSlug) {
  const { isFeatureAvailable } = useAccount();
  const predictionURL = isFeatureAvailable("NPS") ? "v4/predictions" : "v3/predictions";
  const { data, mutate } = backend.useData(
    `${predictionURL}/${socketSlug}`,
    null, // No query params
    { refreshInterval: 10000 }, // Long polling
    true, // Logging disabled
  );

  // We currently only support one prediction (it'll return an array of one)
  return { ...(data?.[0] || {}), invalidate: mutate };
}
