import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  TextField,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { styled } from "@mui/system";
import { addMonths, differenceInDays, subYears } from "date-fns";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { NumericFormat } from "react-number-format";
import { useSelector, useDispatch } from "react-redux";

import { NewSwitch } from "shared/components/NewSwitch";
import PeriodPicker from "shared/components/PeriodPicker";
import { TextButton } from "shared/styledComponents/TextButton";
import BriefType from "./BriefType";
import ConstraintsTable from "./ConstraintsTable";
import { newPrediction } from "./useData";

import { useAccount } from "auth";
import backend from "backend";

import getWhatSocketCallsModelslug from "shared/helpers/getWhatSocketCallsModelslug";
import { adjustPeriod, dateToString, prettyPeriod } from "shared/helpers/time";
import buildOptimizationParams from "./helpers/buildOptimizationParams";
import getMoneyIcon from "./helpers/getMoneyIcon";
import validateRequest from "./helpers/validateRequest";

import { ReactComponent as CrossIcon } from "shared/Icons/cross.svg";
import { ReactComponent as SaveIcon } from "shared/Icons/check.svg";
import { ReactComponent as EditIcon } from "shared/Icons/edit-2.svg";
import { ReactComponent as CalendarIcon } from "shared/Icons/calendar.svg";
import { ReactComponent as TargetIconGrey } from "shared/Icons/target-icon-grey.svg";

import { useNavigateWithParams } from "shared/hooks/useNavigateWIthParams";
import {
  hideActivityDialog,
  updateField,
  updateOverallPredictionError,
  selectIsPredictionError,
  selectData,
  selectIsVisible,
  selectIsModify,
  selectInlineEditor,
  selectModifySource,
} from "store/reducers/activityDialog";
import CustomTooltip from "shared/components/CustomTooltip";
import { deleteSavedInputState } from "store/reducerUtils/optimisationIndexedDB";

export default function CreateOptimisation() {
  const [{ open, content, onConfirmation, method }, modalDispatch] = React.useReducer(modalReducer, {
    open: false,
    content: {},
  });
  const [isValidating, setIsValidating] = React.useState(false);
  const [isFocused, setIsFocused] = React.useState(false);
  const [emailNotification, setEmailNotification] = React.useState(true);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { selectedModel, selectedModelConf: modelConf, isFeatureAvailable } = useAccount();
  const { brand = "", name = selectedModel, socketSlug, objective, currency, mediaGroupings, weekStart } = modelConf;
  const selectedOrg = JSON.parse(sessionStorage.getItem("selectedOrg"));

  const isError = useSelector(selectIsPredictionError);
  const data = useSelector(selectData);
  const isVisible = useSelector(selectIsVisible);
  const isModify = useSelector(selectIsModify);
  const inlineEditor = useSelector(selectInlineEditor);
  const modifySource = useSelector(selectModifySource);
  const handleUpdateField = (fieldData) => {
    dispatch(updateField(fieldData));
  };

  if (!objective) return null;
  const showVatMessage = socketSlug.includes("tsb");

  const fieldsToValidate = [data.startDate, data.endDate, data.budget];
  if (data.selectedBrief === "targetBased") fieldsToValidate.push(data.target);

  const validForRequest = fieldsToValidate.every((d) => !_.isNil(d));
  const PopupCanClose = fieldsToValidate.some((d) => !_.isNil(d));
  const mainInputsDisabledBecauseThereAreConstraints = data.constraints.length > 0;
  const MoneyIcon = getMoneyIcon(currency);
  const optTextLabel = isFeatureAvailable("Simulator") ? "optimisation" : "prediction";

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const handlePredict = ({
    socketSlug,
    whatSocketCallsModelslug,
    uiModelInfo,
    objective,
    tolerance,
    useExperimentalOptimizer,
    emailNotification,
  }) => {
    if (data.constraints.length > 0) {
      setIsValidating(true);
      validateRequest({
        socketSlug,
        whatSocketCallsModelslug,
        objective,
        isAdding: false,
        useExperimentalOptimizer,
        data,
        index: inlineEditor.index,
        isFeatureAvailable,
      }).then((validateData) => {
        setIsValidating(false);
        if (validateData?.constraintsValid) {
          callCreatePrediction(whatSocketCallsModelslug, uiModelInfo, tolerance, useExperimentalOptimizer);
        }
      });
    } else {
      callCreatePrediction(whatSocketCallsModelslug, uiModelInfo, tolerance, useExperimentalOptimizer);
    }
  };

  const callCreatePrediction = (whatSocketCallsModelslug, uiModelInfo, tolerance, useExperimentalOptimizer) => {
    dispatch(
      newPrediction(
        buildOptimizationParams({
          objective,
          modelslug: whatSocketCallsModelslug,
          uiModelInfo,
          tolerance,
          useExperimentalOptimizer,
          data,
          index: inlineEditor.index,
          emailNotification,
        }),
        socketSlug,
        dispatch,
        isFeatureAvailable,
      ),
    );
    deleteSavedInputState().then(() => console.log("stage deleted successfully"));
    dispatch(hideActivityDialog());
  };

  const checkPeriodFilter = (date, startDate, endDate) => {
    return date === startDate || date === endDate || moment(date).isBetween(startDate, endDate);
  };
  const validatePeriodFilter = (constraintStartDate, constraintEndDate, startDate, endDate) => {
    return (
      !checkPeriodFilter(constraintStartDate, startDate, endDate) &&
      !checkPeriodFilter(constraintEndDate, startDate, endDate)
    );
  };

  const inputCSS = (isFieldError = false) => {
    return {
      width: "280px",
      "& .MuiInputLabel-root.Mui-focused": { color: "rgba(0, 0, 0, 0.6)" },
      "& .MuiFormLabel-root": { fontSize: "10px", top: "-4px", transform: "scale(1)" },
      "& .MuiInputBase-root": {
        border: `1px solid ${isFieldError ? "red" : theme.palette.greyish[25]}`,
        padding: "3px 10px",
        fontSize: "14px",
        height: "32px",
        marginTop: "10px",
      },
      "& .MuiInputBase-root:before": { borderBottom: "0px" },
      "& .MuiInputBase-root:after": {
        border: `2px solid ${theme.palette.primary.main}`,
        transition: "none",
        height: "100%",
      },
      ".Mui-focused": {
        border: "none",
      },
      "& .MuiInput-input:-webkit-autofill": {
        transition: "background-color 0s 600000s, color 0s 600000s !important",
      },
    };
  };

  const handleInvestmentChange = debounce((e) => {
    handleUpdateField({ field: "currentBudget", value: numeral(e.target.value).value() });
    if (!data.budget && !data.currentBudget) {
      handleUpdateField({ field: "budget", value: numeral(e.target.value).value() });
    } else {
      document.getElementById("mediaSaveReset").style.display = "none";
      if (isFocused && data.budget !== numeral(e.target.value).value()) {
        document.getElementById("mediaSaveReset").style.display = "block";
      }
    }
  }, 500);

  const handleTargetChange = debounce((e) => {
    handleUpdateField({ field: "currentTarget", value: numeral(e.target.value).value() });
    if (!data.target && !data.currentTarget) {
      handleUpdateField({ field: "target", value: numeral(e.target.value).value() });
    } else {
      document.getElementById("targetSaveReset").style.display = "none";
      if (isFocused && data.target !== numeral(e.target.value).value()) {
        document.getElementById("targetSaveReset").style.display = "block";
      }
    }
  }, 500);
  const diffInStartEndDate = differenceInDays(data?.endDate, data?.startDate);
  if (!isNaN(diffInStartEndDate) && diffInStartEndDate < 6) {
    dispatch(updateOverallPredictionError({ errorStatus: true }));
  }
  return (
    <>
      <Dialog
        open={isVisible}
        style={{ "--common-left-right-padding": "35px" }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              borderRadius: 0,
              width: "100%",
              minWidth: "1150px",
              maxWidth: "min(var(--main-div-max-width), 1526px)", // Set your width here
            },
          },
          "& .MuiDialogContent-root": {
            paddingTop: "0px",
          },
        }}
      >
        <DialogContentStyled style={{ opacity: isValidating && "0.5" }}>
          <ModelHeader>
            <h2>Run an {optTextLabel}</h2>
            <IconButton
              aria-label="close-activity-popup"
              sx={{ padding: 0, color: "#000" }}
              onClick={() => {
                if (PopupCanClose) {
                  modalDispatch({
                    type: "closePopup",
                    description: (
                      <p style={{ whiteSpace: "pre-line", fontSize: "14px" }}>
                        {isModify
                          ? "Are you sure you want to quit? All modifications made by you (if any) will be lost."
                          : "Are you sure you want to quit? All inputs and constraints will be lost."}
                      </p>
                    ),
                    onConfirmation: () => {
                      deleteSavedInputState().then(() => console.log("stage deleted successfully"));
                      dispatch(hideActivityDialog());
                    },
                    isSimAvailable: isFeatureAvailable("Simulator"),
                  });
                } else {
                  deleteSavedInputState().then(() => console.log("stage deleted successfully"));
                  dispatch(hideActivityDialog());
                }
              }}
            >
              <CloseIcon sx={{ width: "20px", height: "20px" }} />
            </IconButton>
          </ModelHeader>
          <form action="" data-ref="new-activity-form" style={{ display: "grid", gap: 30 }}>
            <BriefType
              disabled={
                isFeatureAvailable("Cancel optimisation") && modifySource === "cancelPage"
                  ? false
                  : isModify || mainInputsDisabledBecauseThereAreConstraints
              }
              selectedType={data.selectedBrief}
              onSelectType={(newType) => {
                handleUpdateField({ field: "selectedBrief", value: newType });
                if (
                  newType === "budgetBased" &&
                  !isFeatureAvailable("Cancel optimisation") &&
                  modifySource !== "cancelPage"
                ) {
                  handleUpdateField({ field: "target", value: null });
                  handleUpdateField({ field: "currentTarget", value: null });
                }
              }}
              MoneyIcon={MoneyIcon}
            />

            <BudgetTargetFrame>
              <CustomTooltip
                dark
                content={
                  diffInStartEndDate < 6 && (
                    <div style={{ padding: "0px 15px", maxWidth: 400 }}>
                      <p>Optimisation can run for minimum 1 week.</p>
                    </div>
                  )
                }
                placement="bottom"
                arrow
              >
                <div>
                  <PeriodPicker
                    period={data.startDate && data.endDate && [data.startDate, data.endDate]}
                    onChange={(range) => {
                      const adjustedRange = adjustPeriod(range, { forceWeekly: false, weekStartsOn: weekStart }); //snap period to minimum a week if selected is less than a week
                      handleUpdateField({ field: "startDate", value: adjustedRange[0] });
                      handleUpdateField({ field: "endDate", value: adjustedRange[1] });
                      if (data.constraints.length > 0) {
                        const validateApplicablePeriod = data.constraints.some((d) =>
                          validatePeriodFilter(d.startDate, d.endDate, adjustedRange[0], adjustedRange[1]),
                        );
                        dispatch(updateOverallPredictionError({ errorStatus: validateApplicablePeriod }));
                      } else {
                        dispatch(updateOverallPredictionError({ errorStatus: false }));
                      }
                    }}
                    showPresetsFuture
                    // See https://blackwoodseven.slack.com/archives/CJW82FTK6/p1639146428003200?thread_ts=1639120161.002600&cid=CJW82FTK6
                    allowedPeriod={[subYears(new Date(), 2), addMonths(new Date(), 19)].map(dateToString)}
                    modelConf={modelConf}
                    inputRender={({ period, onClick }) => (
                      <TextField
                        autoComplete="off"
                        value={period ? prettyPeriod(period, { resolveFullPeriods: false, showYear: true }) : ""}
                        onClick={onClick}
                        label={`${optTextLabel.toUpperCase()} PERIOD`}
                        inputProps={{ style: { caretColor: "transparent" } }}
                        data-ref="predPeriodPicker"
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Icon sx={{ display: "flex", alignItems: "center" }}>
                                <CalendarIcon height={20} width={20} />
                              </Icon>
                            </InputAdornment>
                          ),
                          endAdornment: (data.startDate || data.endDate) && (
                            <div className="show-on-hover">
                              <Icon sx={{ display: "flex" }}>
                                <EditIcon height={20} width={20} />
                              </Icon>
                            </div>
                          ),
                          sx: {
                            borderRadius: 0,
                            "& > .show-on-hover": {
                              display: "none",
                            },
                            "&:hover:not(.Mui-disabled, .Mui-error):before": {
                              borderBottom: `2px solid ${diffInStartEndDate < 6 ? "red" : "black"}`,
                            },
                            "&:not(.Mui-focused):hover > .show-on-hover": {
                              display: "block",
                            },
                          },
                        }}
                        sx={inputCSS(diffInStartEndDate < 6)}
                      />
                    )}
                  />
                </div>
              </CustomTooltip>
              <StyledContainer>
                <NumericFormat
                  data-ref="budgetAmount"
                  id="ghost-inspector-budget-input"
                  customInput={TextField}
                  value={data.currentBudget}
                  thousandSeparator={true}
                  // disabled={isModify || mainInputsDisabledBecauseThereAreConstraints}
                  onChange={handleInvestmentChange}
                  onFocus={() => {
                    setIsFocused(true);
                    if (data.budget && data.currentBudget && data.budget !== data.currentBudget) {
                      document.getElementById("mediaSaveReset").style.display = "block";
                    }
                  }}
                  onBlur={() => {
                    setIsFocused(false);
                    document.getElementById("mediaSaveReset").style.display = "none";
                    if (data.budget && data.budget !== data.currentBudget) {
                      handleUpdateField({ field: "currentBudget", value: data.budget });
                    }
                  }}
                  variant="standard"
                  label={`${data.selectedBrief === "targetBased" ? "MAXIMUM BUDGET" : "MEDIA INVESTMENT"}${
                    showVatMessage ? " (excluding VAT)" : ""
                  }`}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {MoneyIcon ? <MoneyIcon /> : currency?.toUpperCase()}
                      </InputAdornment>
                    ),

                    endAdornment: data.currentBudget && (
                      <div className="show-on-hover">
                        <Icon sx={{ display: "flex" }}>
                          <EditIcon height={20} width={20} />
                        </Icon>
                      </div>
                    ),
                    sx: {
                      borderRadius: 0,
                      "& > .show-on-hover, & .show-on-focus": {
                        display: "none",
                      },
                      "&:not(.Mui-focused):hover > .show-on-hover": {
                        display: "block",
                      },
                      "&.Mui-focused .show-on-focus": {
                        display: "block",
                      },
                    },
                  }}
                  sx={{
                    ...inputCSS(),
                    "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before": {
                      borderBottom: `2px solid rgba(0, 0, 0, 0.87)}`,
                    },
                  }}
                />
                <InputButtonContainer id={"mediaSaveReset"}>
                  <div style={{ display: "flex", gap: "6px", height: "24px" }}>
                    <Tooltip title="Save changes" arrow>
                      <IconButton
                        sx={{ display: "flex", flexDirection: "row", padding: 0 }}
                        onMouseDown={(e) => {
                          if (data.budget !== data.currentBudget) {
                            handleUpdateField({ field: "budget", value: data.currentBudget });
                          }
                        }}
                      >
                        <SaveIcon height={20} width={20} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Discard changes" arrow>
                      <IconButton
                        sx={{ display: "flex", padding: 0 }}
                        onMouseDown={(e) => {
                          if (data.budget !== data.currentBudget) {
                            handleUpdateField({ field: "currentBudget", value: data.budget });
                          }
                        }}
                      >
                        <CrossIcon height={20} width={20} />
                      </IconButton>
                    </Tooltip>
                  </div>
                </InputButtonContainer>
              </StyledContainer>
              {data.selectedBrief === "targetBased" && (
                <StyledContainer>
                  <NumericFormat
                    data-ref="targetAmount"
                    id="ghost-inspector-target-input"
                    value={data.currentTarget}
                    variant="standard"
                    onChange={handleTargetChange}
                    onFocus={() => {
                      setIsFocused(true);
                      if (data.target && data.currentTarget && data.target !== data.currentTarget) {
                        document.getElementById("targetSaveReset").style.display = "none";
                      }
                    }}
                    onBlur={() => {
                      setIsFocused(false);
                      document.getElementById("targetSaveReset").style.display = "none";
                      if (data.target && data.target !== data.currentTarget) {
                        handleUpdateField({ field: "currentTarget", value: data.target });
                      }
                    }}
                    thousandSeparator={true}
                    // disabled={isModify || mainInputsDisabledBecauseThereAreConstraints}
                    customInput={TextField}
                    autoComplete="off"
                    label="TARGET"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Icon sx={{ display: "flex", alignItems: "center" }}>
                            <TargetIconGrey height={20} width={20} />
                          </Icon>
                        </InputAdornment>
                      ),
                      endAdornment: data.currentTarget && (
                        <div className="show-on-hover">
                          <Icon sx={{ display: "flex" }}>
                            <EditIcon height={20} width={20} />
                          </Icon>
                        </div>
                      ),
                      sx: {
                        borderRadius: 0,
                        "& > .show-on-hover, & .show-on-focus": {
                          display: "none",
                        },
                        "&:not(.Mui-focused):hover > .show-on-hover": {
                          display: "block",
                        },
                        "&.Mui-focused .show-on-focus": {
                          display: "block",
                        },
                      },
                    }}
                    sx={inputCSS()} //for target no need to show warning
                  />
                  <InputButtonContainer id="targetSaveReset">
                    <div style={{ display: "flex", gap: "6px", height: "24px" }}>
                      <Tooltip title="Save changes" arrow>
                        <IconButton
                          sx={{ display: "flex", flexDirection: "row", padding: 0 }}
                          onMouseDown={(e) => {
                            if (data.target !== data.currentTarget) {
                              handleUpdateField({ field: "target", value: data.currentTarget });
                            }
                          }}
                        >
                          <SaveIcon height={20} width={20} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Discard changes" arrow>
                        <IconButton
                          sx={{ display: "flex", padding: 0 }}
                          onMouseDown={(e) => {
                            if (data.target !== data.currentTarget) {
                              handleUpdateField({ field: "currentTarget", value: data.target });
                            }
                          }}
                        >
                          <CrossIcon height={20} width={20} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </InputButtonContainer>
                </StyledContainer>
              )}
            </BudgetTargetFrame>

            <ConstraintsTable disabled={!validForRequest} modalDispatch={modalDispatch} />

            <div
              style={{
                fontSize: "12px",
                color: theme.palette.greyish[50],
              }}
            >
              Caution: Results will be more uncertain if you invest very differently or you optimise far into the
              future.
            </div>
          </form>
        </DialogContentStyled>
        <PredictionOverwriteWarning
          socketSlug={socketSlug}
          hideActivityDialog={hideActivityDialog}
          modalDispatch={modalDispatch}
          isFeatureAvailable={isFeatureAvailable}
        />
        <DialogActions
          style={{
            backgroundColor: "#F2F1EE",
            borderTop: `1px solid ${theme.palette.greyish[20]}`,
            padding: "7.5px 40px",
            justifyContent: "flex-start",
          }}
        >
          <NewSwitch
            checked={emailNotification}
            onChange={() => setEmailNotification((prevState) => !prevState)}
            darkUnchecked
          />
          <div style={{ marginRight: "auto", fontSize: "12px", marginLeft: 6, color: "black" }}>
            Send email notification when optimisation are complete.
          </div>
          {isValidating && (
            <div style={{ margin: "0px 15px", fontSize: "12px", color: theme.palette.primary.main }}>
              <CircularProgress size={20} sx={{ marginRight: "10px", marginBottom: "-4px" }} />
              Validating the constraints. Please wait.
            </div>
          )}
          <TextButton
            variant="contained"
            data-ref="new-activity-predict"
            disabled={!validForRequest || inlineEditor.editing || isError || isFocused || data.currentBudget <= 0}
            sx={{
              "&:disabled": {
                color: "#fff",
                background: "#858574",
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              handlePredict({
                socketSlug,
                whatSocketCallsModelslug: isFeatureAvailable("NPS")
                  ? socketSlug
                  : getWhatSocketCallsModelslug({
                      whatSocketCallsModelslug: socketSlug,
                      modelSlug: selectedModel,
                      socketSlug,
                    }),
                uiModelInfo: {
                  uiModelSlug: selectedModel,
                  uiModelName: brand ? `${brand} ${name}` : `${name}`,
                  localRequestTime: moment().format("YYYY-MM-DD HH:mm:ss"),
                  mediaGroupingSnapshot: mediaGroupings,
                  uiOrgId: selectedOrg?.id,
                  uiModelId: modelConf?.id,
                },
                objective,
                tolerance: undefined, // ?
                useExperimentalOptimizer: isFeatureAvailable("Use experimental optimizer"),
                emailNotification: emailNotification,
              });
            }}
          >
            {isFeatureAvailable("Simulator") ? "Optimise" : "Predict"}
          </TextButton>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        open={open}
        onClose={() => modalDispatch({ type: "close", isSimAvailable: isFeatureAvailable("Simulator") })}
        content={content}
        onConfirmation={onConfirmation}
        method={method}
      />
    </>
  );
}

function ConfirmationDialog({ open, onClose, onConfirmation, content, method }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Brown, Helvetica, sans-serif",
      }}
    >
      <ModalContent style={{ width: 390, padding: "20px", gap: "20px" }}>
        <h1 style={{ fontSize: "16px" }}>{content.heading}</h1>
        <h1 style={{ fontSize: "14px" }}>{content.description}</h1>

        <ModalActions>
          <TextButton
            color={method === "EDIT" ? "primary" : "alertRed"}
            variant="contained"
            onClick={() => {
              onConfirmation();
              onClose();
            }}
          >
            {content.action}
          </TextButton>
          <TextButton color="blackish" onClick={onClose}>
            NO
          </TextButton>
        </ModalActions>
      </ModalContent>
    </Modal>
  );
}

export function modalReducer(state, action) {
  switch (action.type) {
    case "close": {
      return { open: false, content: {} };
    }
    case "closePopup": {
      return {
        open: true,
        content: {
          heading: `Quit ${action.isSimAvailable ? "optimisation" : "prediction"} `,
          action: "QUIT",
          description: action.description,
        },
        onConfirmation: action.onConfirmation,
      };
    }
    case "deleteConstraint": {
      return {
        open: true,
        content: {
          heading: "Delete constraint",
          action: "DELETE",
          description: action.description,
        },
        onConfirmation: action.onConfirmation,
      };
    }

    default: {
      return { ...state };
    }
  }
}

function PredictionOverwriteWarning({ socketSlug, hideActivityDialog, modalDispatch, isFeatureAvailable }) {
  const predictionURL = isFeatureAvailable("NPS") ? "v4/predictions" : "v3/predictions";
  const navigateWithParams = useNavigateWithParams();
  const dispatch = useDispatch();
  const { data } = backend.useData(`${predictionURL}/${socketSlug}`);
  const { extraData, result } = data?.[0] || {};

  if (result?.status === "inProgress")
    return (
      <PredictionWarningTextContainer>
        You already have a {isFeatureAvailable ? "optimisation" : "prediction"} running. Starting another will CANCEL
        the one currently in progress.
      </PredictionWarningTextContainer>
    );
  else if (result?.status === "done") {
    const anyPredictionUnsaved = ["original", "alternative", "withoutConstraints"].some((type) => {
      const id = result.createdPlans?.[type]?.investmentPlanId; // Does it exist?
      return id && (!extraData?.savedPlans || !extraData?.savedPlans.includes(id)); // Is it unsaved?
    });
    if (anyPredictionUnsaved)
      return (
        <PredictionWarningTextContainer>
          {"You have "}
          <Link
            onClick={() => {
              modalDispatch({
                type: "closePopup",
                description: (
                  <p style={{ whiteSpace: "pre-line" }}>
                    Are you sure you want to quit? All inputs and constraints will be lost.
                  </p>
                ),
                onConfirmation: () => {
                  dispatch(hideActivityDialog());
                  navigateWithParams({ path: "/optimisation" });
                },
                isSimAvailable: isFeatureAvailable("Simulator"),
              });
            }}
            style={{
              cursor: "pointer",
              fontWeight: "bold",
              color: "black",
            }}
          >
            unsaved {isFeatureAvailable ? "optimisation" : "prediction"}
          </Link>
          . Starting another will permanently DELETE them.
        </PredictionWarningTextContainer>
      );
  }

  return null;
}

const ModalContent = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  background-color: white;
  width: fit-content;
  color: black;

  h1 {
    font-size: 24px;
    //resets
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  p {
    font-size: 18px;
    //resets
    margin: 0;
    padding: 0;
  }
`;

const ModalActions = styled("div")`
  display: flex;
  flex-direction: row-reverse;
  gap: 16px;
`;

const ModelHeader = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  position: sticky;
  top: 0px;
  z-index: 10;
`;

const BudgetTargetFrame = styled("div")`
  display: flex;
  gap: 20px;
  padding-top: 5px;
`;

const DialogContentStyled = styled(DialogContent)`
  padding: 30px 40px 16px;

  h2 {
    font-size: 20px;
    font-weight: 400;
  }
  h3 {
    font-size: 16px;
    font-weight: inherit;
    margin: 0 0 19.6px 0;
  }
`;

const PredictionWarningTextContainer = styled("div")(
  ({ theme }) => `
  background-color: ${theme.palette.alertYellow.main};
  padding: 6px var(--common-left-right-padding);
  font-size: 12px;
`,
);
const StyledContainer = styled("div")`
  position: relative;
  display: flex;
  align-items: flex-end;
`;

const InputButtonContainer = styled("div")`
  display: none;
  position: absolute;
  right: 5px;
  bottom: 4px;
`;
