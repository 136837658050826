import styled from "@emotion/styled/macro";
import { Button, ButtonGroup, IconButton, MenuItem, Popover, TextField } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import React from "react";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";

import { ReactComponent as CheckIcon } from "shared/Icons/check.svg";
import { ReactComponent as CrossIcon } from "shared/Icons/cross.svg";
import { ReactComponent as DeleteRedIcon } from "shared/Icons/trash-red.svg";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import {
  addDimension,
  cancelEditor,
  deleteConstraint,
  emptyDimension,
  errorEditorValidation,
  saveConstraint,
  selectData,
  selectInlineEditor,
  startEditorValidation,
  successEditorValidation,
  updateEditor,
} from "store/reducers/activityDialog";

import getWhatSocketCallsModelslug from "shared/helpers/getWhatSocketCallsModelslug";
import { makeSlug } from "shared/helpers/formatters";
import { useLabels } from "shared/helpers/prettyNames";
import { prettyPeriod } from "shared/helpers/time";
import { getFilteringFunction } from "shared/helpers/filterOutUselessDimensionValues";
import fromKeyValueListToKeyValuesObject from "shared/helpers/fromKeyValueListToKeyValuesObject";
import { sortArrayBasedOnAnother } from "shared/helpers/sortArrayBasedOnAnother";

import { getValueTypeIds, getValueTypeLabel } from "../helpers/valueTypes";
import formattedLabel from "shared/helpers/formattedLabel";
import validateRequest from "../helpers/validateRequest";
import getMoneyIcon from "../helpers/getMoneyIcon";

import CustomTooltip from "shared/components/CustomTooltip";
import DimensionFilter from "shared/components/DimensionFilterForConstraints";
import PeriodPicker from "shared/components/PeriodPicker";

import { useAccount } from "auth";

export default function ConstraintsEditor({
  modalDispatch,
  valuesGroupedByDim,
  dimensionValuesMedia,
  availableConfigDimension,
  headers = [],
  style = {},
}) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const { selectedModel, selectedModelConf: modelConf, isFeatureAvailable } = useAccount();
  const { socketSlug, objective, currency } = modelConf;
  const [anchorElTypeSelector, setAnchorElTypeSelector] = React.useState(null);
  const MoneyIcon = getMoneyIcon(currency);
  const mainDivRef = React.useRef();
  const secondaryDivRef = React.useRef();

  const activityV2StateData = useSelector(selectData);
  const activityV2StateInlineEditor = useSelector(selectInlineEditor);
  const { index, validating, constraint } = activityV2StateInlineEditor;
  const { startDate: minDate, endDate: maxDate } = activityV2StateData;

  const availableKeysInDimension =
    dimensionValuesMedia?.length > 0
      ? dimensionValuesMedia?.map((item) => Object.keys(item)).reduce((a, b) => a.concat(b))
      : [];
  const uniqueKeysInDimension = _.uniq(availableKeysInDimension);
  const labelDictionary = useLabels(modelConf);
  let filterForNextDimension = {};
  const disabledAddButton =
    constraint.dimensions[constraint.dimensions.length - 1].selection === undefined ||
    uniqueKeysInDimension.length <= constraint.dimensions.length;

  function toggleTypeSelector(e) {
    setAnchorElTypeSelector(anchorElTypeSelector === null ? e.currentTarget : null);
  }
  const openTypeSelector = Boolean(anchorElTypeSelector);
  const idTypeSelector = openTypeSelector ? "constraintsTypeSelector" : undefined;

  const updateEditorHandler = (editorData) => {
    dispatch(updateEditor(editorData));
  };

  // this function updated the toRIght dimension filter if the current dimension filter is changes.
  // this function is written to avoid the blank filter to show in UI.
  // it calls the redux function with updated index and filter.
  // dimensionsToUpdate: this is the array to which the selection needs to be updated
  const updateAllRightFiltersOnUpdateCurrentFIlter = ({ dimensionsToUpdate, newFilter, newFilterIndex }) => {
    const storeUpdatedFilter = [];
    let filterValueForNextDim = {};
    const sliceDimension = constraint.dimensions
      .slice(0, newFilterIndex)
      .map((d) => {
        return { ...d.selection };
      })
      .reduce((acc, obj) => {
        acc = { ...acc, ...obj };
        return acc;
      }, {});
    // forEach is used just if there are multiple right filters.
    _.forEach(dimensionsToUpdate, (dimToUpdate, dIndex) => {
      // find the selection object from local stored array which is been created from dimensionsToUpdate.
      filterValueForNextDim = {
        ...sliceDimension,
        ...filterValueForNextDim,
        ...(storeUpdatedFilter[dIndex - 1] ? storeUpdatedFilter[dIndex - 1].selection : {}),
        ...newFilter,
      };
      // get the reasonableOptions, this is used to update only those options which are subset of previous filter selected.
      // Omit the used key/value which are already selected in left side filters.
      const filterOutUselessFilters = Object.keys(filterValueForNextDim).map((key) => ({
        [key]: filterValueForNextDim[key],
      }));
      const ungrouped = dimensionValuesMedia.filter((insertion) =>
        filterOutUselessFilters.every((filter) => getFilteringFunction(filter)(insertion)),
      );
      const valuesGroupedByDim2 = fromKeyValueListToKeyValuesObject(
        _.uniqWith(
          ungrouped.map((z) => _.omit(z, Object.keys(filterValueForNextDim))),
          _.isEqual,
        ),
      );

      const newFilterObj = {};
      const sortByHeader =
        headers.length > 0
          ? sortArrayBasedOnAnother(Object.keys(valuesGroupedByDim2), headers)
          : Object.keys(valuesGroupedByDim2);
      const findIndex = _.findIndex(sortByHeader, (o) => {
        return o === headers[dimToUpdate.index];
      });
      const updateValue = valuesGroupedByDim2[sortByHeader[findIndex]];
      newFilterObj[sortByHeader[findIndex]] = [updateValue[0]];

      // update the local stored array with updated value.
      // this array is used to determine which filter is selected in leftmost filter
      storeUpdatedFilter.push({ index: dimToUpdate.index, selection: newFilterObj });
      // update the right filter with redux function
      updateEditorHandler({ index: dimToUpdate.index, field: "dimensions", val: newFilterObj });
    });
  };

  const handleValidateRequest = ({
    socketSlug,
    whatSocketCallsModelslug,
    objective,
    isAdding,
    addedOrEditedConstraint,
    useExperimentalOptimizer,
  }) => {
    dispatch(startEditorValidation());
    validateRequest({
      socketSlug,
      whatSocketCallsModelslug,
      objective,
      isAdding,
      addedOrEditedConstraint,
      useExperimentalOptimizer,
      data: activityV2StateData,
      index: activityV2StateInlineEditor.index,
      isFeatureAvailable,
    })
      .then((res) => {
        console.log("Constraints validated:", res);
        if (res.constraintsValid) {
          dispatch(successEditorValidation());
          dispatch(saveConstraint({ index: activityV2StateInlineEditor.index }));
        } else {
          dispatch(
            errorEditorValidation({
              errorMessage: "Not compatible with previous inputs or available publisher inventory",
            }),
          );
        }
      })
      .catch((err) => {
        console.error("Error while verifying constraints", err);
        // Try to find out what's going on with these errors
        dispatch(errorEditorValidation({ errorMessage: "Internal system error, please try again" }));
      });
  };

  // the startDate & endDate should be equal to minDate or maxDate, or it should be between minDate and maxDate
  const checkPeriodFilter = (date) => {
    return date === minDate || date === maxDate || moment(date).isBetween(minDate, maxDate);
  };
  const validatePeriodFilter = () => {
    return (
      constraint?.startDate &&
      constraint?.endDate &&
      checkPeriodFilter(constraint.startDate) &&
      checkPeriodFilter(constraint.endDate)
    );
  };

  const dimensionHeaderLabels = constraint.dimensions.map((d) => {
    if (d.selection) {
      return Object.keys(d.selection)[0];
    } else {
      return "Dimension";
    }
  });

  const periodTooltipContent = (
    <div style={{ padding: "0px 10px", maxWidth: 400 }}>
      <p>
        The applicable period should fall within the {isFeatureAvailable("Simulator") ? "optimisation" : "prediction"}{" "}
        period.
      </p>
    </div>
  );
  const mainDivScroll = (e) => {
    secondaryDivRef.current.scrollLeft = e.target.scrollLeft;
  };
  const secondaryDivScroll = (e) => {
    mainDivRef.current.scrollLeft = e.target.scrollLeft;
  };
  return (
    <>
      {index === null && validating ? <LoaderWrapper /> : null}
      <ConstraintEditor
        className={index !== null && "customScroll"}
        ref={mainDivRef}
        constraintID={constraint.id}
        rowIndex={index}
        onScroll={mainDivScroll}
        data-ref="constraint-editor"
      >
        {index !== null && validating ? <LoaderWrapper /> : null}
        {index === null && (
          <TableRow style={{ height: "45%", textTransform: "uppercase", fontSize: "10px" }}>
            <TableRowLeft style={{ alignItems: "end", paddingBottom: "5px" }}>
              <TableCellIcon />
              <TableCellType style={{ paddingLeft: "3px" }}>TYPE</TableCellType>
              <TableCellAmount style={{ paddingLeft: "3px" }}>
                AMOUNT
                {MoneyIcon ? (
                  <IconButton sx={{ padding: "0px", lineHeight: "var(--default-line-height)", fontSize: "inherit" }}>
                    (
                    <MoneyIcon
                      sx={{
                        fontSize: "14px",
                        color: "#000",
                        fontWeight: "300",
                        width: "0.8em",
                        height: "0.8em",
                        marginBottom: "2px",
                      }}
                    />
                    )
                  </IconButton>
                ) : (
                  ` (${currency?.toUpperCase()})`
                )}
              </TableCellAmount>
              <TableCellPeriod style={{ paddingLeft: "3px" }}>APPLICABLE PERIOD</TableCellPeriod>
            </TableRowLeft>
            <TableRowRight
              style={{ alignItems: "end", paddingBottom: "5px" }}
              headerLength={dimensionHeaderLabels.length}
              constraintID={constraint.id}
              hideAddButton={uniqueKeysInDimension.length === constraint.dimensions.length}
            >
              {dimensionHeaderLabels.map((header, index) => (
                <TableCellDimension
                  key={header + index}
                  title={labelDictionary[header] ? _.upperCase(labelDictionary[header]) : _.upperCase(header)}
                  style={{ paddingLeft: "3px" }}
                >
                  {formattedLabel(labelDictionary, null, header)}
                </TableCellDimension>
              ))}
              <div></div>
            </TableRowRight>
          </TableRow>
        )}
        <TableRow style={{ height: index === null ? "55%" : "100%", fontSize: "14px" }}>
          <TableRowLeft style={{ alignItems: index === null && "start" }}>
            <TableCellIcon>
              {!validatePeriodFilter() && (
                <CustomTooltip dark content={!validatePeriodFilter() && periodTooltipContent} placement="bottom">
                  <ErrorOutlineIcon sx={{ color: "red", width: "100%", height: "100%" }} />
                </CustomTooltip>
              )}
            </TableCellIcon>
            <TableCellType>
              <Button
                aria-describedby={idTypeSelector}
                onClick={toggleTypeSelector}
                endIcon={openTypeSelector ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                sx={{
                  border: "none",
                  borderBottom: `1.5px solid ${Boolean(anchorElTypeSelector) ? theme.palette.primary.main : "black"}`,
                  color: "#000",
                  lineHeight: 1,
                  padding: "2.5px 3px",
                  width: "100%",
                  justifyContent: "space-between",
                  textTransform: "none",
                  ":hover": {
                    borderBottom: "2px solid black",
                  },
                  "& .MuiButton-endIcon": {
                    marginLeft: "0px",
                  },
                }}
              >
                {getValueTypeLabel(constraint.type)}
              </Button>
              <Popover
                id={idTypeSelector}
                open={openTypeSelector}
                anchorEl={anchorElTypeSelector}
                onClose={() => setAnchorElTypeSelector(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                {getValueTypeIds().map((d) => (
                  <MenuItem
                    data-ref={`constraint-type-${makeSlug(d.toLowerCase())}`}
                    id={`ghost-inspector-add-constraints-form-type-${makeSlug(d.toLowerCase())}`}
                    key={d}
                    value={d}
                    onClick={(event) => {
                      updateEditorHandler({ index, field: "type", val: d });
                      setAnchorElTypeSelector(null);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: d !== constraint.type ? "#F2F1EE" : theme.palette.primary.main,
                      },
                      backgroundColor: d === constraint.type && theme.palette.primary.main,
                      color: d === constraint.type && "#fff",
                      fontSize: "14px",
                      padding: "8px",
                      lineHeight: "var(--default-line-height)",
                    }}
                  >
                    {getValueTypeLabel(d)}
                  </MenuItem>
                ))}
              </Popover>
            </TableCellType>
            <TableCellAmount style={{ marginRight: "10px" }}>
              <NumericFormat
                data-ref="constraint-amount"
                id="ghost-inspector-add-constraints-form-budget"
                customInput={TextField}
                placeholder="Enter"
                variant="standard"
                thousandSeparator={true}
                value={constraint.value}
                // error={constraint.value > budget}
                onChange={(e) => {
                  const value = e.target.value ? numeral(e.target.value).value() : undefined;
                  updateEditorHandler({ index, field: "value", val: value });
                }}
                sx={{
                  width: "100%",
                  "& .MuiInputBase-root": {
                    fontSize: "14px",
                    paddingLeft: "3px",
                    marginBottom: "0.4px",
                  },
                  "& .MuiInputBase-root:before": {
                    borderBottom: `1.5px solid rgba(0, 0, 0, ${constraint.value ? "1" : "0.4"})`,
                  },
                  "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before": {
                    borderBottom: `2px solid rgba(0, 0, 0, 1)`,
                  },
                  "& .MuiInputBase-root:after": {
                    borderBottom: `2px solid ${theme.palette.primary.main}`,
                  },
                  "& .MuiInput-input:-webkit-autofill": {
                    transition: "background-color 0s 600000s, color 0s 600000s !important",
                  },
                }}
              />
            </TableCellAmount>
            <CustomTooltip
              open={show}
              disableHoverListener
              onMouseEnter={() => setShow(true)}
              onMouseLeave={() => setShow(false)}
              dark
              content={!validatePeriodFilter() && periodTooltipContent}
              placement="bottom"
            >
              <TableCellPeriod onClick={() => setShow(false)}>
                <PeriodPicker
                  period={[constraint?.startDate, constraint?.endDate]}
                  onChange={(range) => {
                    updateEditorHandler({ index, field: "startDate", val: range[0] });
                    updateEditorHandler({ index, field: "endDate", val: range[1] });
                  }}
                  allowedPeriod={[minDate, maxDate]}
                  modelConf={modelConf}
                  inputRender={({ period, onClick }) => (
                    <TextField
                      variant="standard"
                      value={period ? prettyPeriod(period, { resolveFullPeriods: false, showYear: true }) : ""}
                      onClick={onClick}
                      inputProps={{ style: { caretColor: "transparent" } }}
                      data-ref="constraintsPeriodPicker"
                      sx={{
                        width: "100%",
                        "& .MuiInputBase-root": {
                          fontSize: "14px",
                          paddingLeft: "3px",
                          marginBottom: "0.4px",
                          color: !validatePeriodFilter() && "#E10000",
                        },
                        "& .MuiInputBase-root:before": {
                          borderBottom: `1.5px solid rgba(${validatePeriodFilter() ? "0" : "255"}, 0, 0, 1)`,
                        },
                        "& .MuiInputBase-root:hover:not(.Mui-disabled, .Mui-error):before": {
                          borderBottom: `2px solid rgba(${validatePeriodFilter() ? "0" : "255"}, 0, 0, 1)`,
                        },
                      }}
                    />
                  )}
                />
              </TableCellPeriod>
            </CustomTooltip>
          </TableRowLeft>
          <TableRowRight
            style={{ alignItems: index === null && "start" }}
            headerLength={dimensionHeaderLabels.length}
            constraintID={constraint.id}
            hideAddButton={uniqueKeysInDimension.length === constraint.dimensions.length}
          >
            {constraint.dimensions.map((d, dimIndex) => {
              const dimLabel = constraint.dimensions[d.index].selection || null;
              const dimLabelSlug = dimLabel && constraint.dimensions[d.index].selection[Object.keys(dimLabel)[0]][0];
              let dimLabelValue =
                dimLabelSlug === "valuemissing" || dimLabelSlug === "blank"
                  ? "Untagged"
                  : dimLabel
                    ? formattedLabel(labelDictionary, dimLabelSlug, Object.keys(dimLabel)?.[0])
                    : null;
              if (index !== null && !dimLabelValue) {
                dimLabelValue = dimIndex > headers.length - 1 ? "Pick from the list" : "Not selected";
              }

              if (dimIndex > 0) {
                _.forEach(constraint.dimensions, (dim) => {
                  if (d.index !== dim.index && d.index > dim.index) {
                    filterForNextDimension = {
                      ...filterForNextDimension,
                      ...constraint.dimensions[dim.index].selection,
                    };
                  }
                });
              }
              const filterOutUselessFilters = Object.keys(filterForNextDimension).map((key) => ({
                [key]: filterForNextDimension[key],
              }));
              const ungrouped = dimensionValuesMedia.filter((insertion) =>
                filterOutUselessFilters.every((filter) => getFilteringFunction(filter)(insertion)),
              );
              // const dimToHide = headers.filter((q) => d?.selection && q !== Object.keys(d.selection)[0]);
              // find the selected dimensions form the state
              const uniqueSavedDimension = constraint.dimensions
                .filter((r, ri) => ri !== dimIndex)
                .map((r) => r.selection)
                .map((r) => r && Object.keys(r)[0])
                .filter((r) => r);
              let dimToHide = [];
              if (dimIndex <= headers.length - 1) {
                // find the actual index from headers array
                // this variable is used to filter the already selected dimension from dimension filter
                // along with "uniqueSavedDimension" variable
                const removeIndex = _.findIndex(availableConfigDimension, (o) => {
                  return o === headers[dimIndex];
                });
                dimToHide = _.uniq([
                  ...availableConfigDimension.filter((d, i) => removeIndex !== i),
                  ...Object.keys(filterForNextDimension),
                  ...uniqueSavedDimension,
                ]);
              } else {
                dimToHide = _.uniq([...headers, ...Object.keys(filterForNextDimension), ...uniqueSavedDimension]);
              }
              const headerFilterDImensionValue =
                headers.length === 0
                  ? dimensionValuesMedia
                  : _.uniqWith(
                      dimensionValuesMedia.map((z) => _.omit(z, dimToHide)),
                      _.isEqual,
                    );
              const filterDimensionValue = !_.isEmpty(filterForNextDimension)
                ? _.uniqWith(
                    ungrouped.map((z) =>
                      _.omit(z, [...Object.keys(filterForNextDimension), ...(headers.length > 0 ? dimToHide : [])]),
                    ),
                    _.isEqual,
                  )
                : headerFilterDImensionValue;
              return (
                <TableCellDimension key={d.index}>
                  <DimensionFilter
                    isNewDimensionRow={index === null ? true : false}
                    filter={{}}
                    onChange={(newFilter) => {
                      updateEditorHandler({ index: d.index, field: "dimensions", val: newFilter });
                      if (dimIndex < constraint.dimensions.length - 1) {
                        const dimensionsToUpdate = _.takeRight(
                          constraint.dimensions,
                          constraint.dimensions.length - 1 - d.index,
                        );
                        updateAllRightFiltersOnUpdateCurrentFIlter({
                          dimensionsToUpdate,
                          newFilter,
                          newFilterIndex: d.index,
                        });
                      }
                    }}
                    onDeleteDimension={() => {
                      dispatch(emptyDimension({ index: d.index }));
                    }}
                    selectedDimension={dimLabel || d.selection ? d.selection : {}}
                    dimensionValues={filterDimensionValue}
                    groupingToFilter={{}}
                    defaultLabel={dimLabelValue || "Pick from the list"}
                    expandedByDefault="media-grouping"
                    labelDictionary={labelDictionary}
                    color={undefined}
                    width="240px"
                    buttonHeight={"26.33px"} //using 26px (-1) and 27px(+1) is creating a difference of 1px from bottom. Using this value aligns exactly
                    // hiddenDimensions={Object.keys(filterForNextDimension)}
                  />
                </TableCellDimension>
              );
            })}
            <div id="addButtonForCOnstraintEditor">
              {uniqueKeysInDimension.length !== constraint.dimensions.length && (
                <Button
                  variant="outlined"
                  disabled={disabledAddButton || validating}
                  sx={{ padding: "0px 15px", height: "38px", top: index === null && "-12px" }}
                  onClick={() => {
                    const elementToScroll = document.getElementById("addButtonForCOnstraintEditor");
                    const scrollToElement = () => elementToScroll.scrollIntoView({ behavior: "smooth" });
                    setTimeout(scrollToElement, 0);
                    dispatch(addDimension({ index: constraint.dimensions.length }));
                  }}
                >
                  + Add
                </Button>
              )}
            </div>
            {constraint.id && (
              <div>
                <ButtonGroup
                  size="small"
                  aria-label="small button group"
                  sx={{ flexShrink: 0, display: "flex", gap: "10px", float: "right", paddingRight: "15px" }}
                >
                  <ModifiedIconButton
                    aria-label="save"
                    size="small"
                    onClick={() => {
                      if (validatePeriodFilter()) {
                        handleValidateRequest({
                          socketSlug,
                          whatSocketCallsModelslug: isFeatureAvailable("NPS")
                            ? socketSlug
                            : getWhatSocketCallsModelslug({
                                whatSocketCallsModelslug: socketSlug,
                                modelSlug: selectedModel,
                                socketSlug,
                              }),
                          objective,
                          isAdding: index === null,
                          addedOrEditedConstraint: constraint,
                          useExperimentalOptimizer: isFeatureAvailable("Use experimental optimizer"),
                        });
                      }
                    }}
                  >
                    <CheckIcon
                      style={{
                        color: "red",
                        opacity: !validatePeriodFilter() ? "0.5" : "1",
                        cursor: !validatePeriodFilter() ? "default" : "pointer",
                      }}
                    />
                  </ModifiedIconButton>

                  <ModifiedIconButton
                    aria-label="cancel"
                    size="small"
                    onClick={() => {
                      dispatch(cancelEditor());
                    }}
                  >
                    <CrossIcon />
                  </ModifiedIconButton>
                  <ModifiedIconButton
                    aria-label="delete"
                    size="small"
                    onClick={() => {
                      modalDispatch({
                        type: "deleteConstraint",
                        description: (
                          <p style={{ whiteSpace: "pre-line", fontSize: "16px" }}>
                            Are you sure you want to delete the constraint?
                          </p>
                        ),
                        onConfirmation: () => {
                          dispatch(deleteConstraint({ index }));
                        },
                      });
                    }}
                  >
                    <DeleteRedIcon />
                  </ModifiedIconButton>
                </ButtonGroup>
              </div>
            )}
          </TableRowRight>
        </TableRow>
      </ConstraintEditor>
      {/* sync scroll table */}
      {index === null && (
        <div
          className="customScroll"
          style={{ overflow: !constraint.id && "auto" }}
          ref={secondaryDivRef}
          onScroll={secondaryDivScroll}
        >
          <TableRow>
            <TableRowLeft style={{ border: "none", height: "1px" }}>
              <TableCellIcon />
              <TableCellType />
              <TableCellAmount />
              <TableCellPeriod />
            </TableRowLeft>
            <TableRowRight
              style={{ border: "none" }}
              headerLength={dimensionHeaderLabels.length}
              constraintID={constraint.id}
              hideAddButton={uniqueKeysInDimension.length === constraint.dimensions.length}
            >
              {dimensionHeaderLabels.map((header, index) => (
                <TableCellDimension
                  key={header + index}
                  title={labelDictionary[header] ? _.upperCase(labelDictionary[header]) : _.upperCase(header)}
                />
              ))}
              <div></div>
            </TableRowRight>
          </TableRow>
        </div>
      )}
    </>
  );
}

const ConstraintEditor = styled.div`
  position: relative;
  border: 1px solid ${(props) => props.theme.palette.greyish[25]};
  background-color: #fff;
  z-index: 2;
  margin-bottom: ${(props) => (props.rowIndex === null ? "3px" : "0px")};
  height: ${(props) => (props.constraintID ? "72px" : "90px")};
  overflow-x: ${(props) => (props.rowIndex === null ? "hidden" : !props.constraintID ? "auto" : "")};
  min-width: ${(props) => props.constraintID && "fit-content"};
`;

export const TableBodyRow = styled.div`
  padding: 0px 20px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-shrink: 0;
  gap: 20px;
  height: 60px;
`;

export const TableRow = styled.div`
  display: grid;
  grid-template-columns: auto 1.5fr;
  grid-template-rows: 1fr;
  gap: 10px 0px;
`;

export const TableRowLeft = styled.div`
  width: fit-content;
  display: grid;
  grid-template-columns: 21px repeat(2, 100px) 200px;
  grid-template-rows: 1fr;
  grid-auto-flow: row;
  grid-template-areas: "icon type amount period";
  align-content: center;
  gap: 5px;
  align-items: center;
  border-right: 1px solid #000;
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;
`;

export const TableRowRight = styled.div(({ headerLength, constraintID, hideAddButton = false }) => ({
  display: "grid",
  gridTemplateColumns: `repeat(${headerLength}, 150px) minmax(${hideAddButton ? "10px" : "80px"}, 1fr) ${
    constraintID ? "minmax(6.25rem, 1fr)" : "auto"
  }`,
  gap: "15px",
  marginLeft: "15px",
  alignItems: "center",
}));

export const TableCellIcon = styled.div`
  grid-area: icon;
`;

export const TableCellType = styled.div`
  grid-area: type;
  margin-right: 10px;
`;

export const TableCellAmount = styled.div`
  grid-area: amount;
  margin-right: 5px;
  display: flex;
  align-items: flex-end;
`;

export const TableCellPeriod = styled.div`
  grid-area: period;
  margin-right: 15px;
`;

export const TableCellDimension = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TableBodyItem = styled.li`
  width: 18%;
  min-width: 95px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const TableBodyItemDate = styled(TableBodyItem)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 44%;
`;

export const TableBodyItemButton = styled(TableBodyItem)`
  display: flex;
  width: 11.8%;
  min-width: 92px;
  margin-left: auto;
`;

const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  // padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  height: 100%;
`;

const ModifiedIconButton = styled(IconButton)`
  height: 24px;
  width: 24px;
`;
