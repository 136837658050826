import styled from "@emotion/styled/macro";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import SearchFilterBar from "admin/CommonComponents/SearchFilterBar";
import Tabs from "admin/CommonComponents/Tabs";
import { runToolArmValidation } from "admin/CommonComponents/Validation";
import { Title } from "admin/EditorItem/layoutComponents";
import { isValidEmail } from "admin/helpers";
import { useAccount } from "auth";
import { produce } from "immer";
import React from "react";
import RenderIfVisible from "shared/components/RenderIfVisible";
import { sessionStorageSync } from "shared/helpers/sessionStorageSync";
import store from "store";
import { notifyNew } from "store/action-creators";
import useSWR, { mutate } from "swr";
import { Entry } from "./Entry";

// Icons
import { ReactComponent as UserPlusGreyIcon } from "shared/Icons/user-plus-grey.svg";
import { ReactComponent as UserPlusIcon } from "shared/Icons/user-plus.svg";
import { sortAndFilterUserList } from "admin/CommonComponents/utilities";

// Tool Roles
export const roles = [
  {
    id: "superAdmin",
    label: "Super Admin",
  },
  {
    id: "deliveryLead",
    label: "Delivery Lead",
  },
  {
    id: "deliveryTeam",
    label: "Delivery Team",
  },
  {
    id: "client",
    label: "Client",
  },
  {
    id: "clientService",
    label: "Client Service",
  },
  {
    id: "developer",
    label: "Developer",
  },
  {
    id: "clientServiceAdmin",
    label: "Client Service Admin",
  },
];

const EMPTY_NEW_USER = { email: "", firstName: "", lastName: "", organizations: [], defaultRole: "" };

const fetcher = (url, headers) => fetch(url, { headers }).then((res) => res.json());

export default function UsersEditor({ organization, getOrgs, setDisableScroll }) {
  const [touchedEmails, setTouchedEmails] = React.useState([]); // To mark modified stuff
  const [confirmAdd, setConfirmAdd] = React.useState(false);
  const [toAdd, setToAdd] = React.useState([EMPTY_NEW_USER]);
  // const { users = [], globallyAvailableModels, globallyAvailableFeatures, modelsLabels } = useData(organization);
  const [tab, setTab] = React.useState("all");
  const [searchText, setSearchText] = React.useState("");
  const [filteredRoles, setFilteredRoles] = React.useState([]);
  const [activeEmail, setActiveEmail] = React.useState("");
  const { isClaim } = useAccount();
  const updateActiveEmail = (email) => {
    setActiveEmail(email);
    setDisableScroll(email !== "");
  };
  const { data, isLoading } = useSWR(`/backend-api/auth/v2/user/global/getall`, (url) =>
    fetcher(url, { msal_user_auth: sessionStorage.getItem("msalToken") }),
  );
  const globallyAvailableOrgs = data?.organizations;
  // const orgList = Object.entries(data?.organizations || {}).map(([key, value]) => [key, value?.name]);
  const usersToShow = React.useMemo(
    () => sortAndFilterUserList({ users: data?.users, tab, searchText, filteredRoles, inOrganizationContext: false }),
    [data?.users, filteredRoles, searchText, tab],
  );
  const toAddNonEmpty = toAdd.filter((d) => d.email.length > 0);
  const existingEmails = [...(data?.users || []), ...toAddNonEmpty].map((d) => d.email);
  const errorInToAdd = runToolArmValidation(toAddNonEmpty, existingEmails);

  // check to avoid deletion of last superAdmin
  const lastStandingAdminCheck = (email) => {
    return data?.users?.filter((user) => user.email !== email && user.defaultRole === "superAdmin").length === 0;
  };

  const actions = {
    updateUser: async (user) => {
      console.log("····· updateUser", user);

      mutate(
        `/backend-api/auth/v2/user/global/getall`,
        { ...data, users: data?.users?.map((d) => (d.email === user.email ? user : d)) },
        false, // Optimistic update
      );

      await fetch(`/backend-api/auth/v2/user/global/update`, {
        method: "PATCH",
        body: JSON.stringify({ ...user, organizations: user.accessibleOrgs.map((a) => a.orgSlug) }),
        headers: { "Content-Type": "application/json", msal_user_auth: `${sessionStorageSync.getItem("msalToken")}` },
      })
        .then(async (res) => {
          if (!res.ok) throw new Error(await res.text());
          return res.text();
        })
        .then(() => setTouchedEmails((s) => [...s, user.email]))
        .catch((err) =>
          store.dispatch(
            notifyNew({
              message: JSON.parse(err?.message)?.message?.status ?? `Failed to update ${user.email}`,
              isError: true,
            }),
          ),
        );

      mutate(`/backend-api/auth/v2/user/global/getall`); // Revalidate
      getOrgs();
    },

    deleteUser: async (email) => {
      console.log("····· deleteUser", email);
      const encodedEmail = encodeURIComponent(email);
      mutate(
        `/backend-api/auth/v2/user/global/getall`,
        { ...data, users: data?.users?.filter((d) => d.email !== email) },
        false, // Optimistic update
      );

      store.dispatch(
        notifyNew({
          message: `User ${email} removed`,
          action: {
            text: "Undo", // As you see the undo effectively reverts the action
            onClick: () => {
              fetch(`/backend-api/auth/v2/user/global/recover/${encodedEmail}`, {
                method: "PATCH",
                headers: {
                  "Content-Type": "application/json",
                  msal_user_auth: `${sessionStorageSync.getItem("msalToken")}`,
                },
              })
                .then(async (res) => (res.ok ? res.text() : Promise.reject(new Error(await res.text()))))
                .then(() => mutate(`/auth/v2/user/global/getall`))
                .catch((err) => {
                  console.error("Failed to recover", err);
                  store.dispatch(notifyNew({ message: `Failed to recover ${email}`, isError: true }));
                });
              mutate(`/backend-api/auth/v2/user/global/getall`);
            },
          },
        }),
      );

      await fetch(`/backend-api/auth/v2/user/global/remove/${encodedEmail}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json", msal_user_auth: `${sessionStorageSync.getItem("msalToken")}` },
      })
        .then(async (res) => (res.ok ? res.text() : Promise.reject(new Error(await res.text()))))
        .catch((err) => {
          console.error("Failed to remove", err);
          store.dispatch(notifyNew({ message: `Failed to remove ${email}`, isError: true }));
        });

      mutate(`/backend-api/auth/v2/user/global/getall`); // Revalidate
    },

    addUsers: async () => {
      console.log("····· addUsers", toAddNonEmpty);
      setToAdd([EMPTY_NEW_USER]); // Reset new users list
      setConfirmAdd(false); // Reset confirmation flag

      mutate(
        `/backend-api/auth/v2/user/global/getall`,
        {
          ...data,
          users: [
            ...data?.users,
            ...toAddNonEmpty.map((a) => {
              return {
                ...a,
                accessibleOrgs: a.organizations.map((org) => {
                  return { orgSlug: org, orgRole: a.defaultRole };
                }),
              };
            }),
          ],
        },
        false, // Optimistic update
      );

      store.dispatch(notifyNew({ message: `${toAddNonEmpty.length} users added` }));

      await fetch(`/backend-api/auth/v2/user/global/create`, {
        method: "POST",
        body: JSON.stringify(toAddNonEmpty), // Add organization
        headers: { "Content-Type": "application/json", msal_user_auth: `${sessionStorageSync.getItem("msalToken")}` },
      })
        .then(async (res) => {
          if (!res.ok) throw new Error(await res.text());
          return res.text();
        })
        .then(() => setTouchedEmails((s) => [...s, ...toAddNonEmpty.map((d) => d.email)]))
        .catch((err) =>
          store.dispatch(
            notifyNew({
              message: JSON.parse(err?.message)?.message?.status ?? `Failed to add ${toAddNonEmpty.length} users`,
              isError: true,
            }),
          ),
        );
      mutate(`/backend-api/auth/v2/user/global/getall`); // Revalidate
    },
  };
  const tabChange = (tab) => {
    if (activeEmail !== "") return;
    setSearchText("");
    setTab(tab);
    setDisableScroll(false);
  };

  return (
    <div>
      <div>
        <Header>
          <Tabs users={data?.users} activeEmail={activeEmail} tab={tab} tabChange={tabChange} />
          <SearchFilterBar
            roles={roles}
            filteredRoles={filteredRoles}
            setFilteredRoles={setFilteredRoles}
            searchText={searchText}
            activeEmail={activeEmail}
            setSearchText={setSearchText}
          />
          <Titles>
            <Title title="EMAIL ID" extraStylesDiv={{ fontSize: "12px", height: "30px" }} />
            <Title title="FIRST NAME" extraStylesDiv={{ fontSize: "12px", height: "30px" }} />
            <Title title="LAST NAME" extraStylesDiv={{ fontSize: "12px", height: "30px" }} />
            <Title title="ORGANIZATIONS" extraStylesDiv={{ fontSize: "12px", height: "30px" }} />
            <Title title="ROLE" extraStylesDiv={{ fontSize: "12px", height: "30px" }} />
            <Title title="" extraStylesDiv={{ height: "30px" }} />
          </Titles>
        </Header>

        {isLoading ? (
          <div style={{ width: "100%", textAlign: "center", padding: "20px" }}>
            <CircularProgress size={20} />
          </div>
        ) : usersToShow.length === 0 ? (
          <NoMatch>No results</NoMatch>
        ) : (
          usersToShow.map((data) => (
            <RenderIfVisible key={data.email} defaultHeight={40} initialVisible visibleOffset={500}>
              <Entry
                key={data.email}
                data={data}
                onChange={actions.updateUser}
                onDelete={actions.deleteUser}
                touched={touchedEmails.includes(data.email)}
                globallyAvailableOrgs={globallyAvailableOrgs}
                activeEmail={activeEmail}
                onActivate={updateActiveEmail}
                lastStandingAdminCheck={lastStandingAdminCheck}
              />
            </RenderIfVisible>
          ))
        )}
      </div>

      {isClaim("GlobalARM.Write") && (
        <BottomSheet data-test-id="add-new-users-section">
          <NewUsersContainer className="customScroll">
            {toAdd.map((newData, i) => (
              <Entry
                key={i} // The index is what better defines this
                isNew
                data={newData}
                onChange={(updated) => {
                  setToAdd(
                    produce(toAdd, (s) => {
                      s[i] = updated;
                      // If the last item has email add a new empty user
                      if (i === toAdd.length - 1 && isValidEmail(updated.email)) s.push(EMPTY_NEW_USER);
                    }),
                  );
                  setConfirmAdd(false); // User will have to mark the checkbox again
                }}
                onDelete={
                  i !== toAdd.length - 1
                    ? () => {
                        setToAdd(toAdd.filter((d, j) => i !== j));
                        setConfirmAdd(false); // User will have to mark the checkbox again
                      }
                    : undefined
                }
                globallyAvailableOrgs={globallyAvailableOrgs}
              />
            ))}
          </NewUsersContainer>

          <div style={{ display: "flex", padding: "0 15px 20px 15px", gap: "15px" }}>
            <Button
              style={{
                fontSize: "14px",
                padding: "6px 8px",
                display: "flex",
                alignItems: "center",
                gap: 8,
                height: 32,
              }}
              sx={{ "& .MuiButton-startIcon": { margin: 0 } }}
              variant="contained"
              disabled={toAddNonEmpty.length === 0 || Boolean(errorInToAdd) || !confirmAdd}
              color="primary"
              startIcon={
                toAddNonEmpty.length === 0 || Boolean(errorInToAdd) || !confirmAdd ? (
                  <UserPlusGreyIcon style={{ height: 20, width: 20 }} />
                ) : (
                  <UserPlusIcon style={{ height: 20, width: 20 }} />
                )
              }
              disableElevation
              onClick={actions.addUsers}
              data-test-id="add-new-users-button"
            >
              <span>
                Add {toAddNonEmpty.length || "new"} user{toAddNonEmpty.length <= 1 ? "" : "s"}
              </span>
            </Button>
            <div style={{ fontSize: "14px", display: "flex", alignItems: "center" }}>
              {errorInToAdd ||
                (toAddNonEmpty.length > 0 ? (
                  <>
                    <Checkbox
                      checked={confirmAdd}
                      onChange={() => setConfirmAdd((s) => !s)}
                      style={{ color: "black", padding: 0, marginRight: 8 }}
                      data-test-id="confirm-before-adding-new-users"
                    />
                    <span style={{ fontWeight: 500 }}>
                      Confirm adding {toAddNonEmpty.length} user
                      {toAddNonEmpty.length > 1 ? "s" : ""}
                    </span>
                  </>
                ) : (
                  <BottomMsg>Type in multiple users here and add them together</BottomMsg>
                ))}
            </div>
          </div>
        </BottomSheet>
      )}
    </div>
  );
}

const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid lightgray;
  background: hsla(0, 0%, 100%, 1);
  z-index: 2;
`;

const BottomSheet = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;

  background: hsla(176, 35%, 92%, 1);
  border: 1px hsl(176, 48%, 80%) solid;
  border-radius: 0px 0px 0 0;

  .MuiLink-button {
    color: inherit;
    text-decoration: underline;
  }
`;

const Titles = styled.div`
  display: grid;
  grid-template-columns: var(--tool-entry-grid-columns);
  height: 30px;
  align-items: center;
`;

const BottomMsg = styled.div`
  padding: 0;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.greyish[80]};
`;

export const ErrorMessage = styled(BottomMsg)`
  color: red;
  font-weight: 500;

  &:before {
    content: "●";
    margin-right: 6px;
  }
`;

const NewUsersContainer = styled.div`
  max-height: 35vh;
  overflow: auto;
  padding: 2px 15px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NoMatch = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px;
  font-size: 16px;
`;
