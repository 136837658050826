import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import Tooltip from "@mui/material/Tooltip";
import { findDuplicateAttributes } from "admin/helpers";
import { format, formatDistanceToNow, isWithinInterval, parseISO, subDays } from "date-fns";
import _ from "lodash";
import mediaInsightsPlusMetrics, { getDefaultMetricLabelMIPlus } from "pages/media-insights-plus/metrics";
import mediaInsightsMetrics, { getDefaultMetricLabel, getMetricLabel } from "pages/media-insights/metrics";
import { getFilteringFunction } from "shared/helpers/filterOutUselessDimensionValues";
import { filterV3Dimensions } from "shared/helpers/filterV3Dimensions";
import BusinessGroupingsEditor from "./BusinessGroupingsEditor";
import ImpactPerDimensionEditor from "./ImpactPerDimensionEditor";
import MediaGroupingsEditor from "./MediaGroupingsEditor";
import getDynamicColor from "shared/helpers/getDynamicColor";
import { checkImpactPerDimensionChanged } from "./ImpactDimensionChangePopup";
/*
TODO: UPDATE THIS
IF YOU'RE TRYING TO EVOLVE THE CONFIGURATION (EG. ADDING A PARAMETER) THIS IS THE FILE YOU NEED TO CHANGE

- The output of this function FULLY defines the configuration structure that the application will use.
- It conforms to the JSON schema spec with one exception (extra `ui__` prefixed attributes for presentation)
- The reason why a function of configuration state is used is to to create inner dependencies.
    example: The list possible models to whitelist in a feature is created from the list of configured models (see enum)

 IMPORTANT: Keep this file succint and manageable as the application evolves. Be careful with deep nesting.
*/

// Models sync from Neo and they are not editable here
// But: the 'available' property is editable.
// By default models are not available but you can change that in the configuration
export default function schemaModels({
  modelSlug,
  data,
  availableModelVersions = [],
  modelDimensionsOld = [],
  decompositionDimensionsV3 = [],
  mediaDimensionsV3 = [],
  organization,
  labelDictionary = {},
  isFeatureAvailable,
  actions,
  saveInProgress,
}) {
  const available = {
    title: "Available",
    description:
      "This works as a top level switch. Nobody will be able to see this model until it's toggled available.",
    type: "boolean",
    default: false, // Models not available by default
    ui__hidden: true, // Don't show in the model configuration screen (it'll be shown in the main screen)
  };

  const currency = {
    title: "Currency",
    type: "string",
    default: "EUR",
    ui__isError: (data) => {
      if (!data || data?.length === undefined || data?.length <= 0) return "Please provide a valid currency, eg. EUR";
    },
  };

  const objective = {
    title: "Objective Socket slug",
    description:
      "The Socket requires an objective slug for optimizations. This was thought for multi-objective models, but it's probably not going to happen. So hopefully we'll be able to remove this setting soonish.",
    type: "string",
    ui__isError: (data) => {
      if (!data || data?.length === undefined || data?.length <= 0)
        return "You need to provide an objective slug that the socket understands";
    },
  };

  const activeVersion = {
    title: "Model version",
    description: "The frontend will use this version (for now only 'prod' or 'validation' activated in the socket)",
    type: "string",
    default: "prod",
    enum: availableModelVersions.map((d) => d.mode),
    ui__labels: availableModelVersions.reduce(
      (acc, d) => ({
        ...acc,
        [d.mode]: <ModelVersionLabel {...d} organization={organization} socketSlug={data?.socketSlug} />,
      }),
      {},
    ),
  };

  const weekStart = {
    title: "Weeks start on",
    type: "integer",
    default: 1,
    enum: [0, 1, 6],
    ui__labels: { 0: "Sunday", 1: "Monday", 6: "Saturday" },
  };

  const optimizationAlgorithm = {
    title: "Optimization Algorithm",
    description:
      "CBC: our current optimizer algorithm (This will be used by DEFAULT).\n\nHeuristic: our new optimizer algorithm.",
    type: "string",
    enum: ['["CBC"]', '["Heuristic"]', '["Heuristic", "CBC"]'],
    ui__labels: {
      '["CBC"]': "CBC only",
      '["Heuristic"]': "Heuristic only",
      '["Heuristic", "CBC"]': "Heuristics + CBC",
    },
    ui__hidden: isFeatureAvailable("Optimization Algorithm") ? false : true, //hide it for PROD release?
  };

  //Need to be changed after we have completerly migrated to using postgres (v3/histoical) APIs instead of elasticsearch
  //this is a workaround for stage env where we can't run elasticsearch in stage to
  const mediaDimensionValues =
    modelDimensionsOld.length === 0
      ? mediaDimensionsV3
          .filter((d) => d.driver_type === "media")
          .map((d) => _.omit(d, ["driver_type", "effect_type", "media-grouping"])) //filter to remove synergy driver_type
      : modelDimensionsOld.filter((d) => d.driver_type === "paid_media").map((d) => _.omit(d, "driver_type"));

  const businessDimensionValues =
    filterV3Dimensions(decompositionDimensionsV3)?.map((d) => _.omit(d, "driver-custom")) ?? []; // do not show driver-custom in the filter for business grouping.

  const mediaGroupings = {
    title: "Media groupings",
    description: "Add custom groupings on top of the dimensions that the model already provides",
    type: "array",
    ui__isError: (data = []) => {
      // if (data === undefined)
      //   throw new Error("mediaGroupings should never be undefined. No groupings should be []. Fix corrupt conf in DB.");

      const validFilters = data.map((d) => d.filter).filter((d) => !_.isEmpty(d));
      const colorsConfigured = data.map((d) => d.color ?? getDynamicColor("grouping", d.slug));
      const ungrouped = mediaDimensionValues.filter(
        (insertion) => !validFilters.some((filter) => getFilteringFunction(filter)(insertion)),
      );
      const groupedLength = validFilters.reduce(
        (acc, filter) => acc + mediaDimensionValues.filter(getFilteringFunction(filter)).length,
        0,
      );
      const slugsConfigured = data.map((d) => d.slug);

      if (groupedLength + ungrouped.length > mediaDimensionValues.length)
        return "Your groupings overlap. This is not currently supported";
      for (const { name, filter } of data) {
        if (_.isEmpty(filter)) return "You need to add filters to all your groupings";
        if (name === "") return "You need to add names to all your groupings";
      }
      if (ungrouped.length > 0) return "Some elements are not grouped";

      if (new Set(slugsConfigured).size !== slugsConfigured.length)
        return "Multiple groupings have same name, please update them to have unique names.";

      if (new Set(colorsConfigured).size !== colorsConfigured.length) {
        const duplicateGroups = Object.values(
          findDuplicateAttributes(
            data.map((a) => ({ ...a, color: a.color ?? getDynamicColor("grouping", a.slug) })),
            "color",
          ),
        )
          .map((items) => `[${items.map((item) => `"${item.name}"`).join(", ")}]`)
          .join(", ");
        return `Groups ${duplicateGroups} have similar colors.`;
      }
    },
    ui__component: (props) => (
      <MediaGroupingsEditor {...props} mediaDimensionValues={mediaDimensionValues} labelDictionary={labelDictionary} />
    ),
  };

  const driverCustom = {
    title: "Business groupings",
    description:
      "Introduce custom categories alongside the existing dimensions provided by the model. These selected categories will be exclusively displayed in the user interface, while all remaining dimensions will be consolidated under the 'Other Business Drivers' category. Please note, detailed exploration('Drilldown') is not available for the 'Other Business Drivers'.",
    type: "array",
    ui__isError: (data = []) => {
      const validFilters = data.map((d) => d.filter).filter((d) => !_.isEmpty(d));
      const colorsConfigured = data.map((d) => d.color);
      const ungrouped = businessDimensionValues.filter(
        (insertion) => !validFilters.some((filter) => getFilteringFunction(filter)(insertion)),
      );
      // need to observe the behavior with overlapping of groupings in BI+
      const groupedLength = validFilters.reduce(
        (acc, filter) => acc + businessDimensionValues.filter(getFilteringFunction(filter)).length,
        0,
      );

      const slugsConfigured = data.map((d) => d.slug);
      if (groupedLength + ungrouped.length > businessDimensionValues.length)
        return "Your groupings overlap. This is not currently supported";
      for (const { name, filter } of data) {
        if (_.isEmpty(filter)) return "You need to add filters to all your groupings";
        if (name === "") return "You need to add names to all your groupings";
      }
      if (new Set(colorsConfigured).size !== colorsConfigured.length) {
        const duplicateGroups = Object.values(findDuplicateAttributes(data, "color"))
          .map((items) => `[${items.map((item) => `"${item.name}"`).join(", ")}]`)
          .join(", ");
        return `Groups ${duplicateGroups} have similar colors.`;
      }

      if (new Set(slugsConfigured).size !== slugsConfigured.length)
        return "Multiple groupings have same name, please update them to have unique names.";
    },
    ui__component: (props) => (
      <BusinessGroupingsEditor
        {...props}
        businessDimensionValues={businessDimensionValues}
        labelDictionary={labelDictionary}
        organization={organization}
        actions={actions}
        saveInProgress={saveInProgress}
      />
    ),
  };

  let mediaInsightsLabels = {};
  Object.keys(mediaInsightsMetrics).forEach(
    (key) => (mediaInsightsLabels[key] = getMetricLabel(key, data.objectiveUnitName)),
  );

  const mediaInsights__metrics_v2 = {
    title: "Media insights: visible metrics",
    description: "The KPIs or metrics that appear below the media insights timeline, KPI expansion, and KPI table.",
    type: "array",
    default: [],
    items: {
      type: "object",
      ui__uniqueKey: "slug",
      properties: {
        slug: {
          title: "Metric",
          description: "This formula is based on attribute 'output_type' in the DB",
          type: "string",
          required: true,
          ui__isSlug: true,
          enum: Object.keys(mediaInsightsMetrics).filter(
            (d) => !data?.mediaInsights__metrics_v2?.map((d) => d?.slug).includes(d),
          ),
        },
        label: {
          title: "Label (can be edited)",
          description: "Use key word {objectiveUnitName} if you want to insert the objective unit name in the label",
          type: "string",
          ui__default: (item) => getDefaultMetricLabel(item?.slug, data.objectiveUnitName),
        },
      },
    },
    ui__isError: (data) => {
      if (data === undefined) return "Media Insights will not work until you set this up";
      for (const item of data) {
        if (item?.slug === undefined) return "You need to select a metric";
      }
    },
    ui__hidden: true,
  };

  const mediaInsightsPlus__metrics_v1 = {
    title: `MEDIA INSIGHTS, PREDICTION & ACTIVITY: VISIBLE METRICS`,
    description:
      "The KPIs or metrics will appear in Media Insights: media insights timeline, KPI cards, and KPI table and in Prediction & Activity: input card, output cards and downloaded files.",
    type: "array",
    default: [],
    items: {
      type: "object",
      ui__uniqueKey: "slug",
      properties: {
        slug: {
          title: "Metric",
          description: "This formula is based on attribute 'output_type' in the DB",
          type: "string",
          required: true,
          ui__isSlug: true,
          enum: Object.keys(mediaInsightsPlusMetrics).filter(
            (d) => !data?.mediaInsightsPlus__metrics_v1?.map((d) => d?.slug).includes(d),
          ),
        },
        label: {
          title: "Label (can be edited)",
          description: "Use key word {objectiveUnitName} if you want to insert the objective unit name in the label",
          type: "string",
          ui__default: (item) => getDefaultMetricLabelMIPlus(item?.slug, data.objectiveUnitName),
        },
      },
    },
    ui__isError: (data) => {
      if (data === undefined) return `Media Insights will not work until you set this up`;
      for (const item of data) {
        if (item?.slug === undefined) return "You need to select a metric";
      }
    },
  };

  const mediaInsightsPlus__impact_per_dimension_v1 = {
    title: `MEDIA INSIGHTS, PREDICTION & ACTIVITY: VISIBLE IMPACT PER DIMENSION`,
    description: `${
      data?.type !== "direct/indirect"
        ? `Dimensions displayed in direct impact that appears in Media Insights: below the media insights timeline and KPI expansion and in Prediction & Activity: KPI/overall summary cards, input card and downloaded file.`
        : `Dimensions displayed for direct & indirect impact that appears in Media Insights: below the media insights timeline and KPI expansion and in Prediction & Activity: KPI/overall summary cards, input card and downloaded file.`
    }`,
    type: "object",
    ui__isError: (dataVisibleImpactPerDimension, prodDataVisibleImpactPerDimension, modelType) => {
      if (checkImpactPerDimensionChanged(dataVisibleImpactPerDimension, prodDataVisibleImpactPerDimension, modelType)) {
        return "Changes in visible dimensions may affect past predictions and data granularity. A prediction rerun may be necessary. An indicator within the platform provides the user with information about this.";
      }
      return;
    },
    ui__component: (props) => (
      <ImpactPerDimensionEditor
        {...props}
        modelType={data?.type}
        modelSlug={modelSlug}
        activeVersion={data?.activeVersion}
        labelDictionary={labelDictionary}
      />
    ),
  };

  const availableDriverTypes = _.uniq(modelDimensionsOld.map((d) => d.driver_type));
  const businessInsights__drivers = {
    title: "Business Insights: visible drivers",
    description: "Only these drivers will show in the UI. All the rest will be grouped into an 'Other drivers' bucket.",
    type: "array",
    default: [],
    items: {
      type: "object",
      ui__uniqueKey: "driver_type", // This way only undefined items can be edited and they need to be unique
      properties: {
        driver_type: {
          title: "Driver type (slug)",
          type: "string",
          required: true,
          ui__isSlug: true,
          // Exclude already added driver_types
          enum: availableDriverTypes.filter(
            (d) => !data?.businessInsights__drivers?.map((d) => d?.driver_type).includes(d),
          ),
        },
        subDimension: {
          title: "Sub-dimension (optional)",
          type: "string",
          ui__isSlug: true,
          // We need a special enum because it depends on other elements in the parent array
          ui__enum: (item) => {
            if (item?.driver_type === undefined) return [];

            const withThisDriverType = modelDimensionsOld.filter((d) => d.driver_type === item.driver_type);
            const otherDimensions = _.uniq(
              withThisDriverType.reduce(
                (acc, item) => [...acc, ...Object.keys(item).filter((d) => d !== "driver_type")],
                [],
              ),
            );
            return [...otherDimensions, ...(item.driver_type === "paid_media" ? ["grouping"] : []), null];
          },
        },
      },
    },
    ui__isError: (data) => {
      if (data === undefined) return "Business Insights will not work until you set this up";
      for (const item of data) {
        if (item?.driver_type === undefined) return "You need to add a driver type";
        if (!availableDriverTypes.includes(item.driver_type))
          return `Driver '${item.driver_type}' not in model. Please remove it`;
      }
    },
    ui__hidden: true,
  };

  const businessInsights__minInterval = {
    title: "Business Insights: minimum interval",
    description:
      "User larger intervals if you think that will result in more accurate values. This will affect the charts/download excel in the UI",
    type: "string",
    default: "week",
    enum: data?.weekly ? ["week", "month"] : ["day", "week", "month"],
    ui__labels: { day: "Daily", week: "Weekly", month: "Monthly" },
    ui__isError: (data) => {
      if (data === undefined) return "The timelines on Business Insights will not work until you set this up";
    },
  };

  return {
    type: "object",
    properties: {
      // -- From Neo --
      socketSlug: { title: "Socket slug", type: "string", ui__isSlug: true, ui__readOnly: true },
      id: { title: "ID (Neo)", type: "string", ui__isSlug: true, ui__readOnly: true },
      modelFwModelId: { title: "ID (Model Framework)", type: "string", ui__isSlug: true, ui__readOnly: true },
      createdAt: { title: "Model created", type: "timestamp", ui__readOnly: true },
      updatedAt: { title: "Model updated", type: "timestamp", ui__readOnly: true },
      type: { title: "Model type", type: "string", ui__readOnly: true },
      weekly: { title: "Weekly granularity", type: "boolean", ui__readOnly: true },
      market: { title: "Market", type: "string", ui__readOnly: true },
      objectiveUnitName: { title: "Objective unit", type: "string", ui__isSlug: true, ui__readOnly: true },

      // Configurable, also add the keys to exported const configurableKeys below, if it fits the usecase described in comments there.
      available,
      currency,
      objective,
      activeVersion,
      weekStart,
      optimizationAlgorithm,
      mediaInsightsPlus__metrics_v1,
      mediaInsightsPlus__impact_per_dimension_v1,
      mediaInsights__metrics_v2,
      mediaGroupings,
      driverCustom,
      businessInsights__drivers,
      businessInsights__minInterval,
    },
  };
}

export const configurableKeys = [
  /* 
  These are the keys which are not present at the start in conf, but are added to draft once you start editing the draft and make changes to this key related controls.

  Current use case: see removeModelKeysNotInProd function in useConfVersions.js
  */
  "available",
  "currency",
  "objective",
  "activeVersion",
  "weekStart",
  "optimizationAlgorithm",
  "mediaGroupings",
  "driverCustom",
  "mediaInsights__metrics_v2",
  "businessInsights__drivers",
  "businessInsights__minInterval",
  "mediaInsightsPlus__metrics_v1",
  "mediaInsightsPlus__impact_per_dimension_v1",
];

function ModelVersionLabel({ mode, jobId, creationTime, createdBy, operation, organization, socketSlug }) {
  const creationTimeParsed = creationTime && parseISO(creationTime);
  // return `mode ${creationTimeParsed}`;
  const creationTimeTag =
    creationTimeParsed &&
    (isWithinInterval(creationTimeParsed, {
      start: subDays(new Date(), 7),
      end: new Date(),
    })
      ? `${formatDistanceToNow(creationTimeParsed)} ago `
      : format(creationTimeParsed, "yyyy-MM-dd"));

  return (
    <>
      <span
        style={{
          padding: "2px 8px",
          background: "#000",
          color: "#fff",
          borderRadius: 0,
          marginRight: 8,
          textTransform: "uppercase",
          fontSize: 14,
        }}
      >
        {mode}
      </span>
      <span style={{ color: "#858574", marginRight: 8 }}>{jobId || "?????"}</span>
      {jobId && (
        <Tooltip
          title={
            <>
              <div>
                Job {jobId} ({operation})
              </div>
              <div>by {createdBy || "?????"}</div>
              <div>Started {creationTimeTag || "?????"}</div>
              <div style={{ marginTop: 10 }}>Click to see details</div>
            </>
          }
          PopperProps={{ style: { maxWidth: 180 } }}
          arrow
        >
          <OpenInNewRoundedIcon
            fontSize="small"
            style={{ verticalAlign: "middle", cursor: "pointer" }}
            onClick={() => {
              // The socket cockpit has replaced their URL schema. So:
              // https://socket-cockpit.blackwoodseven.com/models/hi3g_oister_dk/default/jobs/47849/result now is:
              // https://socket-cockpit.blackwoodseven.com/organizations/hi3g/models/hi3g_oister_dk/default/jobs/47849/result
              // 2023-02-23 URL changed again (https://blackwoodseven.atlassian.net/browse/BUGS-1464) to:
              // https://modeling.blackwoodseven.com/organizations/hi3g/models/hi3g_oister_dk/jobs/47849/model-specification
              // 2023-06-12 URL changed again to:
              // https://modeling.production.bw7.io/organizations/demo_jupiter/models/demo_demo_telco_no/jobs/72783/model-specification
              // We will be no longer relying on the saved .url, we build the URL from scratch instead
              let org = organization;
              if (organization === "demo_jupiter_elasticsearch" || organization === "demo_jupiter") org = "demo";
              if (organization === "demo_jupiter_2") org = "demo_test";
              const targetEnv =
                document.location.hostname.includes("stage") || document.location.hostname === "localhost"
                  ? "staging"
                  : "production";
              window.open(
                `https://modeling.${targetEnv}.bw7.io/organizations/${org}/models/${socketSlug}/jobs/${jobId}/model-specification`,
              );
            }}
          />
        </Tooltip>
      )}
    </>
  );
}
