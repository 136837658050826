import { ListItemButton, TextField, Tooltip } from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useTheme } from "@mui/styles";
import { getClaimsToken } from "auth";
import React from "react";
import { defaultToOrgRole } from "./Entry";

const filter = createFilterOptions();
const EmailAutoComplete = ({
  id,
  value = "",
  updateValue,
  existingEmails,
  setAlreadyExistsInTool,
  extraStyles,
  placeholderStyle = {},
  defaultRole,
}) => {
  const theme = useTheme();
  const [suggestions, setSuggestions] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [cachedResults, setCachedResults] = React.useState([]);
  const otherExistingEmails =
    defaultRole === "clientServiceAdmin" || defaultRole === "clientService"
      ? existingEmails.filter((a) => a.id !== id && a.email.toLowerCase().endsWith("@kantar.com")).map((a) => a.email)
      : existingEmails.filter((a) => a.id !== id).map((a) => a.email); // This will filter out the emails except its own row
  // sets the dropdown width to testfield input
  const [buttonWidth, setButtonWidth] = React.useState(null);
  const buttonRef = React.useRef(null);

  React.useEffect(() => {
    //this might not always work when resizing the window maybe we can use resizeObserver if this is necessary.
    if (buttonRef?.current) {
      setButtonWidth(buttonRef.current.offsetWidth - 10);
    }
  }, [buttonRef?.current?.offsetWidth]);

  const handleChange = React.useCallback(
    (newValue) => {
      if (typeof newValue === "string") {
        updateValue({
          email: newValue,
        });
      } else if (newValue?.inputValue) {
        // Create a new value from the user input
        updateValue({
          email: newValue.inputValue.toLowerCase(),
          ...(newValue.defaultRole && { orgRole: defaultToOrgRole[newValue.defaultRole] }),
          ...(newValue.firstName && { firstName: newValue.firstName }),
          ...(newValue.lastName && { lastName: newValue.lastName }),
        });
        setAlreadyExistsInTool(false);
      } else {
        updateValue({
          ...newValue,
        });
        setAlreadyExistsInTool(false);
        setSearchTerm("");
      }
    },
    [setAlreadyExistsInTool, updateValue],
  );

  React.useEffect(() => {
    const updateSuggestions = (s) => {
      setSuggestions(s);
      const completeMatchMail = s.find((ele) => ele.email === value);
      if (completeMatchMail !== undefined) {
        handleChange(completeMatchMail);
      }
    };
    // Function to fetch suggestions from the suggest API
    const fetchSuggestions = async () => {
      try {
        const response = await fetch(`/backend-api/auth/v2/user/suggest?prefix=${searchTerm}`, {
          method: "GET",
          headers: { "Content-Type": "application/json", Authorization: `Bearer ${getClaimsToken()}` },
        });
        const data = await response.json();
        updateSuggestions(data.existingUsers.filter((a) => !otherExistingEmails.includes(a.email)));
        setCachedResults([
          ...cachedResults,
          {
            query: searchTerm.slice(0, 3),
            data: data.existingUsers.filter((a) => !otherExistingEmails.includes(a.email)),
          },
        ]);
        const existsInTool = data.existingUsers
          .filter((a) => !otherExistingEmails.includes(a.email))
          .some((a) => a.email === searchTerm);
        setAlreadyExistsInTool(existsInTool);
      } catch (error) {
        setSuggestions([]);
      }
    };
    // Fetch suggestions when the search term changes
    if (searchTerm.trim().length >= 3) {
      // Check if results are already cached for the query prefix
      const cachedResultsForPrefix = cachedResults.find((a) => a.query === searchTerm.slice(0, 3))?.data;
      if (cachedResults.findIndex((a) => a.query === searchTerm.slice(0, 3)) > -1) {
        // Filter the cached results based on the full query
        const filteredResults = cachedResultsForPrefix.filter((result) => result.email.includes(searchTerm));
        updateSuggestions(filteredResults);
        const existsInTool = filteredResults.some((a) => a.email === searchTerm);
        setAlreadyExistsInTool(existsInTool);
      } else {
        // If no cached results, make a suggest API call
        fetchSuggestions();
      }
    } else {
      setSuggestions([]);
    }
  }, [cachedResults, existingEmails, handleChange, otherExistingEmails, searchTerm, setAlreadyExistsInTool, value]);

  const handleBlur = () => {
    // On blur, check if the current email exists in the suggestions and update other fields
    const match = suggestions.find((ele) => ele.email === value?.email?.toLowerCase()?.trim());
    if (match) {
      updateValue({
        email: match.email,
        firstName: match.firstName,
        lastName: match.lastName,
        defaultRole: match.defaultRole ? defaultToOrgRole[match.defaultRole] : undefined,
      });
    }
  };

  return (
    <Autocomplete
      disableClearable
      componentsProps={{
        popper: {
          sx: {
            "& .MuiAutocomplete-listbox": {
              padding: 0,
              width: `calc(${buttonWidth}px + 4px)`,
              ".MuiAutocomplete-option": {
                padding: "8px",
                fontSize: "18px",
                lineHeight: "var(--default-line-height)",
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                color: "black",
              },
              ".MuiAutocomplete-option.MuiAutocomplete-option[aria-selected='true']": {
                //selected option style
                backgroundColor: theme.palette.primary.main,
                color: "white",
              },
            },
          },
        },
      }}
      value={value?.email || ""}
      onChange={(event, newValue) => handleChange(newValue)}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.email);
        if (inputValue !== "" && !isExisting) {
          filtered.push({
            inputValue,
            email: `Use "${inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="email-autocomplete"
      options={suggestions}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.email;
      }}
      renderOption={(props, option) => (
        <Tooltip key={option.email} title={option.email} arrow placement="right">
          <ListItemButton {...props}>{option.email}</ListItemButton>
        </Tooltip>
      )}
      freeSolo
      renderInput={(params) => (
        <TextField
          ref={buttonRef}
          value={value?.email?.toLowerCase()?.trim()}
          sx={{
            width: "100%",
            "& .MuiInputBase-root": { padding: 0 },
            "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
              padding: "12.5px 0",
              display: "table-cell",
              width: "100%",
              border: "none",
              background: "transparent",
              height: "100%",
              fontSize: "18px",
              paddingLeft: "5px",
              ...extraStyles,
            },
            "& .Mui-focused": {
              outlineColor: theme.palette.primary.main,
              outlineStyle: "solid",
              outlineWidth: "2px",
            },
            "& .MuiInputBase-root>input::placeholder": {
              ...placeholderStyle,
            },
            border: "none",
            "& fieldset": {
              border: "none",
            },
            "& .MuiInputBase-root > input:-webkit-autofill": {
              transition: "background-color 0s 600000s, color 0s 600000s !important",
            },
          }}
          theme={theme}
          onChange={(e) => {
            setSearchTerm(e.target.value?.toLowerCase()?.trim());
            updateValue({
              email: e.target.value?.toLowerCase()?.trim(),
            });
            handleBlur();
          }}
          onBlur={handleBlur}
          {...params}
          placeholder="Email ID"
        />
      )}
    />
  );
};

export default EmailAutoComplete;
