import backend from "backend";
import { useSelector } from "react-redux";
import { format, subYears, addYears, startOfDay, endOfDay } from "date-fns";
import { parseDate } from "shared/helpers/time";
import { assert } from "chai";
import _ from "lodash";

export default function useActivitiesPeriods(socketSlug) {
  assert.isDefined(socketSlug, "socketSlug needs to be provided to load activities");
  const seqNum = useSelector((state) => state.seqNum.activities);
  const { data = [] } = backend.useData("v2/activities", {
    startDate: format(subYears(startOfDay(new Date()), 2), "yyyy-MM-dd"),
    endDate: format(addYears(endOfDay(new Date()), 2), "yyyy-MM-dd"),
    active: true,
    seqNum,
    modelSlug: socketSlug,
  });

  const formatted = data.map(({ id, name, activePlanId, investmentPlans }) => {
    const { startDate, endDate } = investmentPlans[activePlanId];
    return {
      label: name,
      interval: { start: parseDate(startDate), end: parseDate(endDate) },
      activePlanId,
    };
  });
  return _.sortBy(formatted, (d) => d.startDate); // Order activities by start date
}
