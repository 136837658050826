import _ from "lodash";

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "MEDIA_INSIGHTS_PLUS_SELECT_PERIOD": {
      const focusingActivity = action.activePlanId;
      return {
        ...state,
        //for activity overlay back button functionality
        //keeps track of last period just before overlay activity
        previousPeriod: state.period,
        period: action.period || INITIAL_STATE.period, // Reset if no period specified
        //activity details only available in split off mode with combined impact only.
        impact: focusingActivity ? "combined" : state.impact,
        splitViewglobal: focusingActivity ? false : state.splitViewglobal,

        focusedActivityPlanId: action.activePlanId ?? INITIAL_STATE.focusedActivityPlanId,
      };
    }
    case "MEDIA_INSIGHTS_PLUS_SELECT_PREVIOUS_PERIOD": {
      /*
        !!! IMPORTANT:
        This case is only used currently to get out of a focused activity through a back button in MI+ timeline chart,
        After calling this once, there would be no previous period set so it shouldn't be called twice in a row.
        If we need some previous period functionality in any other place, please ensure that the change does not break the back button implementation of ActivitySummary component in MI+.
      */
      return {
        ...state,
        period: state.previousPeriod ?? state.period, //check if there is a previous period stored
        //previousPeriod makes sense only when overlay period is activated
        previousPeriod: undefined,
        focusedActivityPlanId: action.activePlanId ?? INITIAL_STATE.focusedActivityPlanId,
      };
    }
    case "MEDIA_INSIGHTS_PLUS_REMOVE_SELECTED_ACTIVITY": {
      return {
        ...state,
        focusedActivityPlanId: null,
      };
    }
    case "MEDIA_INSIGHTS_PLUS_SELECT_SPLIT_DATA_BUTTON_GLOBAL":
      return { ...state, splitViewglobal: action.splitViewglobal }; // for global state change
    case "MEDIA_INSIGHTS_PLUS_SELECT_HIERARCHY_GLOBAL":
      return {
        ...state,
        filter: Object.keys(state.filter)
          .filter((key) => action.dimensionOptions.find((item) => item.value === key).selected)
          .reduce((acc, key) => {
            acc[key] = state.filter[key];
            return acc;
          }, {}),
        dimensionOptions: action.dimensionOptions,
        cardsGroupBy: action.dimensionOptions.find((a) => a.selected)?.value || INITIAL_STATE.cardsGroupBy,
      }; // for global state change

    case "MEDIA_INSIGHTS_PLUS_SELECT_IMPACT": {
      let returnObj = {};
      if (action.impact === "combined") {
        returnObj = { ...state, impact: action.impact, groupBy: "effect_type", filter_effect: {} };
      } else {
        returnObj = { ...state, impact: action.impact, filter_effect: { effect_type: [action.impact] } };
        delete returnObj["groupBy"];
      }
      if (state.impact !== action.impact) {
        returnObj = {
          ...returnObj,
          focusMode: false,
          filter: INITIAL_STATE.filter,
        }; //resetting filter when impact type is changed
      }
      return returnObj;
    }
    case "MEDIA_INSIGHTS_PLUS_DRILLDOWN_UPDATE":
      const selectedDimensionOptions = action.dimensionOptions.filter((a) => a.selected);
      const currDrilldownIndex = selectedDimensionOptions?.findIndex((a) => a.value === state.cardsGroupBy);
      if (action.increaseLevel) {
        //drilling down throught cards
        if (currDrilldownIndex === selectedDimensionOptions.length - 1) {
          //last level
          return { ...state };
        } else {
          return {
            ...state,
            cardsGroupBy: selectedDimensionOptions[currDrilldownIndex + 1]?.value,
          };
        }
      } else {
        //going back from filter
        return {
          ...state,
          cardsGroupBy:
            currDrilldownIndex > 0
              ? selectedDimensionOptions[currDrilldownIndex - 1]?.value
              : selectedDimensionOptions[0]?.value,
        };
      }
    case "MEDIA_INSIGHTS_PLUS_SELECT_INTERVAL":
      return { ...state, interval: action.interval };
    case "MEDIA_INSIGHTS_PLUS_SELECT_TIMELINE_METRIC":
      return { ...state, timelines: { ...state.timelines, [action.chartId]: action.newKpi } };
    case "MEDIA_INSIGHTS_PLUS_FOCUS_TOGGLE":
      return { ...state, focusMode: !state.focusMode };
    case "MEDIA_INSIGHTS_PLUS_FILTER_UPDATE": {
      const changes = {
        filter: action.filter,
        cardsDrilledDown:
          action.options.drillingDown !== undefined ? action.options.drillingDown : state.cardsDrilledDown,
      };
      const drilldownOptions = action.options.dimensionOptions
        ?.filter((option) => option.selected)
        ?.map((option) => option.value);
      if (changes.cardsDrilledDown === false && drilldownOptions !== undefined) {
        //If there is only one dimension selected in the filter that dimension in the cards.
        //Have this behaviour only when manually changing the filter not throught the cards drildown functionality.
        changes.cardsGroupBy =
          _.intersection(drilldownOptions, Object.keys(action.filter)).at(-1) ?? INITIAL_STATE.cardsGroupBy;
      }
      // Empty filter resets
      if (_.isEmpty(changes.filter)) {
        changes.groupBy = INITIAL_STATE.groupBy;
        changes.focusMode = INITIAL_STATE.focusMode;
        changes.cardsGroupBy = action.options.dimensionOptions.filter((a) => a.selected)?.at(0)?.value;
        changes.cardsDrilledDown = INITIAL_STATE.cardsDrilledDown;
      }

      return { ...state, ...changes };
    }
    case "MEDIA_INSIGHTS_PLUS_FILTER_CLEAR_ALL": {
      const { filter, groupBy, cardsDrilledDown } = INITIAL_STATE;
      return {
        ...state,
        filter,
        groupBy,
        focusMode: false,
        cardsDrilledDown,
        cardsGroupBy: state?.dimensionOptions?.filter((a) => a.selected)?.at(0)?.value,
      };
    }
    case "MEDIA_INSIGHTS_PLUS_KPIS_SORT_BY":
      return {
        ...state,
        sortBy: action.sortBy,
        ascending: state.sortBy === action.sortBy ? !state.ascending : false, // Toggle ascending/descending
      };
    default:
      return state;
  }
}

const INITIAL_STATE = {
  period: undefined,
  previousPeriod: undefined,
  impact: "combined",
  cardsGroupBy: null, // which dimension to show in cards
  cardsDrilledDown: false, // whether cards are drilledown by clicking inside of cards
  interval: "day",
  focusMode: false,
  groupBy: "effect_type",
  filter: {}, // or { publisher: ['nbc'] }
  filter_effect: {},
  timelines: { top: "effects_aggregated", bottom: "net_spend" },
  sortBy: undefined, // column to use are reference for sorting, could be 'cost', 'effect', etc
  ascending: false,
  splitViewglobal: false, //toolbar split button
  dimensionOptionsArray: [], // toolbar heirarchy
  /* 
    focusedActivityPlanId: will set this to show activity plan data in all components of MI+ (activity overlay feature)
    !!!!!!!!!
    IMPORTANT: having focusedActivityPlanId as non null does not indicate that we have to show different UI like we are focusing the activity but two more conditions need to be met 
      splitViewglobal === false && impact === "combined"
    you will find this condition in all three components in several places, I tried to tie this logic from here but it is more complex so left for the component to handle but it is guaranteed to have non null value when we are focusing an activity.
  */
  focusedActivityPlanId: null,
};
