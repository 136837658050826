import _ from "lodash";
import backend from "backend";
import { setSavePlanStatus } from "./reducers/optimization";

// Reset store, for logouts
export function logoutResetStore() {
  return { type: "LOGOUT_RESET_STORE" };
}

// -- Feature toggle --
export function updateFeatureStatus(featureName) {
  return {
    type: "TOGGLE_FEATURE",
    featureName,
  };
}

// -- Notifications --
export function notifyNew({
  message,
  action, // { text, onClick } We define exactly what to do when clicked
  isError,
  isPersistent,
  horizontalPosition = "left",
  closeButton = true,
  extraStyles,
}) {
  return { type: "NOTIFY_NEW", message, action, isError, isPersistent, horizontalPosition, closeButton, extraStyles };
}
export function notifyClose() {
  return { type: "NOTIFY_CLOSE" };
}

//Paginated navigation
export function navigationSetCurrentPage(page) {
  return { type: "NAVIGATION_SET_CURRENT_PAGE", page };
}

// -- Sequence numbers for mutations -- (see store/reducers/seqNum.js for info)
export function seqNumActivitiesMutation() {
  return { type: "SEQNUM_ACTIVITIES_MUTATION" };
}

// -- Business Insights

export function businessInsightsSelectPeriod(period) {
  return { type: "BUSINESS_INSIGHTS_SELECT_PERIOD", period };
}

export function businessInsightsSelectVsPeriodType(vsPeriodType) {
  return { type: "BUSINESS_INSIGHTS_SELECT_VS_PERIOD_TYPE", vsPeriodType };
}

export function businessInsightsFilterUpdate(filter) {
  return { type: "BUSINESS_INSIGHTS_FILTER_UPDATE", filter };
}

// -- Media Insights --

export function mediaInsightsSelectPeriod({ period }) {
  return { type: "MEDIA_INSIGHTS_SELECT_PERIOD", period };
}

export function mediaInsightsSelectInterval({ interval }) {
  return { type: "MEDIA_INSIGHTS_SELECT_INTERVAL", interval };
}

export function mediaInsightsSelectTimelineMetric({ chartId, newKpi }) {
  return { type: "MEDIA_INSIGHTS_SELECT_TIMELINE_METRIC", chartId, newKpi };
}

export function mediaInsightsFocusToggle() {
  return { type: "MEDIA_INSIGHTS_FOCUS_TOGGLE" };
}

export function mediaInsightsFilterUpdate({ newFilter, newColor }) {
  if (_.isEmpty(newFilter)) return { type: "MEDIA_INSIGHTS_FILTER_CLEAR_ALL" };
  else return { type: "MEDIA_INSIGHTS_FILTER_UPDATE", filter: newFilter, color: newColor };
}

export function mediaInsightsClearAllFilters() {
  return { type: "MEDIA_INSIGHTS_FILTER_CLEAR_ALL" };
}

export function mediaInsightsExpandedKpisToggle({ kpi }) {
  return { type: "MEDIA_INSIGHTS_EXPANDED_KPIS_TOGGLE", kpi };
}

export function mediaInsightsExpandedKpisSortBy({ sortBy }) {
  return { type: "MEDIA_INSIGHTS_EXPANDED_KPIS_SORT_BY", sortBy };
}

// -- Media Insights Plus--

export function mediaInsightsPlusSelectPeriod({ period, activePlanId }) {
  return { type: "MEDIA_INSIGHTS_PLUS_SELECT_PERIOD", period, activePlanId };
}

export function mediaInsightsPlusRemoveSelectedActivity() {
  return { type: "MEDIA_INSIGHTS_PLUS_REMOVE_SELECTED_ACTIVITY" };
}

export function mediaInsightsPlusSelectPreviousPeriod(activePlanId) {
  return { type: "MEDIA_INSIGHTS_PLUS_SELECT_PREVIOUS_PERIOD", activePlanId };
}

export function mediaInsightsPlusSelectImpact({ impact }) {
  return { type: "MEDIA_INSIGHTS_PLUS_SELECT_IMPACT", impact };
}

export function mediaInsightsPlusDrilldownUpdate({ increaseLevel, dimensionOptions }) {
  return { type: "MEDIA_INSIGHTS_PLUS_DRILLDOWN_UPDATE", increaseLevel, dimensionOptions };
}

export function mediaInsightsPlusSelectInterval({ interval }) {
  return { type: "MEDIA_INSIGHTS_PLUS_SELECT_INTERVAL", interval };
}

export function mediaInsightsPlusSelectTimelineMetric({ chartId, newKpi }) {
  return { type: "MEDIA_INSIGHTS_PLUS_SELECT_TIMELINE_METRIC", chartId, newKpi };
}

export function mediaInsightsPlusFocusToggle() {
  return { type: "MEDIA_INSIGHTS_PLUS_FOCUS_TOGGLE" };
}

export function mediaInsightsPlusFilterUpdate({ newFilter, options = {} }) {
  if (_.isEmpty(newFilter)) return { type: "MEDIA_INSIGHTS_PLUS_FILTER_CLEAR_ALL", options };
  else return { type: "MEDIA_INSIGHTS_PLUS_FILTER_UPDATE", filter: newFilter, options };
}

export function mediaInsightsPlusClearAllFilters({ options = {} } = {}) {
  return { type: "MEDIA_INSIGHTS_PLUS_FILTER_CLEAR_ALL", options };
}

export function mediaInsightsPlusKpisSortBy({ sortBy }) {
  return { type: "MEDIA_INSIGHTS_PLUS_KPIS_SORT_BY", sortBy };
}

export function mediaInsightsPlusHierarchyGlobal(dimensionOptions) {
  return { type: "MEDIA_INSIGHTS_PLUS_SELECT_HIERARCHY_GLOBAL", dimensionOptions };
}

export function mediaInsightsPlusSelectSplitDataButtonGlobal({ splitViewglobal }) {
  return { type: "MEDIA_INSIGHTS_PLUS_SELECT_SPLIT_DATA_BUTTON_GLOBAL", splitViewglobal };
}

// -- Business Insights Plus

export function businessInsightsPlusSelectPeriod(period) {
  return { type: "BUSINESS_INSIGHTS_PLUS_SELECT_PERIOD", period };
}

export function businessInsightsPlusSelectVsPeriodType(vsPeriodType) {
  return { type: "BUSINESS_INSIGHTS_PLUS_SELECT_VS_PERIOD_TYPE", vsPeriodType };
}

export function businessInsightsPlusFilterUpdate(filter, driverCustom) {
  return { type: "BUSINESS_INSIGHTS_PLUS_FILTER_UPDATE", filter, driverCustom };
}

export function businessInsightsPlusGroupByUpdate(groupByDimension) {
  return { type: "BUSINESS_INSIGHTS_PLUS_GROUPBY_UPDATE", groupByDimension };
}

// -- Activity evaluation --

export function deactivateActivity({ activityId, planId, socketSlug }) {
  return (dispatch) =>
    backend
      .patch(`v2/activities/${socketSlug}/${activityId}`, { activePlanId: null })
      .then(() => dispatch(seqNumActivitiesMutation())) // Active activities list must be refetched
      .then(() => {
        dispatch(
          notifyNew({
            message: "Activity has been deactivated",
            action: {
              text: "Undo", // As you see the undo effectively reverts the action
              onClick: () =>
                backend
                  .patch(`v2/activities/${socketSlug}/${activityId}`, { activePlanId: planId })
                  .then(() => dispatch(seqNumActivitiesMutation())),
            },
          }),
        );

        // TODO Legacy, to be removed once the new timeline is in place
        dispatch({ type: "DEACTIVATE_PLAN_SUCCESS", socketSlug });
      })
      .catch((error) => {
        console.error(`Error while deactivating activity ${activityId} (plan ${planId})`, error);
        dispatch(notifyNew({ message: "Deactivate activity failed", isError: true }));
      });
}

// Rename activity
export const renameActivity =
  ({ activityId, name, socketSlug }) =>
  (dispatch) => {
    return backend
      .patch(`v2/activities/${socketSlug}/${activityId}`, { name })
      .then(() => dispatch({ type: "SEQNUM_ACTIVITIES_MUTATION" }))
      .then(() => dispatch({ type: "DEACTIVATE_PLAN_SUCCESS", socketSlug })) //Refresh activities list
      .catch((error) => {
        console.error(`Error while renaming activity ${activityId}`, error);
      });
  };

// Delete activity
export const deleteActivity =
  ({ activityId, socketSlug }) =>
  (dispatch) => {
    return backend
      .delete(`v2/activities/${socketSlug}/${activityId}`)
      .then(() => dispatch({ type: "SEQNUM_ACTIVITIES_MUTATION" }))
      .then(() => dispatch({ type: "DEACTIVATE_PLAN_SUCCESS", socketSlug })) //Refresh activities list
      .catch((error) => {
        console.error(`Error while deleting activity ${activityId}`, error);
      });
  };

export const createActivity =
  (name, socketSlug, type = "public") =>
  (dispatch) => {
    return backend
      .post(`v2/activities/${socketSlug}`, { name, accessType: type })
      .then(() => dispatch({ type: "SEQNUM_ACTIVITIES_MUTATION" }))
      .then(() => dispatch({ type: "DEACTIVATE_PLAN_SUCCESS", socketSlug })) //Refresh activities list
      .catch((error) => {
        console.error(`Error while creating activity ${name}`, error);
      });
  };

// -- Plans --
// Which will for now be used both in Predictions and in Activities

export function plansSignalNewPartialInvestmentMax(newMax) {
  return { type: "PLANS_NEW_PARTIAL_INVESTMENT_MAX", value: newMax };
}

export function plansSignalNewEffectsMin(newMin) {
  return { type: "PLANS_NEW_EFFECTS_MIN", value: newMin };
}

export function plansSignalNewEffectsMax(newMax) {
  return { type: "PLANS_NEW_EFFECTS_MAX", value: newMax };
}

export function plansSignalMediaMixOrder(newOrder) {
  return { type: "PLANS_MEDIA_MIX_ORDER", order: newOrder };
}

export function plansToggleMediaMixExpand() {
  return { type: "PLANS_TOGGLE_MIX_EXPAND" };
}

export function newActivityShow() {
  return { type: "ADD_ACTIVITY_DIALOG_SHOW" };
}

export const saveInvestmentPlan =
  ({
    planId,
    activityId,
    activityName,
    socketSlug,
    predictionId,
    extraData = {},
    savedPlans = [],
    onInvalidatePrediction,
    isFeatureAvailable,
  }) =>
  async (dispatch) => {
    const getActivityId = async (activityId, activityName) => {
      if (activityId) {
        // the activity might have been deleted and need to be recreated.
        const res = await backend.put(`v2/activities/${socketSlug}/${activityId}`, { name: activityName });
        return res.id;
      } else {
        const res = await backend.post(`v2/activities/${socketSlug}`, { name: activityName });
        return res.id;
      }
    };

    try {
      dispatch(setSavePlanStatus({ status: "inProgress", planId }));
      const actualActivityId = await getActivityId(activityId, activityName);
      await backend.put(`v2/activities/${socketSlug}/${actualActivityId}/investment-plan-ids/${planId}`, {
        ...extraData,
      });

      const updatedSavedPlans = [...savedPlans, planId];
      const predictionURL = isFeatureAvailable("NPS") ? "v4/predictions" : "v3/predictions";
      // Mark the plan as saved only if plan saved successfully
      await backend.patch(`${predictionURL}/${socketSlug}/${predictionId}`, {
        savedPlans: updatedSavedPlans,
      });
      //optimistic update
      onInvalidatePrediction?.((oldData) => {
        const newData = structuredClone(oldData);
        newData[0].extraData.savedPlans = updatedSavedPlans;
        return newData;
      });
      //refresh activity endpoints
      dispatch(seqNumActivitiesMutation());
    } catch (err) {
      console.error("Error saving plan", err, { planId, activityId, activityName });
      dispatch(notifyNew({ message: "Error saving plan, please try again", isError: true }));
    } finally {
      dispatch(setSavePlanStatus({}));
    }
  };

export const moveInvestmentPlan =
  ({ planId, oldActivityId, newActivityId, newActivityName, socketSlug, extraData = {} }) =>
  async (dispatch) => {
    const getActivityId = async (newActivityId, newActivityName) => {
      if (newActivityId) {
        const res = await backend.put(`v2/activities/${socketSlug}/${newActivityId}`, {
          name: newActivityName,
        });
        return res.id;
      } else {
        const res = await backend.post(`v2/activities/${socketSlug}`, { name: newActivityName });
        return res.id;
      }
    };

    try {
      dispatch({ type: "SAVE_INVESTMENT_PLAN_START" });

      const actualActivityId = await getActivityId(newActivityId, newActivityName);
      await backend.put(`v2/activities/${socketSlug}/${actualActivityId}/investment-plan-ids/${planId}`, {
        ...extraData,
      });

      dispatch({
        type: "SAVE_INVESTMENT_PLAN_DONE",
        payload: { savedPlan: planId },
        socketSlug,
      });

      //delete old plan from old activity
      await backend.delete(`v2/activities/${socketSlug}/${oldActivityId}/investment-plan-ids/${planId}`);

      // Update activity list
      dispatch({
        type: "GET_ACTIVITY_SUMMARIES",
        socketSlug,
      });

      // Navigate to destination folder activity
      dispatch({
        type: "GET_PLANS_BY_ACTIVITY",
        activityId: actualActivityId,
        socketSlug: socketSlug,
      });
    } catch (err) {
      console.error("Error saving plan", err, { planId, newActivityId, newActivityName });
      dispatch(notifyNew({ message: "Error saving plan, please try again", isError: true }));

      dispatch({
        type: "SAVE_INVESTMENT_PLAN_DONE",
        socketSlug,
        error: true,
        payload: err,
      });
    }
  };

// new Activity V2 dialog

export function activityV2newActivityShow() {
  return { type: "ACTIVITY_V2_DIALOG_SHOW" };
}

export function activityV2HideDialog() {
  return { type: "ACTIVITY_V2_DIALOG_HIDE" };
}

export function activityV2ModifyActivity(originalRequest) {
  return { type: "ACTIVITY_V2_DIALOG_SHOW_MODIFY", originalRequest };
}

export function activityV2EditConstraints(index, headers) {
  return { type: "ACTIVITY_V2_ADD_EDIT_CONSTRAINT", index, headers };
}

export function activityV2ResetBeforeAddNewConstraint() {
  return { type: "ACTIVITY_V2_RESET_BEFORE_ADD_NEW_CONSTRAINT" };
}

export function activityV2DeleteConstraint(index) {
  return { type: "ACTIVITY_V2_DELETE_CONSTRAINT", index };
}

export function activityV2HandleEvent(label, value) {
  return { type: "ACTIVITY_V2_UPDATE_FIELD", label, value };
}

export function activityV2UpdateInlineEditor(index, field, val) {
  return { type: "ACTIVITY_V2_UPDATE_INLINE_EDITOR_FIELD", index, field, val };
}

export function activityV2AddInlineConstraintDimension(index) {
  return { type: "ACTIVITY_V2_ADD_INLINE_CONSTRAINT_DIMENSION", index };
}

export function activityV2DeleteInlineConstraintDimension(index) {
  return { type: "ACTIVITY_V2_DELETE_INLINE_CONSTRAINT_DIMENSION", index };
}

export function activityV2EmptyInlineConstraintDimension(index) {
  return { type: "ACTIVITY_V2_EMPTY_INLINE_CONSTRAINT_DIMENSION", index };
}

export function activityV2SwitchBrief(label, value) {
  return { type: "ACTIVITY_V2_SWITCH_BRIEF", label, value };
}

export function activityV2SaveConstraint(index) {
  return { type: "ACTIVITY_V2_SAVE_CONSTRAINT", index };
}

export function activityV2CancelEditConstraint() {
  return { type: "ACTIVITY_V2_CANCEL_EDIT_CONSTRAINT" };
}

export function activityV2StartValidating() {
  return { type: "ACTIVITY_V2_START_VALIDATING" };
}

export function activityV2NotifyValidationSuccess() {
  return { type: "ACTIVITY_V2_VALIDATION_SUCCESS" };
}

export function activityV2NotifyValidationError(errorMessage) {
  return { type: "ACTIVITY_V2_VALIDATION_ERROR", errorMessage };
}

export function activityV2OverallValidationError(errorStatus) {
  return { type: "ACTIVITY_V2_OVERALL_VALIDATION_ERROR", errorStatus };
}

export const saveOptimisationPlanForPrivateShared =
  ({
    activityName,
    activityId,
    type,
    socketSlug,
    planId,
    requestBody,
    savedPlans,
    isFeatureAvailable,
    onInvalidatePrediction,
    predictionId,
  }) =>
  async (dispatch) => {
    const getActivityId = async (activityId, activityName) => {
      if (!activityId) {
        // the activity might have been deleted and need to be recreated.
        const res = await backend.put(`v2/activities/${socketSlug}`, { activityName, accessType: type });
        return res.id;
      } else {
        return activityId;
      }
    };
    try {
      dispatch(setSavePlanStatus({ status: "inProgress", planId }));
      const actualActivityId = await getActivityId(activityId, activityName);
      await backend.put(`v2/activities/${socketSlug}/${actualActivityId}/investment-plan-ids/${planId}`, {
        ...requestBody,
      });
      const updatedSavedPlans = [...savedPlans, planId];
      const predictionURL = isFeatureAvailable("NPS") ? "v4/predictions" : "v3/predictions";
      // Mark the plan as saved only if plan saved successfully
      await backend.patch(`${predictionURL}/${socketSlug}/${predictionId}`, {
        savedPlans: updatedSavedPlans,
      });
      //optimistic update
      onInvalidatePrediction?.((oldData) => {
        const newData = structuredClone(oldData);
        newData[0].extraData.savedPlans = updatedSavedPlans;
        return newData;
      });
      //refresh activity endpoints
      dispatch(seqNumActivitiesMutation());
    } catch (err) {
      console.error("Error saving plan", err, { planId, activityId, activityName });
      dispatch(notifyNew({ message: "Error saving plan, please try again", isError: true }));
    } finally {
      dispatch(setSavePlanStatus({}));
    }
  };
