import React from "react";
import styled from "@emotion/styled";
import { useTheme } from "@mui/styles";
import SharedSearchBar from "shared/components/SearchBar";
import { TextButton } from "shared/styledComponents/TextButton";
import { ReactComponent as ArrowDown } from "shared/Icons/chevron-grey.svg";
import { Checkbox, Menu, MenuItem } from "@mui/material";
import { EllipsisLabel } from "shared/styledComponents/EllipsisLabel";

export default function SearchFilterBar({
  roles,
  filteredRoles,
  setFilteredRoles,
  searchText,
  activeEmail,
  setSearchText,
}) {
  const disabled = activeEmail !== "";
  return (
    <Container>
      <RoleFilter roles={roles} filteredRoles={filteredRoles} setFilteredRoles={disabled ? null : setFilteredRoles} />
      <SharedSearchBar
        value={searchText}
        onChange={disabled ? null : setSearchText}
        placeholder="Search user by email or name"
      />
    </Container>
  );
}

function RoleFilter({ roles, filteredRoles, setFilteredRoles }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isAllSelected = filteredRoles.length === roles.length;

  const filteredString = React.useMemo(() => {
    if (isAllSelected || filteredRoles.length > 1) {
      return `Selected ${filteredRoles.length}/${roles.length}`;
    }
    if (filteredRoles.length === 1) {
      const selectedRole = roles.find((r) => r.id === filteredRoles[0]);
      return selectedRole ? selectedRole.label : "Role not found";
    }
    return filteredRoles.length > 0
      ? filteredRoles.map((role) => roles.find((r) => r.id === role)?.label).join(", ")
      : "Filter by roles";
  }, [filteredRoles, roles, isAllSelected]);

  const toggleOption = (id) => {
    if (filteredRoles.includes(id)) {
      setFilteredRoles(filteredRoles.filter((role) => role !== id));
    } else {
      setFilteredRoles([...filteredRoles, id]);
    }
  };
  const handleSelectAll = () => {
    if (isAllSelected) {
      setFilteredRoles([]);
    } else {
      setFilteredRoles(roles.map((role) => role.id));
    }
  };
  return (
    <>
      <FilterButton disabled={!setFilteredRoles} onClick={(e) => setAnchorEl(e.currentTarget)}>
        <EllipsisLabel
          style={{
            color:
              filteredRoles?.length > 0 && setFilteredRoles ? theme.palette.blackish.main : theme.palette.greyish[50],
            textTransform: "none",
          }}
        >
          {filteredString}
        </EllipsisLabel>
        <ArrowDown
          height={20}
          width={20}
          style={{
            transform: Boolean(anchorEl) ? "rotate(180deg)" : "none",
            transition: "transform 0.3s ease-in-out",
          }}
        />
      </FilterButton>
      <Menu
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        MenuListProps={{ disablePadding: true }}
        slotProps={{
          paper: {
            style: {
              minWidth: FILTER_MENU_WIDTH,
            },
          },
        }}
      >
        <FilterMenuItem
          onClick={handleSelectAll}
          style={{ borderBottom: `1px solid ${theme.palette.greyish[25]}`, background: theme.palette.whiteish.main }}
        >
          <Checkbox
            edge="start"
            checked={isAllSelected}
            indeterminate={filteredRoles?.length === 0}
            tabIndex={-1}
            disableRipple
            color="primary"
            style={{ padding: 0, margin: 0 }}
          />
          <span>{isAllSelected ? "Deselect All" : "Select All"}</span>
        </FilterMenuItem>

        {roles.map((role) => (
          <FilterMenuItem key={role.id} onClick={() => toggleOption(role.id)}>
            <Checkbox
              edge="start"
              checked={Boolean(filteredRoles?.includes(role.id))}
              indeterminate={filteredRoles?.length === 0}
              tabIndex={-1}
              disableRipple
              color="primary"
              style={{ padding: 0, margin: 0 }}
            />
            <span>{role.label}</span>
          </FilterMenuItem>
        ))}
        {filteredRoles?.length > 0 && (
          <MenuItem style={{ padding: 0 }}>
            <TextButton
              color="alertRed"
              onClick={() => {
                setFilteredRoles([]);
                setAnchorEl(null);
              }}
              style={{ width: "100%", justifyContent: "center", fontSize: "12px" }}
            >
              Clear Filter
            </TextButton>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

const FILTER_MENU_WIDTH = 250;
const FilterButton = styled(TextButton)`
  align-items: center;
  gap: 4px;
  justify-content: space-between;
  width: ${FILTER_MENU_WIDTH}px;
  border: 1px solid ${({ theme }) => theme.palette.greyish[50]};
  flex-shrink: 0;
  &.Mui-disabled {
    cursor: not-allowed;
    pointer-events: none;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.greyish[50]};
  }
  ,
  &:hover {
    background-color: transparent;
  }
`;
const FilterMenuItem = styled(MenuItem)`
  display: flex;
  padding: 4px 8px;
  gap: 8px;
  font-size: 12px;
  &:hover {
    background-color: transparent;
  }
`;
const Container = styled.div`
  display: flex;
  gap: 8px;
  margin: 0 15px 8px;
`;
