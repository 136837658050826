import { buildCreateSlice, asyncThunkCreator } from "@reduxjs/toolkit";
import { v4 as uuid } from "uuid";
import _ from "lodash";
import moment from "moment";
import { saveInputState } from "store/reducerUtils/optimisationIndexedDB";

const INITIAL_STATE = {
  isVisible: false,
  isModify: false,
  isPredictionError: false,
  isPredictionErrorBeforeEditing: false,
  modifySource: null,
  data: {
    selectedBrief: "budgetBased",
    budget: undefined,
    currentBudget: undefined,
    target: undefined,
    currentTarget: undefined,
    startDate: undefined,
    endDate: undefined,
    constraints: [],
  },
  inlineEditor: {
    editing: false,
    errorMessage: undefined,
    index: null,
    validating: false,
    constraint: {
      type: undefined,
      startDate: undefined,
      endDate: undefined,
      match: [],
      dimensions: [],
      value: undefined,
    },
  },
  source: null,
};

export const createActivityDialogSlice = buildCreateSlice({
  creators: { asyncThunk: asyncThunkCreator },
});

function updateConstraint(constraint, field, value, index) {
  const getMatchObj = (dimensions) => {
    const match = dimensions
      .filter((item) => item.selection !== undefined)
      .map((d) => {
        return { dimension: Object.keys(d.selection)[0], value: d.selection[Object.keys(d.selection)[0]] };
      });
    return match;
  };

  if (field === "dimensions") {
    constraint.dimensions[index] = { ...constraint.dimensions[index], selection: { ...value } };
    if (index < constraint.dimensions.length - 1) {
      const dimensionsToUpdate = _.takeRight(constraint.dimensions, constraint.dimensions.length - 1 - index);
      _.forEach(dimensionsToUpdate, (d) => {
        delete d.selection;
      });
    }

    return {
      ...constraint,
      dimensions: [...constraint.dimensions],
      match: getMatchObj(constraint.dimensions),
    };
  } else {
    return { ...constraint, [field]: value };
  }
}

const checkPeriodFilter = (date, startDate, endDate) => {
  return date === startDate || date === endDate || moment(date).isBetween(startDate, endDate);
};

const validatePeriodFilter = (constraintStartDate, constraintEndDate, startDate, endDate) => {
  return (
    !checkPeriodFilter(constraintStartDate, startDate, endDate) &&
    !checkPeriodFilter(constraintEndDate, startDate, endDate)
  );
};

const activityDialogSlice = createActivityDialogSlice({
  name: "activityDialog",
  initialState: INITIAL_STATE,
  reducers: {
    showActivityDialog: (state) => {
      state.source = null;
      state.isVisible = true;
      state.data = INITIAL_STATE.data;
      state.isPredictionError = INITIAL_STATE.isPredictionError;
      state.isModify = INITIAL_STATE.isModify;
    },
    hideActivityDialog: (state) => {
      state.isVisible = false;
      state.inlineEditor = INITIAL_STATE.inlineEditor;
    },
    modifyActivityDialog: (state, action) => {
      const constraints = [...action.payload.originalRequest.constraints].map((d) => {
        let dimensions = [];
        if (Array.isArray(d.match)) {
          dimensions = d.match.map((t, i) => {
            const obj = { index: i, selection: {} };
            obj.selection[t.dimension] = t.value;
            return obj;
          });
        } else {
          const obj = { index: 0, selection: {} };
          const key = d.match.dimension === "media-group" ? "media-grouping" : d.match.dimension;
          obj.selection[key] = [d.match.value];
          dimensions = [obj];
        }
        return {
          ...d,
          match: Array.isArray(d.match)
            ? d.match
            : [
                {
                  dimension: d.match.dimension === "media-group" ? "media-grouping" : d.match.dimension,
                  value: [d.match.value],
                },
              ],
          dimensions,
        };
      });

      state.isVisible = true;
      state.isModify = true;
      state.modifySource = action.payload?.modifySource ?? null;
      state.isPredictionError = INITIAL_STATE.isPredictionError;

      state.data.startDate = action.payload.originalRequest.startDate;
      state.data.endDate = action.payload.originalRequest.endDate;
      state.data.constraints = constraints;

      state.data.budget = action.payload.originalRequest.budget || action.payload.originalRequest.maxBudget;
      state.data.currentBudget = action.payload.originalRequest.budget || action.payload.originalRequest.maxBudget;
      state.data.target = action.payload.originalRequest.target;
      state.data.currentTarget = action.payload.originalRequest.target;
      state.data.selectedBrief = action.payload.originalRequest.type || action.payload.originalRequest.selectedBrief;
    },
    //  update fields such as startDate, endDate, BriefType, budget etc...
    updateField: (state, action) => {
      state.data[action.payload.field] = action.payload.value;
    },
    //  update a error (if any), this is used to validate the entire prediction
    updateOverallPredictionError: (state, action) => {
      state.isPredictionError = action.payload.errorStatus;
    },
    //  Editor related functions
    //  reset the editor. This is used before creating a new editor
    resetEditor: (state) => {
      state.inlineEditor.constraint.startDate = state.data.startDate;
      state.inlineEditor.constraint.endDate = state.data.endDate;
    },
    //  create a editor. this is called when adding a constraint or editing a existing constraint
    addModifyEditor: (state, action) => {
      const isAdding = action.payload?.index === undefined;
      let constraintObj = {};
      if (!isAdding) {
        constraintObj = { ...state.data.constraints[action.payload.index] };
        constraintObj.dimensions = [];
        _.each(action.payload.headers, (header, i) => {
          const find = _.find(constraintObj.match, (d) => {
            return d.dimension === header;
          });
          if (find) {
            const selectionObj = { index: i, selection: {} };
            selectionObj.selection[find.dimension] = find.value;
            constraintObj.dimensions.push(selectionObj);
          } else {
            constraintObj.dimensions.push({ index: i });
          }
        });
      }

      state.isPredictionErrorBeforeEditing = state.isPredictionError;
      state.inlineEditor.editing = true;
      state.inlineEditor.index = action.payload?.index ?? null;
      state.inlineEditor.constraint = isAdding
        ? {
            type: "fixed-spend",
            startDate: state.data.startDate,
            endDate: state.data.endDate,
            dimensions: [{ index: 0 }],
          }
        : { ...constraintObj };
    },
    //  cancel a editor, reset with initial state
    cancelEditor: (state) => {
      state.inlineEditor = INITIAL_STATE.inlineEditor;
      state.isPredictionError = state.isPredictionErrorBeforeEditing;
    },
    //  delete a constraint
    deleteConstraint: (state, action) => {
      state.data.constraints = state.data.constraints.filter((d, i) => i !== action.payload.index);
      if (state.data.constraints.length > 0) {
        const validateApplicablePeriod = state.data.constraints.some((d) =>
          validatePeriodFilter(d.startDate, d.endDate, state.data.startDate, state.data.endDate),
        );
        state.isPredictionError = validateApplicablePeriod;
      } else {
        state.isPredictionError = false;
      }
      state.inlineEditor = INITIAL_STATE.inlineEditor;
    },
    //  save constraints
    saveConstraint: (state, action) => {
      const updatedData = [...state.data.constraints];
      const newConstraint = {
        id: uuid(),
        ...state.inlineEditor.constraint,
      };
      if (action.payload.index === null) updatedData.push(newConstraint);
      else updatedData[action.payload.index] = newConstraint;

      state.data.constraints = updatedData;
      state.inlineEditor = INITIAL_STATE.inlineEditor;
    },
    //  update a existing constraint
    updateEditor: (state, action) => {
      state.inlineEditor.constraint = updateConstraint(
        state.inlineEditor.constraint,
        action.payload.field,
        action.payload.val,
        action.payload.index,
      );
    },
    //  constraints validation
    startEditorValidation: (state) => {
      state.inlineEditor.validating = true;
    },
    successEditorValidation: (state) => {
      state.inlineEditor.validating = false;
      state.isPredictionError = false;
    },
    errorEditorValidation: (state, action) => {
      state.inlineEditor.validating = false;
      state.inlineEditor.errorMessage = action.payload.errorMessage;
      state.isPredictionError = true;
    },
    //  dimensions related function
    addDimension: (state, action) => {
      state.inlineEditor.constraint.dimensions = [
        ...state.inlineEditor.constraint.dimensions,
        { index: action.payload.index },
      ];
    },
    deleteDimension: (state, action) => {
      const currentDimension = state.inlineEditor.constraint.dimensions;
      currentDimension.splice(action.payload.index - 1, 1);
      state.inlineEditor.constraint.dimensions = currentDimension;
    },
    emptyDimension: (state, action) => {
      const isLast = state.inlineEditor.constraint.dimensions.length - 1 === action.payload.index;
      const findCurrentDimension = state.inlineEditor.constraint.dimensions;
      const findCurrentMatch = state.inlineEditor.constraint.match.filter((d, i) => i !== action.payload.index);
      _.forEach(findCurrentDimension, (d) => {
        if (isLast && action.payload.index === d.index) {
          delete d["selection"];
        }
        if (action.payload.index === d.index) {
          delete d["selection"];
        }
      });
      state.inlineEditor.constraint.dimensions = findCurrentDimension;
      state.inlineEditor.constraint.match = findCurrentMatch;
    },
  },
});

export const createOptimisationMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  //save the state to indexedDB
  const optState = store.getState().activityDialog;
  const { data } = optState;
  switch (action.type) {
    case "activityDialog/deleteConstraint":
    case "activityDialog/saveConstraint":
    case "activityDialog/updateField": {
      saveInputState(data).catch((error) => console.error("Failed to save to indexed db"));
      break;
    }
    default:
      break;
  }

  return result;
};

export const {
  showActivityDialog,
  hideActivityDialog,
  modifyActivityDialog,
  updateField,
  updateOverallPredictionError,
  addModifyEditor,
  resetEditor,
  cancelEditor,
  deleteConstraint,
  saveConstraint,
  updateEditor,
  startEditorValidation,
  successEditorValidation,
  errorEditorValidation,
  addDimension,
  deleteDimension,
  emptyDimension,
} = activityDialogSlice.actions;

export const selectIsModify = (state) => state.activityDialog.isModify;
export const selectIsVisible = (state) => state.activityDialog.isVisible;
export const selectIsPredictionError = (state) => state.activityDialog.isPredictionError;
export const selectModifySource = (state) => state.activityDialog.modifySource;
export const selectInlineEditor = (state) => state.activityDialog.inlineEditor;
export const selectData = (state) => state.activityDialog.data;

export default activityDialogSlice.reducer;
