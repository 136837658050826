import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  selectedActivity: { name: null, id: null, accessType: null },
  sort: {
    //initial a-z name sorted
    column: "name",
    ascending: true,
  },
  planSort: {
    //initial period sorted
    column: { id: "period", label: "Plan period", period: true },
    ascending: true,
  },
  selectedSpace: "public",
  planType: "optimisation",
  comparePlans: [null, null, null],
  isDrawerOpen: false, //for comparison drawer
};

const activitySlice = createSlice({
  name: "activities",
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedActivity: (state, action) => {
      state.selectedActivity = {
        id: action.payload.id,
        name: action.payload.name,
        accessType: action.payload?.accessType ?? "public",
      };
    },
    setActivityTableSort: (state, action) => {
      if (state.sort.column === action.payload) {
        state.sort.ascending = !state.sort.ascending;
      } else {
        state.sort.ascending = true;
      }
      state.sort.column = action.payload;
    },
    setPlansTableSort: (state, action) => {
      if (state.planSort.column?.id === action.payload?.id) {
        state.planSort.ascending = !state.planSort.ascending;
      } else {
        state.planSort.ascending = true;
      }
      state.planSort.column = action.payload;
    },
    resetActivitiesState: (state) => {
      return INITIAL_STATE;
    },
    togglePlan: (state, action) => {
      const toggleId = action.payload.id;
      const selectedPlans = [...state.comparePlans];
      const indexOfId = selectedPlans.indexOf(toggleId);
      const indexOfNull = selectedPlans.indexOf(null);
      if (indexOfId > -1) selectedPlans[indexOfId] = null;
      else if (indexOfNull > -1) selectedPlans[indexOfNull] = toggleId;

      state.comparePlans = selectedPlans;
    },
    resetPlans: (state) => {
      state.comparePlans = INITIAL_STATE.comparePlans;
      state.planSort = INITIAL_STATE.planSort;
      state.isDrawerOpen = INITIAL_STATE.isDrawerOpen;
    },
    toggleDrawerOpen: (state) => {
      state.isDrawerOpen = !state.isDrawerOpen;
    },
    setSpaceType: (state, action) => {
      state.selectedSpace = action.payload;
    },
    setPlanType: (state, action) => {
      state.planType = action.payload;
    },
  },
});

export const {
  setSelectedActivity,
  setActivityTableSort,
  resetActivitiesState,
  setPlansTableSort,
  togglePlan,
  resetPlans,
  toggleDrawerOpen,
  setSpaceType,
  setPlanType,
} = activitySlice.actions;

export const selectSelectedActivity = (state) => state.activities.selectedActivity;
export const selectActivityTableSort = (state) => state.activities.sort;
export const selectPlansTableSort = (state) => state.activities.planSort;
export const selectComparePlans = (state) => state.activities.comparePlans;
export const selectSpaceType = (state) => state.activities.selectedSpace;
export const selectPlanType = (state) => state.activities.planType;
export const isDrawerOpen = (state) => state.activities.isDrawerOpen;

export default activitySlice.reducer;
