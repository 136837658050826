import React from "react";
import { notifyNew } from "store/action-creators";
import styled from "@emotion/styled/macro";
import generateInsertionsWithGroupingsCsv from "./generateInsertionsWithGroupingsCsv";
import { useAccount } from "auth";
import useDimensionValues from "shared/hooks/useDimensionValues";
import backend from "backend";
import store from "store";
import { useDispatch, useSelector } from "react-redux";

export const LinkButton = styled.button`
  color: white;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  line-height: 1.5em;
  cursor: pointer;
  width: 100%;
  text-align: left;

  &:hover {
    font-weight: bold;
  }
`;

const HelpForPredictionDebugging = () => {
  const { selectedModel, conf, isFeatureAvailable } = useAccount();
  const { socketSlug } = conf.models[selectedModel];
  const predictionInput = useSelector((s) => s?.AddActivityDialog?.data);
  const dispatch = useDispatch();
  const predictionURL = isFeatureAvailable("NPS") ? "v4/predictions" : "v3/predictions";

  return (
    <>
      <LinkButton
        onClick={async () => {
          const predictions = await backend.get(`${predictionURL}/${socketSlug}`);
          const { createdPlans } = predictions[0]?.result || {};
          navigator.clipboard
            .writeText(
              `${new Date().toString()}
Model version: ${predictions[0]?.modelVersion || "unknown"}
-------------------------------------------
These are the correlation ids:
Your plan:           ${createdPlans?.original?.investmentPlanId || "N/A"}
Without constraints: ${createdPlans?.withoutConstraints?.investmentPlanId || "N/A"}
Alternative plan:    ${createdPlans?.alternative?.investmentPlanId || "N/A"}`,
            )
            .then(
              () => store.dispatch(notifyNew({ message: "✨ Prediction information copied to clipboard!" })),
              () => store.dispatch(notifyNew({ message: "Failed to copy to clipboard", isError: true })),
            );
        }}
      >
        Copy prediction information
      </LinkButton>
      <LinkButton
        onClick={async () => {
          const fileHandle = await window.showSaveFilePicker({ suggestedName: `prediction-input-${socketSlug}.json` });
          const fileStream = await fileHandle.createWritable();
          await fileStream.write(new Blob([JSON.stringify(predictionInput, null, 2)], { type: "text/plain" }));
          await fileStream.close();
        }}
      >
        Save prediction input to file
      </LinkButton>
      <LinkButton
        onClick={async () => {
          const [fileHandle] = await window.showOpenFilePicker();
          console.log("fileHandle", fileHandle);
          const file = await fileHandle.getFile();
          const jsonContents = await file.text();
          console.log("jsonContents-------", jsonContents);
          dispatch({ type: "BW7INTERNAL_LOAD_INPUTS", data: JSON.parse(jsonContents) });
        }}
      >
        Load prediction input from file
      </LinkButton>
    </>
  );
};

function HelpForReportCreation() {
  const { selectedModelConf } = useAccount();
  const { socketSlug, activeVersion, mediaGroupings } = selectedModelConf;
  const groupingToFilter = mediaGroupings?.reduce((acc, d) => ({ ...acc, [d.slug]: d.filter }), {});
  const dimensionValuesMedia = useDimensionValues(socketSlug, activeVersion, { onlyMedia: true });

  return (
    <>
      <LinkButton
        onClick={() => {
          try {
            const csv = generateInsertionsWithGroupingsCsv(dimensionValuesMedia, groupingToFilter);
            navigator.clipboard.writeText(csv).then(
              () =>
                store.dispatch(
                  notifyNew({ message: "✨ Copied to clipboard! Now you can paste it into a spreadsheet." }),
                ),
              () => store.dispatch(notifyNew({ message: "Failed to copy to clipboard", isError: true })),
            );
            store.dispatch(notifyNew({ message: "✨ Copied to clipboard! Now you can paste it into a spreadsheet." }));
          } catch (err) {
            store.dispatch(notifyNew({ message: "The groupping list seems to be malformed", isError: true }));
            throw new Error(`Malformed data: ${JSON.stringify(groupingToFilter)}`, err);
          }
        }}
      >
        Copy insertions with groupings as CSV
      </LinkButton>
    </>
  );
}

export default function LittleToolsForCoworkers() {
  return (
    <>
      <div style={{ margin: "1em 0 0.5em 0", color: "#C7C7C7" }}>
        <span role="img" aria-label="cake">
          🎂
        </span>{" "}
        Little tools for coworkers:
      </div>
      <HelpForPredictionDebugging />
      <HelpForReportCreation />
    </>
  );
}
