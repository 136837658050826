import { MsalProvider, useMsal } from "@azure/msal-react";
import styled from "@emotion/styled/macro";
import JsonURL from "@jsonurl/jsonurl";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import Admin from "admin";
import { useAccount } from "auth";
import { homepageURLS, whiteListedPaths } from "auth/commonConstants";
import Login from "auth/components/Login";
import { loginRequest, msalInstance } from "auth/msalAuth";
import { useOrgData } from "auth/useOrgData";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import utc from "dayjs/plugin/utc";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekday from "dayjs/plugin/weekday";
import { getTheme } from "getTheme";
import { createBrowserHistory } from "history";
import "index.css";

import { CircularProgress } from "@mui/material";
import AutoRedirect from "autoRedirect";
import CreateOptimisation from "pages/predictions/CreateOptimisation";
import ErrorPage from "pages/ErrorPage";
import LandingPage from "pages/landing";
import React, { Suspense } from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes, useLocation } from "react-router-dom";
import Navigation from "root/Navigation";
import Notify from "root/Notify";
import { sessionStorageSync, useSessionStorageSync } from "shared/helpers/sessionStorageSync";
import useBroadcastMessages, { EXTRA_PADDING_FOR_BROADCAST } from "shared/hooks/useBroadcastMessages";
import store from "store";

const DevPlayground = React.lazy(() => import("root/DevPlayground"));
const DevDocs = React.lazy(() => import("root/DevDocs"));

const PredictionsPage = React.lazy(() => import("pages/predictions"));
const MediaInsightsPlus = React.lazy(() => import("pages/media-insights-plus"));
const BusinessInsightsPlus = React.lazy(() => import("pages/business-insights-plus"));
const ActivitiesPage = React.lazy(() => import("pages/Activities"));
const SimulationPage = React.lazy(() => import("pages/predictions/Simulator"));
const OptimizationPage = React.lazy(() => import("pages/predictions/optimization"));

// We do there only once
dayjs
  .extend(quarterOfYear) // Add quarter manipulations support https://github.com/iamkun/dayjs/blob/dev/docs/en/Plugin.md#quarterofyear
  .extend(weekday) // Add weekday support
  .extend(weekOfYear) // Add week of year support
  .extend(utc);

export const history = createBrowserHistory();

function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function App() {
  const { instance, accounts } = useMsal();
  const [msalToken] = useSessionStorageSync("msalToken");
  const { isFeatureAvailable, setGlobalScope, selectedModel, loggingIn, loggedIn, conf } = useAccount();
  const [autoSelectLoading, setAutoSelectLoading] = React.useState(true);

  const { data: orgData = null, loading, status } = useOrgData(msalToken || null, accounts, instance);

  // Initial msal login script
  React.useEffect(() => {
    const login = async () => {
      await instance.initialize();
      await instance
        .handleRedirectPromise()
        .then((response) => {
          if (instance.getAllAccounts().length > 0) {
            sessionStorageSync.setItem("msalUsername", response.account.username.toLowerCase());
            sessionStorageSync.setItem("msalToken", response.accessToken);
          } else instance.loginRedirect(loginRequest);
        })
        .catch((err) => console.error(err));
    };
    login();
  }, [accounts, instance]);

  // If the msal token expires the, backend will return HTTP status 440 and we silently refresh that token on client side.
  React.useEffect(() => {
    if (status === 440) {
      instance
        .acquireTokenSilent({
          // refresh token without refresh
          account: accounts[0], // Use the first account
        })
        .then((response) => {
          sessionStorageSync.setItem("msalUsername", response.account.username.toLowerCase());
          sessionStorageSync.setItem("msalToken", response.accessToken);
          window.location.reload();
        });
    }
  }, [accounts, instance, status]);

  // Sets global scope for user
  React.useEffect(() => {
    if (!loading) setGlobalScope(JSON.stringify(orgData?.scope));
  }, [loading, orgData, setGlobalScope]);

  React.useEffect(() => {
    //change the title of the page of if using platform url
    if (window.location.origin.includes("blackwoodseven")) {
      document.title = "Blackwood Seven Media AI Platform";
    }
  }, []);

  if (loggingIn || status === 440) return <Loader />;

  // no access to user. error message
  if (!loading && (orgData?.accessibleOrgs?.length === 0 || status === 401))
    return <Login error={(orgData === null || orgData?.accessibleOrgs?.length === 0) && !loading} />;

  const whitelistedURLs =
    process.env.NODE_ENV === "development" &&
    whiteListedPaths.some((keyword) => window.location.href.includes(`/${keyword}/`));

  return (
    <>
      {!whitelistedURLs && (
        <AutoRedirect
          orgsLoading={loading}
          conf={conf}
          setAutoSelectLoading={setAutoSelectLoading}
          autoSelectLoading={autoSelectLoading}
          orgData={orgData}
        />
      )}
      <Box sx={{ color: "blackish.main", backgroundColor: "whiteish.main", minHeight: "100vh" }}>
        {!whitelistedURLs && autoSelectLoading ? (
          <Loader />
        ) : (
          <>
            {loggedIn && selectedModel && (
              <>
                <Navigation />
                <CreateOptimisation />
                <AddExtraPaddingForBroadcast />
              </>
            )}
            <Maindiv bottomPadding={selectedModel}>
              <React.Suspense fallback={null}>
                <Routes>
                  <Route
                    path="/"
                    element={
                      <LandingPage
                        orgList={!loading && orgData?.accessibleOrgs}
                        defaultRole={!loading && orgData?.defaultRole}
                        selection={orgData && !loggedIn && status === 200 ? "org" : "models"}
                        orgLoading={loading}
                      />
                    }
                  />
                  {loggedIn && (
                    <>
                      {isFeatureAvailable("Media insights") && (
                        <Route
                          path="/media-insights/*"
                          element={
                            <Suspense fallback={<Loader />}>
                              <MediaInsightsPlus />
                            </Suspense>
                          }
                        />
                      )}
                      {isFeatureAvailable("Business insights") && (
                        <Route
                          path="/business-insights"
                          element={
                            <Suspense fallback={<Loader />}>
                              <BusinessInsightsPlus />
                            </Suspense>
                          }
                        />
                      )}
                      {isFeatureAvailable("Predict") && (
                        <Route
                          path="/predictions/*"
                          element={
                            <Suspense fallback={<Loader />}>
                              <PredictionsPage />
                            </Suspense>
                          }
                        />
                      )}
                      {isFeatureAvailable("Predict") && (
                        <Route
                          path="/activities/*"
                          element={
                            <Suspense fallback={<Loader />}>
                              <ActivitiesPage />
                            </Suspense>
                          }
                        />
                      )}
                      {isFeatureAvailable("Simulator") && (
                        <Route
                          path="/simulation/*"
                          element={
                            <Suspense fallback={<Loader />}>
                              <SimulationPage />
                            </Suspense>
                          }
                        />
                      )}
                      {isFeatureAvailable("Predict") && (
                        <Route
                          path="/optimisation/*"
                          element={
                            <Suspense fallback={<Loader />}>
                              <OptimizationPage />
                            </Suspense>
                          }
                        />
                      )}
                    </>
                  )}

                  {process.env.NODE_ENV === "development" && (
                    // Allows to isolate specific components for development
                    <Route path="/__playground__/*" element={<DevPlayground />} />
                  )}

                  {process.env.NODE_ENV === "development" && (
                    // Allows to isolate docs
                    <Route path="/__docs__/*" element={<DevDocs />} />
                  )}

                  {/* If the URL does not match, we redirect (navigate) to the media insights page */}
                  <Route path="/*" element={<ErrorPage setAutoSelectLoading={setAutoSelectLoading} />} />
                </Routes>
              </React.Suspense>
            </Maindiv>
          </>
        )}
      </Box>
    </>
  );
}

function BaseStructure() {
  const { isFeatureAvailable } = useAccount();
  const urlPrefix = homepageURLS.some((keyword) => window.location.href.includes(keyword)) ? "/hai" : ""; //this is to add /hai before every route in client side routing (only when it is accessed from ummo page) so that we can this can be accessed from the hai page of ummo-landing and would not disturb the current routing urls. A better approach might be to handle this from cloudfront?
  return (
    <>
      {<ProfileAvatarContainer id="profile-avatar-container" />}
      <ThemeProvider theme={getTheme(isFeatureAvailable("BW7 theme"))}>
        <CssBaseline />
        <MsalProvider instance={msalInstance}>
          <Router history={history} basename={urlPrefix}>
            <ScrollToTop />
            <Routes>
              <Route path="*" element={<App />} />
            </Routes>
            <Admin />
            <Notify />
          </Router>
        </MsalProvider>
      </ThemeProvider>
    </>
  );
}

ReactDOMClient.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <BaseStructure />
  </Provider>,
);

// To load URLs that include a section at the highlight (key 'at')
// Keep looking for that id until it appears and if so, only once, scroll to it
// I probably should move this to the widget itself
const highlightSectionTimeout = setInterval(() => {
  try {
    const highlightQueryParam = new URLSearchParams(window.location.search).get("highlight");
    const highlightedSection = highlightQueryParam && JsonURL.parse(encodeURI(highlightQueryParam), { AQF: true })?.at;
    const sectionRef = document.getElementById(highlightedSection);
    if (sectionRef) {
      clearTimeout(highlightSectionTimeout);
      sectionRef.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  } catch (err) {
    console.error("Malformed URL (2):", err);
  }
}, 1000);

const ProfileAvatarContainer = styled("div")`
  position: sticky;
  top: 0;
  height: 0; // The profile Icon is absolutely positioned it's just here to act as an anchor point for profile icon, don't make it's position fixed otherwise it will shift whenever there is any dropdown opened.
  z-index: 1000;
`;

const Maindiv = styled.div`
  --main-div-bottom-padding: 120px;
  max-width: var(--main-div-max-width);
  margin: 0 auto;
  padding: 0px var(--main-div-left-right-padding)
    ${({ bottomPadding }) => (bottomPadding ? "var(--main-div-bottom-padding)" : "0")};
`;

const IntermidiateLoader = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;
  position: absolute;
  left: 0;
`;

function AddExtraPaddingForBroadcast() {
  const { isNewBroadcastAvailable } = useBroadcastMessages();
  return isNewBroadcastAvailable ? <div style={{ height: `${EXTRA_PADDING_FOR_BROADCAST}px` }}></div> : null;
}

export const Loader = () => {
  return (
    <IntermidiateLoader>
      <CircularProgress color="primary" />
    </IntermidiateLoader>
  );
};
