import styled from "@emotion/styled/macro";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { Title } from "admin/EditorItem/layoutComponents";
import { runOrgArmValidation } from "admin/CommonComponents/Validation";
import { isValidEmail } from "admin/helpers";
import { getAadClaims, getClaimsToken, useAccount } from "auth";
import { produce } from "immer";
import _ from "lodash";
import React from "react";
import RenderIfVisible from "shared/components/RenderIfVisible";
import { sessionStorageSync } from "shared/helpers/sessionStorageSync";
import store from "store";
import { notifyNew } from "store/action-creators";
import { mutate } from "swr";
import BulkAssign from "./BulkAssign";
import Entry from "./Entry";
import { useData } from "./useData";

// Icons
import { ReactComponent as UserPlusGreyIcon } from "shared/Icons/user-plus-grey.svg";
import { ReactComponent as UserPlusIcon } from "shared/Icons/user-plus.svg";
import Tabs from "../../CommonComponents/Tabs";
import SearchFilterBar from "admin/CommonComponents/SearchFilterBar";
import { sortAndFilterUserList } from "admin/CommonComponents/utilities";

// ORG ROLES
export const roles = [
  { id: "admin", label: "Admin" },
  { id: "superAdmin", label: "Super Admin" },
  { id: "deliveryTeam", label: "Delivery Team" },
  { id: "developer", label: "Developer" },
  { id: "clientService", label: "Client Service" },
  { id: "client", label: "Client" },
  { id: "clientServiceAdmin", label: "Client Service Admin" },
];

export const EMPTY_NEW_USER = {
  email: "",
  firstName: "",
  lastName: "",
  features: [],
  models: [],
  orgRole: "",
};

export default function UsersEditor({ organization, setDisableScroll }) {
  const { isClaim, conf } = useAccount();
  const [touchedEmails, setTouchedEmails] = React.useState([]); // To mark modified stuff
  const [confirmAdd, setConfirmAdd] = React.useState(false);
  const [toAdd, setToAdd] = React.useState([{ ...EMPTY_NEW_USER, id: Math.random() }]);
  const availableModels = Object.keys(conf?.models);
  const availableFeatures = conf?.features?.map((m) => m.name);
  const {
    loading = true,
    users = [],
    globallyAvailableModels,
    globallyAvailableFeatures,
    modelsLabels,
  } = useData(organization);
  const [existInToolEmails, setExistInToolEmails] = React.useState([]);
  const [tab, setTab] = React.useState(isClaim("OrgARM.External.Read") ? "external" : "all");
  const [searchText, setSearchText] = React.useState("");
  const [filteredRoles, setFilteredRoles] = React.useState([]);
  const [activeEmail, setActiveEmail] = React.useState("");
  const [affectingUsers, setAffectingUsers] = React.useState([]);
  const [affectingUsersGlobal, setAffectingUsersGlobal] = React.useState(true);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const checkClientService = isClaim("OrgARM.External.Read") && isClaim("OrgARM.External.Write");

  const updateActiveEmail = (email) => {
    setActiveEmail(email);
    setDisableScroll(email !== "");
  };

  const usersToShow = React.useMemo(
    () => sortAndFilterUserList({ users, tab, searchText, filteredRoles, inOrganizationContext: true }),
    [filteredRoles, searchText, tab, users],
  ).map((user) => {
    return {
      ...user,
      models: user.models.filter((model) =>
        checkClientService
          ? globallyAvailableModels.includes(model) && availableModels.includes(model)
          : globallyAvailableModels.includes(model),
      ),
      features: user.features.filter((feature) =>
        checkClientService
          ? globallyAvailableFeatures.includes(feature) && availableFeatures.includes(feature)
          : globallyAvailableFeatures.includes(feature),
      ),
    };
  });

  const toAddNonEmpty = toAdd.filter((d) => d.email.length > 0);
  const existingEmails = [...users, ...toAddNonEmpty];
  const errorInToAdd = runOrgArmValidation(
    toAddNonEmpty,
    existingEmails.map((d) => d.email),
    existInToolEmails,
    checkClientService,
    users.map((d) => d.email),
  );
  const actions = {
    updateUser: async (user) => {
      console.log("····· updateUser", user);

      mutate(
        `/backend-api/auth/v2/user/getall/${organization}`,
        users.map((d) => (d.email === user.email ? user : d)),
        false, // Optimistic update
      );
      const updateUser = await fetch(`/backend-api/auth/v2/user/update/${organization}`, {
        method: "PATCH",
        body: JSON.stringify({
          email: user.email,
          orgRole: user.orgRole,
          firstName: user.firstName,
          lastName: user.lastName,
          models: user.models,
          features: user.features,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getClaimsToken()}`,
          msal_user_auth: `${sessionStorageSync.getItem("msalToken")}`,
        },
      })
        .then(async (res) => {
          if (!res.ok) throw new Error(await res.text());
          return res.text();
        })
        .then(() => setTouchedEmails((s) => [...s, user.email]))
        .catch((err) =>
          store.dispatch(
            notifyNew({
              message: JSON.parse(err?.message)?.message?.status ?? `Failed to update ${user.email}`,
              isError: true,
            }),
          ),
        );

      mutate(`/backend-api/auth/v2/user/getall/${organization}`); // Revalidate
      mutate(`/backend-api/auth/v2/user/get-organizations`, updateUser);
      if (user.email.toLowerCase() === sessionStorage.getItem("msalUsername").toLowerCase())
        getAadClaims(organization, "prod");
    },

    deleteUser: async (email) => {
      console.log("····· deleteUser", email);
      const encodedEmail = encodeURIComponent(email);
      mutate(
        `/backend-api/auth/v2/user/getall/${organization}`,
        users.filter((d) => d.email !== email),
        false, // Optimistic update
      );

      store.dispatch(
        notifyNew({
          message: `User ${email} removed`,
          action: {
            text: "Undo", // As you see the undo effectively reverts the action
            onClick: () =>
              fetch(`/backend-api/auth/v2/user/recover/${organization}/${encodedEmail}`, {
                method: "PATCH",
                headers: { "Content-Type": "application/json", Authorization: `Bearer ${getClaimsToken()}` },
              })
                .then(async (res) => (res.ok ? res.text() : Promise.reject(new Error(await res.text()))))
                .then(() => mutate(`/backend-api/auth/v2/user/getall/${organization}`))
                .catch((err) => {
                  console.error("Failed to recover", err);
                  store.dispatch(notifyNew({ message: `Failed to recover ${email}`, isError: true }));
                }),
          },
        }),
      );

      await fetch(`/backend-api/auth/v2/user/remove/${organization}/${encodedEmail}`, {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: `Bearer ${getClaimsToken()}` },
      })
        .then(async (res) => (res.ok ? res.text() : Promise.reject(new Error(await res.text()))))
        .catch((err) => {
          console.error("Failed to remove", err);
          store.dispatch(notifyNew({ message: `Failed to remove ${email}`, isError: true }));
        });

      mutate(`/backend-api/auth/v2/user/getall/${organization}`); // Revalidate
    },

    addUsers: async () => {
      console.log("····· addUsers", toAddNonEmpty);
      setToAdd([]); // Reset new users list
      setConfirmAdd(false); // Reset confirmation flag
      setAffectingUsers((s) => [...affectingUsers, ...toAddNonEmpty.map((d) => ({ email: d.email, selected: true }))]);

      mutate(
        `/backend-api/auth/v2/user/getall/${organization}`,
        [...users, ...toAddNonEmpty],
        false, // Optimistic update
      );

      store.dispatch(notifyNew({ message: `${toAddNonEmpty.length} users added` }));
      await fetch(`/backend-api/auth/v2/user/create/${organization}`, {
        method: "POST",
        body: JSON.stringify(toAddNonEmpty), // Add organization
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getClaimsToken()}`,
          msal_user_auth: `${sessionStorageSync.getItem("msalToken")}`,
        },
      })
        .then(async (res) => {
          if (!res.ok) throw new Error(await res.text());
          return res.text();
        })
        .then(() => {
          setTouchedEmails((s) => [...s, ...toAddNonEmpty.map((d) => d.email)]);
        })
        .catch((err) =>
          store.dispatch(
            notifyNew({
              message: JSON.parse(err?.message)?.message?.status ?? `Failed to add ${toAddNonEmpty.length} users`,
              isError: true,
            }),
          ),
        );

      mutate(`/backend-api/auth/v2/user/getall/${organization}`); // Revalidate
      setToAdd([{ ...EMPTY_NEW_USER, id: Math.random() }]);
    },
  };

  const tabChange = (tab) => {
    if (activeEmail !== "") return;
    setSearchText("");
    setTab(tab);
    setDisableScroll(false);
    setIsDisabled(false);
    setAffectingUsersGlobal(true);
  };

  const usersInTab = users.filter((user) =>
    tab === "internal" ? user.email.endsWith("@kantar.com") : !user.email.endsWith("@kantar.com"),
  );

  const enableBulkAssign = () => {
    if (usersInTab?.length > 0) {
      setAffectingUsers(usersInTab.map((m) => ({ email: m.email, selected: true })));
      setAffectingUsersGlobal(true);
    }
  };

  const handleCheckBox = (email) => {
    const updatedUsers = affectingUsers?.map((user) => {
      if (user?.email === email) {
        // Toggle the 'selected' state for the user with the matching email
        return { ...user, selected: !user.selected };
      }
      return user; // Return other users unchanged
    });

    setAffectingUsers(updatedUsers);

    const checkSingleUserDisable = updatedUsers.filter((m) => m.selected === false);
    const checkAllUserPresent = updatedUsers.filter((m) => m.selected === true);
    if (checkSingleUserDisable?.length) {
      setAffectingUsersGlobal(false);
    }
    if (checkAllUserPresent.length === usersInTab.length) {
      setAffectingUsersGlobal(true);
    }
  };

  const bulkAssignKey =
    tab +
    JSON.stringify(
      globallyAvailableFeatures.filter((feature) => usersInTab.every((user) => user.features.includes(feature))),
    ) +
    JSON.stringify(globallyAvailableModels.filter((model) => usersInTab.every((user) => user.models.includes(model)))) +
    JSON.stringify(
      globallyAvailableFeatures.filter((feature) => usersInTab.every((user) => !user.features.includes(feature))),
    ) +
    JSON.stringify(globallyAvailableModels.filter((model) => usersInTab.every((user) => !user.models.includes(model))));

  return (
    <div>
      <div>
        <Header>
          <Tabs users={users} activeEmail={activeEmail} tab={tab} tabChange={tabChange} />
          {(isClaim("OrgARM.Write") || isClaim("OrgARM.External.Write")) && tab !== "all" && !loading && (
            <BulkAssign
              key={bulkAssignKey}
              tab={tab}
              organization={organization}
              affectingUsers={affectingUsers}
              setIsDisabled={setIsDisabled}
              enableBulkAssign={enableBulkAssign}
              availableFeatures={checkClientService ? availableFeatures : globallyAvailableFeatures} // client service admin can bulk assign/revoke only model/features which is accessible by himself/her
              // where as admin can assign/revoke all available model/features irrelevant his/her scope
              availableModels={checkClientService ? availableModels : globallyAvailableModels}
              checkClientService={checkClientService}
              checkAffectUsers={usersInTab?.length === affectingUsers.filter((m) => m.selected)?.length}
            />
          )}
          <SearchFilterBar
            roles={roles}
            filteredRoles={filteredRoles}
            setFilteredRoles={setFilteredRoles}
            searchText={searchText}
            activeEmail={activeEmail}
            setSearchText={setSearchText}
          />
          <Titles tab={tab} isDisabled={isDisabled} style={{ textTransform: "uppercase", height: "30px", padding: 0 }}>
            {tab !== "all" && isDisabled ? (
              <Checkbox
                checked={affectingUsersGlobal}
                onChange={() => {
                  setAffectingUsersGlobal((prev) => {
                    const newAffectingUsersGlobal = !prev;
                    // Update affectingUsers based on the new value of affectingUsersGlobal
                    const updatedAffectingUsers = usersInTab.map((user) => ({
                      email: user.email,
                      selected: newAffectingUsersGlobal, // Set all to selected/deselected based on global flag
                    }));
                    setAffectingUsers(updatedAffectingUsers);
                    return newAffectingUsersGlobal;
                  });
                }}
                style={{ color: "white", padding: 0, height: 20, width: 20, margin: "0px 15px" }}
              />
            ) : null}
            <Title title="EMAIL ID" extraStylesDiv={{ fontSize: "12px", height: "30px" }} />
            <Title title="First Name" extraStylesDiv={{ fontSize: "12px", height: "30px" }} />
            <Title title="Last Name" extraStylesDiv={{ fontSize: "12px", height: "30px" }} />
            <Title
              title="Models"
              description="Mark here the model/s you want to give access to specific user"
              extraStylesDiv={{ fontSize: "12px", height: "30px" }}
              extraStylesIcon={{ marginLeft: 4 }}
            />
            <Title
              title="Features"
              description="Mark here the features you want to show to specific user"
              extraStylesDiv={{ fontSize: "12px", height: "30px" }}
              extraStylesIcon={{ marginLeft: 4 }}
            />
            <Title title="organization role" extraStylesDiv={{ fontSize: "12px", height: "30px" }} />
            {/* For actions on hover */}
            <Title extraStylesDiv={{ height: "30px" }} />
          </Titles>
        </Header>

        {loading ? (
          <div style={{ width: "100%", textAlign: "center", padding: "20px" }}>
            <CircularProgress size={20} />
          </div>
        ) : usersToShow.length === 0 ? (
          <NoMatch>No results</NoMatch>
        ) : (
          usersToShow.map((data) => (
            <RenderIfVisible key={data.email} defaultHeight={40} visibleOffset={500}>
              <Entry
                key={data.email}
                data={data}
                onChange={actions.updateUser}
                onDelete={actions.deleteUser}
                globallyAvailableModels={
                  checkClientService
                    ? _.intersection(globallyAvailableModels, availableModels)
                    : globallyAvailableModels
                }
                globallyAvailableFeatures={
                  checkClientService
                    ? _.intersection(availableFeatures, globallyAvailableFeatures)
                    : globallyAvailableFeatures
                }
                modelsLabels={modelsLabels}
                touched={touchedEmails.includes(data.email)}
                organization={organization}
                activeEmail={activeEmail}
                onActivate={updateActiveEmail}
                setAffectingUsers={setAffectingUsers}
                affectingUsers={affectingUsers}
                tab={tab}
                isDisabledFromBulkAssign={isDisabled}
                handleCheckBox={handleCheckBox}
              />
            </RenderIfVisible>
          ))
        )}
      </div>
      {(isClaim("OrgARM.Write") || isClaim("OrgARM.External.Write")) && (
        <BottomSheet data-test-id="add-new-users-section">
          <NewUsersContainer className="customScroll">
            {toAdd.map((data, i) => (
              <Entry
                key={data.id} // The index is what better defines this
                isNew
                index={i}
                data={data}
                existInToolEmails={existInToolEmails}
                setExistInToolEmails={setExistInToolEmails}
                onChange={(updated) => {
                  setToAdd(
                    produce(toAdd, (s) => {
                      s[i] = updated;
                      // If the last item has email add a new empty user
                      if (i === toAdd.length - 1 && isValidEmail(updated.email))
                        s.push({ ...EMPTY_NEW_USER, id: Math.random() });
                    }),
                  );
                  setConfirmAdd(false); // User will have to mark the checkbox again
                }}
                onDelete={
                  i !== toAdd.length - 1
                    ? () => {
                        setToAdd(toAdd.filter((d, j) => data.id !== d.id));
                        setConfirmAdd(false); // User will have to mark the checkbox again
                      }
                    : undefined
                }
                globallyAvailableModels={
                  checkClientService
                    ? _.intersection(globallyAvailableModels, availableModels)
                    : globallyAvailableModels
                }
                globallyAvailableFeatures={
                  checkClientService
                    ? _.intersection(availableFeatures, globallyAvailableFeatures)
                    : globallyAvailableFeatures
                }
                modelsLabels={modelsLabels}
                existingEmails={existingEmails}
              />
            ))}
          </NewUsersContainer>

          <div style={{ display: "flex", padding: "0 15px 20px 15px", gap: "15px" }}>
            <Button
              style={{
                fontSize: "14px",
                padding: "6px 8px",
                display: "flex",
                alignItems: "center",
                gap: 8,
                height: 32,
              }}
              sx={{ "& .MuiButton-startIcon": { margin: 0 } }}
              variant="contained"
              disabled={toAddNonEmpty.length === 0 || Boolean(errorInToAdd) || !confirmAdd}
              color="primary"
              disableElevation
              startIcon={
                toAddNonEmpty.length === 0 || Boolean(errorInToAdd) || !confirmAdd ? (
                  <UserPlusGreyIcon height={20} width={20} />
                ) : (
                  <UserPlusIcon height={20} width={20} />
                )
              }
              onClick={actions.addUsers}
              data-test-id="add-new-users-button"
            >
              <span>
                Add {toAddNonEmpty.length || "new"} user{toAddNonEmpty.length <= 1 ? "" : "s"}
              </span>
            </Button>
            <div
              style={{
                maxHeight: "100%",
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              {errorInToAdd ||
                (toAddNonEmpty.length > 0 ? (
                  <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                    <Checkbox
                      checked={confirmAdd}
                      onChange={() => setConfirmAdd((s) => !s)}
                      style={{ color: "black", padding: 0 }}
                      data-test-id="confirm-before-adding-new-users"
                    />
                    <span style={{ fontWeight: 500 }}>
                      Confirm adding {toAddNonEmpty.length} user
                      {toAddNonEmpty.length > 1 ? "s" : ""}
                    </span>
                  </div>
                ) : (
                  <BottomMsg>Type in multiple users here and add them together</BottomMsg>
                ))}
            </div>
          </div>
        </BottomSheet>
      )}
    </div>
  );
}

const Header = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  border-bottom: 1px solid lightgray;
  background: hsla(0, 0%, 100%, 1);
  z-index: 2;
`;

const BottomSheet = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;

  background: hsla(176, 35%, 92%, 1);
  border: 1px hsl(176, 48%, 80%) solid;
  border-radius: 0px 0px 0 0;

  .MuiLink-button {
    color: inherit;
    text-decoration: underline;
  }
`;

const Titles = styled.div`
  display: grid;
  grid-template-columns: ${({ tab, isDisabled }) =>
    tab !== "all" && isDisabled ? "var(--org-entry-grid-columns)" : "25% repeat(4, 12%) 18% auto"};
  padding: 0px 6px;
`;

const BottomMsg = styled.div`
  padding: 0px 5px;
  color: ${({ theme }) => theme.palette.greyish[80]};
`;

export const ErrorMessage = styled(BottomMsg)`
  color: red;
  font-weight: 500;
  display: flex;
  align-items: center;
  &:before {
    content: "•";
    margin-right: 6px;
  }
`;

const NewUsersContainer = styled.div`
  max-height: 35vh;
  overflow: auto;
  padding: 2px 15px;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NoMatch = styled.div`
  width: 100%;
  text-align: center;
  padding: 20px;
  font-size: 16px;
`;
