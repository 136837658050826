import styled from "@emotion/styled/macro";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Icon } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/material/styles";
import React from "react";

import { ReactComponent as TargetIconDark } from "shared/Icons/target-icon-dark.svg";

export default function BriefType({ disabled, selectedType, onSelectType, MoneyIcon }) {
  const useBriefRef = React.useRef([]);
  return (
    <div>
      <OptimizationTypeFrame>
        {[
          {
            id: "budgetBased",
            Icon: MoneyIcon || AttachMoneyIcon,
            name: "Investment optimisation",
            description: "You have a budget and want to generate as much effect from it as possible.",
          },
          {
            id: "targetBased",
            Icon: TargetIcon,
            name: "Target optimisation",
            description: "You have a target on volume and want to predict how much it would cost you to reach it. ",
          },
        ].map(({ id, ...visualProps }, index) => (
          <BriefTypeBox
            key={id}
            useBriefRef={useBriefRef}
            index={index}
            selected={selectedType === id}
            disabled={disabled}
            onClick={() => {
              onSelectType(id);
              useBriefRef.current[index].focus();
            }}
            {...visualProps}
          />
        ))}
      </OptimizationTypeFrame>
    </div>
  );
}

function BriefTypeBox({ Icon, name, description, selected, disabled, onClick, useBriefRef, index }) {
  const theme = useTheme();
  return (
    <BriefTypeBoxFrame
      tabIndex={index === 0 ? 0 : -1}
      ref={(el) => (useBriefRef.current[index] = el)}
      disabled={disabled}
      selected={selected}
      onClick={disabled || selected ? undefined : onClick}
    >
      <Icon sx={{ width: "20px", height: "20px" }} />
      <div>
        <BriefTypeBoxTitle>{name}</BriefTypeBoxTitle>
        <BriefTypeBoxDescription>{description}</BriefTypeBoxDescription>
      </div>
      <Checkbox
        sx={{
          visibility: selected ? "visible" : "hidden",
          "& .MuiSvgIcon-root": {
            fontSize: "20px",
          },
          "&.Mui-disabled": {
            color: theme.palette.primary.main,
          },
        }}
        edge="start"
        disabled={disabled}
        checked={selected}
        tabIndex={-1}
        disableRipple
        color="primary"
        style={{ padding: 0, height: "20px", width: "20px", marginLeft: "auto" }}
      />
    </BriefTypeBoxFrame>
  );
}

function TargetIcon({ sx = {} }) {
  return (
    <Icon sx={{ display: "flex", ...sx }}>
      <TargetIconDark height={20} width={20} />
    </Icon>
  );
}

const OptimizationTypeFrame = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
`;

const BriefTypeBoxFrame = styled.div(({ selected, disabled, theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  position: "relative",
  padding: "20px",
  cursor: `${disabled ? "inherit" : "pointer"}`,
  border: `1px solid ${selected ? theme.palette.primary.main : theme.palette.greyish[50]}`,
  gap: "15px",
  maxWidth: "700px",
}));

const BriefTypeBoxTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
`;

const BriefTypeBoxDescription = styled.div`
  font-size: 14px;
  font-weight: 300;
`;
